<body class="fixed-sn">
<div class="wrapper container">
    <app-header></app-header>
    <main>
        <ng-container *ngIf="isActive === false; else elseBlock">
            <app-spinner>
            </app-spinner>
        </ng-container>
        <ng-template #elseBlock>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a
                            [routerLink]="'/report-portal'">{{ 'sidebar.payment' | translate }}</a></li>
                    <li class="breadcrumb-item"><a [routerLink]="">{{ 'sidebar.payoutTransaction' | translate }}</a>
                    </li>
                </ol>
            </nav>
            <h1>{{ 'sidebar.payoutTransaction' | translate }}</h1>
            <div class="pb-3"></div>

            <!--                <div class="search-bar">-->
            <!--                    <div class="left">-->
            <!--                        right -&ndash;&gt;-->
            <!--                    </div>-->
            <!--                    <div><&#45;&#45;&#45;&#45; left</div>-->
            <!--                    <div class="left">-->
            <!--                        {{'common.merhcant' | translate }}-->
            <!--                    </div>-->
            <!--                    <div class="right">-->
            <!--                    <div class="col-fix"><input type="text" class="form-control customer-border" placeholder="prefix" aria-label="username" aria-describedby="button-addon2"-->
            <!--                                [(ngModel)]="username" name="username">-->
            <!--                    </div>-->
            <!--                        <div >{{'report.payoutTransaction' | translate }}-->
            <!--                        </div>-->
            <!--                        <div class="col-fix">-->
            <!--                            <input type="text"  style="width: 150px;" class="form-control customer-border" aria-describedby="button-addon2"-->
            <!--                                   [(ngModel)]="transactionId" name="transactionId">-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    <div class="left">-->
            <!--                        {{'common.startDate' | translate }}-->
            <!--                    </div>-->
            <!--                    <div class="right">-->
            <!--                        <div class="col-fix">-->
            <!--                            <input style="width: 150px;" type="date" id="startDate" class="form-control customer-border"-->
            <!--                                   [ngModel]="defaultStartDate | date:'yyyy-MM-dd'" placeholder="Payment Id"-->
            <!--                                   aria-label="Start Date" name="defaultStartDate"-->
            <!--                                   (change)="setStartDate($event.target.value)">-->
            <!--                        </div>-->
            <!--                        <div class="col-fix">-->
            <!--                            <input type="time" data-date="12:00 AM" class="testDate form-control customer-border"-->
            <!--                                   [(ngModel)]="defaultStartTime" id="starttime" data-date-format="hh:mm A">-->
            <!--                        </div>-->
            <!--                        <div class="col-fix">-->
            <!--                            {{'common.endDate' | translate }}-->
            <!--                        </div>-->
            <!--                        <div class="col-fix">-->
            <!--                            <input type="date" id="endDate" class="form-control customer-border"-->
            <!--                                   aria-label="End Date" [ngModel]="defaultEndDate | date:'yyyy-MM-dd'"-->
            <!--                                   (change)="setEndtDate($event.target.value)">-->
            <!--                        </div>-->
            <!--                        <div class="col-fix">-->
            <!--                            <input type="time" data-date="12:00 AM" class="testDate form-control customer-border"-->
            <!--                                   [(ngModel)]="defaultEndTime" id="endtime" data-date-format="hh:mm A">-->
            <!--                        </div>-->
            <!--                        <div class="col-fix">-->
            <!--                            <button class="btn btn-yellow font-weight-bold m-0 px-3 py-2 z-depth-0 waves-effect btnMenu"-->
            <!--                                    type="button" set-lan="text:Search" (click)="searchPayment(1)">Search</button>-->
            <!--                        </div>-->
            <!--                        <a (click)="ExportTOExcel();" id="btnExport" set-lan="title:Export Excel" title="Export Excel"-->
            <!--                           style="display: initial; padding-left: 0.5rem;">-->
            <!--                            <img src="../../../../../assets/img/icons/Excel.png"-->
            <!--                                 style="width: 37px; margin-left: .5rem; padding-top: 3px;"></a>-->
            <!--                        <label id="txtExport" set-lan="text:ExportNote"-->
            <!--                               style="font-size: 10pt; width: 185px; margin-left: 0.5rem; line-height: 1rem; display: initial;">Remark:-->
            <!--                            limit to 100 records per download</label>-->
            <!--                    </div>-->
            <!--                    <div class="left">-->
            <!--                        {{'common.service' | translate }}-->
            <!--                    </div>-->
            <!--                    <div class="select-form  customer-border right" style="margin: 0 10px; width: ">-->
            <!--                        <div class="btn-group col-fix">-->
            <!--                            <select name="status" style="color:#008F6B; padding-left: 0px;"-->
            <!--                                    class="form-control-lg-select btn btn-primary" [(ngModel)]="status">-->
            <!--                                <option selected="selected" value="">All</option>-->
            <!--                                <option value="SUCCESS">SUCCESS</option>-->
            <!--                                <option value="APPROVE">APPROVE</option>-->
            <!--                                <option value="PENDING">PENDING</option>-->
            <!--                                <option value="REJECT">REJECT</option>-->
            <!--                                <option value="PROCCESS">PROCCESS</option>-->
            <!--                                <option value="FAIL">FAIL</option>-->
            <!--                                <option value="MANUAL">MANUAL</option>-->
            <!--                            </select>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->

            <div class="btn-toolbar section-group btn-toolbaredit" role="toolbar"
                 style="padding-bottom: 0px !important;">
                <!--                <div class="row col-md-12">-->
                <!--                    <div class="form-group row" style="padding-left: 0.6rem;">-->
                <div class="form-group col-md-12 row">

                    <ng-container *ngIf="role=='ADMIN' || role   == 'ADMIN_READ_ONLY'">
                        <label for="email"
                               class="col-form-label font-weight-bold">{{'common.merhcant' | translate }}</label>
                        <div class="input-group table-search col-2">
                            <input type="text" class="form-control customer-border" placeholder="Prefix"
                                   aria-label="username" aria-describedby="button-addon2"
                                   [(ngModel)]="username" name="username">
                        </div>
                    </ng-container>

                    <label for="email"
                           class="col-form-label font-weight-bold">{{'report.payoutTransaction' | translate }}</label>

                    <div class="input-group table-search col-2">
                        <input type="text" style="width: 150px;" class="form-control customer-border"
                               placeholder="Payout Id" aria-describedby="button-addon2"
                               [(ngModel)]="transactionId" name="transactionId">
                    </div>

                </div>

                <div class="form-group row col-md-12 btn-toolbaredit">
                    <!--                    <div class="form-group row" style="padding-left: 0.6rem;">-->
                    <label for="email" class="col-form-label font-weight-bold"
                           set-lan="text:Start date_">{{'common.startDate' | translate }}</label>
                    <div class="input-group table-search col-fix">
                        <input style="width: 150px;" type="date" id="startDate" class="form-control customer-border"
                               [ngModel]="defaultStartDate | date:'yyyy-MM-dd'" placeholder="Payment Id"
                               aria-label="Start Date" name="defaultStartDate"
                               (change)="setStartDate($event.target.value)">
                    </div>
                    <div class="col-fix">
                        <input type="time" data-date="12:00 AM" class="testDate form-control customer-border"
                               [(ngModel)]="defaultStartTime" id="starttime" data-date-format="hh:mm A">
                    </div>
                    <!--                    </div>-->

                    <!--                    <div class="form-group row">-->
                    <label for="email" class="col-form-label font-weight-bold"
                           set-lan="text:To date_">{{'common.endDate' | translate }}</label>
                    <div class="input-group table-search col-fix">
                        <input type="date" id="endDate" class="form-control customer-border"
                               aria-label="End Date" [ngModel]="defaultEndDate | date:'yyyy-MM-dd'"
                               (change)="setEndtDate($event.target.value)">
                    </div>
                    <div class="input-group table-search col-fix">
                        <input type="time" data-date="12:00 AM" class="testDate form-control customer-border"
                               [(ngModel)]="defaultEndTime" id="endtime" data-date-format="hh:mm A">
                    </div>
                    <!--                    </div>-->

                    <div class="row col-fix" style="margin-left: 10px;">
                        <button class="btn btn-yellow font-weight-bold m-0 px-3 py-2 z-depth-0 waves-effect btnMenu"
                                type="button" set-lan="text:Search" (click)="searchPayment(1)">Search
                        </button>
                    </div>
                    <a (click)="ExportTOExcel();" id="btnExport" set-lan="title:Export Excel" title="Export Excel"
                       style="display: initial; padding-left: 0.5rem;">
                        <img src="../../../../../assets/img/icons/Excel.png"
                             style="width: 37px; margin-left: .5rem; padding-top: 3px;"></a>
                    <label id="txtExport" set-lan="text:ExportNote"
                           style="font-size: 10pt; width: 185px; margin-left: 0.5rem; line-height: 1rem; display: initial;">Remark:
                        limit to 100 records per download</label>
                </div>
                <div class="form-group col-md-12 row">
                    <label for="email" class="col-form-label font-weight-bold" style="padding-right: 10px"
                           set-lan="text:Start date_">{{'common.service' | translate }}</label>
                    <!--                    <div class="input-group table-search col-4">-->
                    <div class="select-form customer-border">
                        <div class="btn-group">
                            <select name="status" style="color:#008F6B; padding-left: 0px;"
                                    class="form-control-lg-select btn btn-primary" [(ngModel)]="status">
                                <option selected="selected" value="">All</option>
                                <option value="SUCCESS">SUCCESS</option>
                                <option value="APPROVE">APPROVE</option>
                                <option value="PENDING">PENDING</option>
                                <option value="REJECT">REJECT</option>
                                <option value="PROCESS">PROCESS</option>
                                <option value="FAIL">FAIL</option>
                                <option value="MANUAL">MANUAL</option>
                            </select>
                        </div>
                    </div>
                    <!--                    </div>-->
                </div>

            </div>


            <br>
            <div class="form-group col-md-12 row">
                <div class="btn-group btn-group-green mr-3" data-toggle="buttons">
                    <label
                            class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                            (click)="btnTimePN('Previous');">
                        <input class="form-check-input" type="radio" name="options" id="option1" autocomplete="off">
                        <span set-lan="text:Previous">Previous</span>
                    </label>
                    <label
                            class="btn btn-white border border-success z-depth-0 form-check-label defult-active waves-effect waves-light"
                            (click)="btnTime('Today');">
                        <input class="form-check-input" type="radio" name="options" id="option2" autocomplete="off">
                        <span set-lan="text:Today">Today</span>
                    </label>
                    <label
                            class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                            (click)="btnTime('Yesterday');">
                        <input class="form-check-input" type="radio" name="options" id="option3" autocomplete="off">
                        <span set-lan="text:Yesterday">Yesterday</span>
                    </label>
                    <label
                            class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                            (click)="btnTime('This week');">
                        <input class="form-check-input" type="radio" name="options" id="option4" autocomplete="off">
                        <span set-lan="text:This week">This week</span>
                    </label>
                    <label
                            class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                            (click)="btnTime('Last week');">
                        <input class="form-check-input" type="radio" name="options" id="option5" autocomplete="off">
                        <span set-lan="text:Last week">Last week</span>
                    </label>
                    <label
                            class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                            (click)="btnTime('This month');">
                        <input class="form-check-input" type="radio" name="options" id="option6" autocomplete="off">
                        <span set-lan="text:This month">This month</span>
                    </label>
                    <label
                            class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                            (click)="btnTime('Last month');">
                        <input class="form-check-input" type="radio" name="options" id="option7" autocomplete="off">
                        <span set-lan="text:Last month">Last month</span>
                    </label>
                    <label
                            class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                            (click)="btnTimePN('Next');">
                        <input class="form-check-input" type="radio" name="options" id="option8" autocomplete="off">
                        <span set-lan="text:Next">Next</span>
                    </label>
                </div>
            </div>

            <!--        <div class="btn-toolbar mb-3" role="toolbar">-->
            <!--        <div class="form-row">-->
            <!--            <div class="form-group col-12">-->
            <!--                <div class="form-group row">-->
            <!--                    <div class="input-group table-search col-6">-->
            <!--                        <input type="date" class="form-control" [ngModel]="defaultStartDate"-->
            <!--                               placeholder="Payment Id" aria-label="Start Date"-->
            <!--                               name="defaultStartDate" (change)="setStartDate($event.target.value)">-->
            <!--                    </div>-->
            <!--                    <div class="input-group table-search col-6">-->
            <!--                        <input type="date" class="form-control" [ngModel]="defaultEndDate"-->
            <!--                               placeholder="Order Id" aria-label="End Date"-->
            <!--                               name="defaultEndDate" (change)="setEndtDate($event.target.value)">-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div class="form-group row">-->
            <!--                    <div class="input-group table-search col-6">-->
            <!--                        <input type="text" class="form-control" placeholder="Payment Id" aria-label="Payment Id"-->
            <!--                               aria-describedby="button-addon2" [(ngModel)]="transactionId" name="transactionId">-->
            <!--                    </div>-->
            <!--                    <div class="input-group table-search col-6">-->
            <!--                        <div class="select-form">-->
            <!--                            <div class="btn-group">-->
            <!--                                <select name="status" class="form-control-lg-select btn btn-primary"-->
            <!--                                        [(ngModel)]="status">-->
            <!--                                    <option selected="selected" value="">All</option>-->
            <!--                                    <option value="SUCCESS">SUCCESS</option>-->
            <!--                                    <option value="APPROVE">APPROVE</option>-->
            <!--                                    <option value="PENDING">PENDING</option>-->
            <!--                                    <option value="REJECT">REJECT</option>-->
            <!--                                    <option value="PROCCESS">PROCCESS</option>-->
            <!--                                    <option value="FAIL">FAIL</option>-->
            <!--                                    <option value="MANUAL">MANUAL</option>-->
            <!--                                </select>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                    </div>-->

            <!--                </div>-->
            <!--                <button class="btn btn-lg btn-yellow font-weight-bold" type="button" (click)="searchPayment(1)">Search-->
            <!--                </button>-->
            <!--            </div>-->
            <!--        </div>-->
            <!--        </div>-->
            <div class="table-wrapper">
                <nav aria-label="Page navigation example">
                    <ul class="pagination" style="float: right;">
                        <li class="page-item">
                            <a class="page-link" (click)="searchPayment(currentPage - 1)">Previous</a>
                        </li>
                        <li class="page-item" *ngFor="let page of counter(pageNumber); let i = index">
                            <a class="page-link" (click)="searchPayment(i+1)">{{i + 1}}</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" (click)="searchPayment(currentPage + 1)">Next</a>
                        </li>
                    </ul>
                </nav>
                <div class="table-wrapper" style="margin-top: 10px; margin-bottom: 10px; border-radius: 5px;">
                    <table class="table table-border" datatables="ng" dt-options="dtOptions">
                        <thead class="rgba-green-slight">
                        <tr>
                            <th style=" display:table-cell;width: 10%; text-align: right; padding-right: 5px;"
                                class="text-nowrap"><span class="">Date & Time</span></th>
                            <th style="width: 7%; text-align: right; padding-right: 5px;" class="">Transaction Id.
                            </th>
                            <ng-container *ngIf="role=='ADMIN' || role   == 'ADMIN_READ_ONLY'">
                                <th style="width: 7%; text-align: right; padding-right: 5px;"><span
                                        class="">Merchant.</span></th>
                            </ng-container>
                            <th style="width: 7%; text-align: right; padding-right: 5px;"><span
                                    class="">Amount.</span></th>
                            <th style="width: 7%; text-align: right; padding-right: 5px;"><span class="">Fee.</span>
                            </th>
                            <th style="width: 7%; text-align: right; padding-right: 5px;"><span class="">Amount Inc
                                        Fee.</span></th>
                            <th style="width: 7%; text-align: right; padding-right: 5px;"><span class="">Account
                                        No.</span></th>
                            <th style="width: 7%; text-align: right; padding-right: 5px;"><span
                                    class="">Channel.</span></th>
                            <ng-container *ngIf="role == 'ADMIN'">
                                <th style="width: 7%; text-align: right; padding-right: 5px;"><span
                                        class="">Firstname</span></th>
                                <th style="width: 7%; text-align: right; padding-right: 5px;"><span
                                        class="">Lastname</span></th>
                                <th style="width: 7%; text-align: right; padding-right: 5px;"><span
                                        class="">Account Bank</span></th>
                                <th style="width: 7%; text-align: right; padding-right: 5px;"><span
                                        class="">Account Set</span></th>
                            </ng-container>
                            <th style="width: 7%; text-align: right; padding-right: 5px;"><span
                                    class="">Status.</span></th>
                            <th style="width: 7%; text-align: right; padding-right: 5px;">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="hide">
                            <td></td>
                        </tr>
                        <tr *ngFor="let index of responseTransaction?.result.transactions;let i=index">
                            <td class="text-left">
                                {{index.update_date | date:'d/M/yy, h:mm a'}}
                            </td>
                            <td class="text-right">{{index.transaction_id}}</td>
                            <ng-container *ngIf="role=='ADMIN' || this.role === 'ADMIN_READ_ONLY'">
                                <td class="text-right">{{index.username}}</td>
                            </ng-container>
                            <td class="text-right">
                                <ng-container *ngIf="index.type=='cash' || index.type=='credit'">
                                    {{index.amount | number:'1.2-2'}}
                                </ng-container>
                                <ng-container *ngIf="index.type!='cash' && index.type!='credit' ">
                                    {{index.amount_dw | number:'1.2-2'}}
                                </ng-container>
                            </td>
                            <td class="text-right">{{index.fee_amount | number:'1.2-2'}}</td>
                            <td class="text-right">
                                <ng-container *ngIf="index.type=='cash' || index.type=='credit'">
                                    {{index.amount_inc_fee | number:'1.2-2'}}
                                </ng-container>
                                <ng-container *ngIf="index.type!='cash' && index.type!='credit' ">
                                    {{index.amount_dw_inc_fee | number:'1.2-2'}}
                                </ng-container>
                            </td>
                            <td class="text-right">
                                {{index.account_number }}
                            </td>

                            <td class="text-right">
                                {{index.channel_transfer}}
                            </td>
                            <ng-container *ngIf="role == 'ADMIN'">
                                <td class="text-right">
                                    {{index.firstname}}
                                </td>
                                <td class="text-right">
                                    {{index.lastname}}
                                </td>
                                <td class="text-right">
                                    {{index.bank_set}}
                                </td>
                                <td class="text-right">
                                    {{index.account_set}}
                                </td>
                            </ng-container>
                            <td class="text-center">
                                {{index.status}}
                            </td>

                            <td class="text-center">
                                <a class="link" data-toggle="modal" data-target="#modal-payout-transaction"
                                   (click)="modal(index.transaction_id)">View</a>
                            </td>
                        </tr>
                        </tbody>
                        <tfoot class="rgba-yellw-slight">
                        <tr style="background-color: rgb(255, 244, 149);" class="total">
                            <td colspan="2" class="total-cell text-center">Total</td>
                            <ng-container
                                    *ngIf="currentPosition == 'ADMIN' || currentPosition == 'ADMIN_READ_ONLY'">
                                <td>
                                </td>
                            </ng-container>
                            <td class="text-right">{{sumAmount | number: '1.2-2'}}</td>
                            <td class="text-right"> {{sumFee | number: '1.2-2'}}</td>
                            <td class="text-right">
                                {{sumActualAmount | number:'1.2-2'}}
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td colspan="3"></td>
                            <ng-container *ngIf="currentPosition == 'ADMIN' || currentPosition == 'ADMIN_READ_ONLY'">

                                <td colspan="3">
                                </td>
                            </ng-container>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>


            <div class="modal fade" id="modal-payout-transaction">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <!--                        <h4 class="modal-title">Payment information</h4>-->
                            <br/>
                        </div>
                        <div class="modal-body">
                            <form class="form-horizontal">
                                <div class="row">
                                    <div class="col-6">
                                        <fieldset>
                                            <legend style="color:blue;font-weight:bold;">Payout Detail</legend>
                                            <table class="table table-bordered sortTable" datatables="ng"
                                                   dt-options="dtOptions">
                                                <tbody class="table-striped">
                                                <tr>
                                                    <td><b>Payout Id</b></td>
                                                    <td>{{currentPayoutTransaction.transaction_id}}</td>

                                                </tr>
                                                <tr>
                                                    <td><b>Amount</b></td>
                                                    <td>{{currentPayoutTransaction.amount}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Fee Amount</b></td>
                                                    <td>{{currentPayoutTransaction.fee_amount}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Net Amount</b></td>
                                                    <td>{{currentPayoutTransaction.amount_inc_fee}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Status</b></td>
                                                    <td>{{currentPayoutTransaction.status}}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </fieldset>
                                    </div>

                                    <div class="col-6">
                                        <fieldset>
                                            <legend style="color:blue;font-weight:bold;">Payout History</legend>
                                            <table class="table table-bordered sortTable" datatables="ng"
                                                   dt-options="dtOptions">
                                                <thead>
                                                <tr>
                                                    <th style="text-align: left;"><span
                                                            class="col-cell">Status</span></th>
                                                    <th style="text-align: left;"><span class="col-cell">Date &
                                                                Time</span>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody class="table-striped">
                                                <tr *ngFor="let index of currentPayoutTransaction.status_history">
                                                    <td><b>
                                                        {{index.status}}
                                                    </b></td>
                                                    <td> {{index.date | date : 'M/d/yy, h:mm a'}}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </fieldset>
                                    </div>
                                </div>

                                <br/>
                                <ng-container *ngIf="currentPosition == 'ADMIN'">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="select-form">
                                                <div class="btn-group">
                                                    <select name="status_payout"
                                                            class="form-control-lg-select btn btn-primary"
                                                            [(ngModel)]="status_payout">
                                                        <option value="SUCCESS">SUCCESS</option>
                                                        <option selected value="APPROVE">APPROVE</option>
                                                        <option value="PENDING">PENDING</option>
                                                        <option value="REJECT">REJECT</option>
                                                        <option value="FAIL">FAIL</option>
                                                        <option value="MANUAL">MANUAL</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                </ng-container>
                                <div class="row">
                                    <ng-container *ngIf="currentPosition == 'ADMIN'">
                                        <div>
                                            <button type="button" class="btn btn-default" data-dismiss="modal"
                                                    (click)="updateStatus()">
                                                Save Status
                                            </button>
                                        </div>
                                        &nbsp;
                                    </ng-container>

                                    <button type="button" class="btn btn-default" data-dismiss="modal">
                                        Close
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </main>
</div>
</body>
