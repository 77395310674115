<script src="changepassword.component.ts"></script>

<body class="fixed-sn">
<div class="wrapper container">
    <app-header></app-header>
    <main>
        <div>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="">Account</a></li>
                    <li class="breadcrumb-item"><a [routerLink]="">Password</a></li>
                </ol>
            </nav>
            <h1>Password</h1>
            <div class="pb-3"></div>
<!--            <div class="btn-toolbar section-group" role="toolbar" style="padding-bottom: 0px !important;">-->
            <div class="section-group mb-4 col-6">
                <div class="form-row">
                    <div class="form-group col-12">
                        <div class="form-group row">
                            <label for="username" class="col-3 col-form-label" set-lan="text:Username">Username</label>
                            <div class="col-9">
                                <input type="text" class="form-control" id="username" disabled="disabled" autocomplete="off" [(ngModel)]="username" name="username">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="oldpassword" class="col-3 col-form-label" set-lan="html:Old Password">Old Password<label style="color: red;">&nbsp;*</label></label>
                            <div class="col-9">
                                <input type="text" class="form-control" id="oldpassword" autocomplete="off" maxlength="24" [(ngModel)]="oldPassword" name="oldPassword">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="newpassword" class="col-3 col-form-label" set-lan="html:New Password">New Password<label style="color: red;">&nbsp;*</label></label>
                            <div class="col-9">
                                <input type="text" class="form-control" id="newpassword" autocomplete="off" maxlength="24" [(ngModel)]="newPassword" name="newPassword">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="repeatPassword" class="col-3 col-form-label" set-lan="html:Confirm Password">Confirm Password<label style="color: red;">*</label></label>
                            <div class="col-9">
                                <input type="text" class="form-control" id="repeatPassword" autocomplete="off" maxlength="24" [(ngModel)]="repeatPassword" name="repeatPassword">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12" style="text-align: center;">
                        <button class="btn btn-yellow font-weight-bold waves-effect waves-light" (click)="changePassword()" type="button" set-lan="text:Save New Password">Save New Password</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
</body>
