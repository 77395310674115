import {Injectable} from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class AuthService implements CanActivate{

    constructor( public router: Router) {
    }

    public async isAuthenticated(): Promise<boolean>  {
        const token = localStorage.getItem('token');
        console.log(token);
        if (token === null || token === '') {
            return Promise.resolve(false);
        }else {
            return Promise.resolve(true);
        }
    }


    async canActivate(): Promise<boolean> {
        try {
            if (!(await this.isAuthenticated())) {
                this.router.navigate(['/login']);
                return Promise.resolve(false);
            }
            return Promise.resolve(true);
        }catch (e) {
            return Promise.resolve(false);
        }
    }

}
