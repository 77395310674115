import {Pipe} from '@angular/core';

@Pipe({
    name: 'nameBet'
})
export class NameBet {
  transform(i, lang) {

      if(lang == 'th'){
          if(i == "_1BOT"){
            return "หนึ่งตัวล่าง";
          }
          if(i == "_1TOP"){
            return "หนึ่งตัวบน";
          }
          if(i == "_2BOT"){
            return "สองตัวล่าง";
          }
          if(i == "_2BOT_ODD"){
            return "สองตัวล่างคี่";
          }
          if(i == "_2BOT_EVEN"){
            return "สองตัวล่างคู่";
          }
          if(i == "_LA0_4TOP"){
            return "แบบชุด";
          }
          if(i == "_2BOT_UNDER"){
            return "สองตัวล่างต่ำ";
          }
          if(i == "_2BOT_UPPER"){
            return "สองตัวล่างสูง";
          }
          if(i == "_2TOD"){
            return "สองตัวโต๊ด";
          }
          if(i == "_2TOP"){
            return "สองตัวบน";
          }
          if(i == "_2TOP_ODD"){
            return "สองตัวบนคี่";
          }
          if(i == "_2TOP_EVEN"){
            return "สองตัวบนคู่";
          }
          if(i == "_2TOP_UNDER"){
            return "สองตัวบนต่ำ";
          }
          if(i == "_2TOP_UPPER"){
            return "สองตัวบนสูง";
          }
          if(i == "_3BOT"){
            return "สามตัวล่าง";
          }
          if(i == "_3TOD"){
            return "สามตัวโต๊ด";
          }
          if(i == "_3TOP"){
            return "สามตัวบน";
          }
          if(i == "_3TOP_ODD"){
            return "สามตัวบนคี่";
          }
          if(i == "_3TOP_EVEN"){
            return "สามตัวบนคู่";
          }
          if(i == "_3TOP_UNDER"){
            return "สามตัวบนสูง";
          }
          if(i == "_3TOP_UPPER"){
            return "สามตัวบนต่ำ";
          }
          if(i == "_4TOD"){
            return "สี่ตัวโต๊ด";
          }
          if(i == "_4TOP"){
            return "สี่ตัวบน";
          }
          if(i == "_5TOD"){
            return "ห้าตัวโต๊ด";
          }
          if(i == "_5TOP"){
            return "ห้าตัวบน";
          }
          if(i == "_6TOD"){
            return "หกตัวโต๊ด";
          }
          if(i == "_6TOP"){
            return "หกตัวบน";
          }
      }else{
        if(i == "_1BOT"){
          return "1 Bottom";
        }
        if(i == "_1TOP"){
          return "1 Top";
        }
        if(i == "_2BOT"){
          return "2 Bottom";
        }
        if(i == "_LA0_4TOP"){
          return "4 Straight(lao)";
        }
        if(i == "_2BOT_UNDER"){
          return "2BOT UNDER";
        }
        if(i == "_2BOT_UPPER"){
          return "2BOT OVER";
        }
        if(i == "_2TOD"){
          return "2TOD";
        }
        if(i == "_2TOP"){
          return "2TOP";
        }
        if(i == "_2TOP_ODD"){
          return "2TOP ODD";
        }
        if(i == "_2TOP_EVEN"){
          return "2TOP EVEN";
        }
        if(i == "_2TOP_UNDER"){
          return "2TOP OVER";
        }
        if(i == "_2TOP_UPPER"){
          return "2TOP OVER";
        }
        if(i == "_3BOT"){
          return "3BOT";
        }
        if(i == "_3TOD"){
          return "3TOD";
        }
        if(i == "_3TOP"){
          return "3TOP";
        }
        if(i == "_3TOP_ODD"){
          return "3TOP ODD";
        }
        if(i == "_3TOP_EVEN"){
          return "3TOP EVEN";
        }
        if(i == "_3TOP_UNDER"){
          return "3TOP UNDER";
        }
        if(i == "_3TOP_UPPER"){
          return "3TOP OVER";
        }
        if(i == "_4TOD"){
          return "4TOD";
        }
        if(i == "_4TOP"){
          return "4TOP";
        }
        if(i == "_5TOD"){
          return "5TOD";
        }
        if(i == "_5TOP"){
          return "5TOP";
        }
        if(i == "_6TOD"){
          return "6TOD";
        }
        if(i == "_6TOP"){
          return "6TOP";
        }
      }


    }
}

