import {OnInit} from '@angular/core';

declare let jQuery: any;

export class DefaultVariable implements OnInit {
    maxSize = 50;
    page = 1;

    role = localStorage.getItem('role');
    today = new Date();
    defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 1, 0, 0, 0);
    defaultEndDate = new Date();

    stage: any;
    defaultEndTime = '23:59';
    defaultStartTime = '00:00';


    async ngOnInit(): Promise<any> {
        await this.btnTime('Today');

    }

    async setDefault(): Promise<any> {
        this.defaultStartDate.setDate(this.defaultStartDate.getDate());
        this.defaultEndDate.setDate(this.defaultEndDate.getDate() + 1);
    }

    async setStartDate(value) {
        console.log(value);
        this.defaultStartDate = value;
        // alert(this.date);
    }

    async setEndtDate(value) {
        console.log(value);
        this.defaultEndDate = value;
        // alert(this.date);
    }

    async getHour(value) {
        return value.split(':')[0];
        // alert(this.date);
    }

    async getMiniute(value) {
        return value.split(':')[1];
        // alert(this.date);
    }

    async combineDateAndTime(date, time) {
        let dateConvert = new Date(date);
        return new Date(dateConvert.getFullYear(), dateConvert.getMonth(), dateConvert.getDate(), await this.getHour(time), await this.getMiniute(time), 0);
    }

    async btnTimePN(param) {
        if (param == 'Next') {
            this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
            this.defaultEndDate = new Date();
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'Previous') {
            this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
            this.defaultEndDate = new Date();
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
            if (this.stage == 'Today') {
                this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()+1, 0, 0, 0);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()+1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Yesterday') {

                this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() , 0, 0, 0);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'This week') {
                this.defaultStartDate = await this.getNextMonday(this.today);
                this.defaultEndDate = await this.getNextSunday(this.today);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Last week') {
                this.stage = 'This week';
                this.defaultStartDate = await this.getMonday(this.today);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'This month') {
                this.defaultStartDate = await this.getStartNextMonth(this.today);
                let current = await this.getStartNextMonth(this.today);
                this.defaultEndDate = new Date(current.getFullYear(), current.getMonth()+1, current.getDate() - 1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Last month') {
                this.stage = 'This month';
                this.defaultStartDate = await this.getStartMonth(this.today);
                this.defaultEndDate = new Date();
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            }

        } else if (param == 'Previous') {
            if (this.stage == 'Today') {
                this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()-1, 0, 0, 0);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()-1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Yesterday') {

                this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 2, 0, 0, 0);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 2, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'This week') {
                this.defaultStartDate = await this.getLastMonday(this.today);
                this.defaultEndDate = await this.getLastSunday(this.today);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Last week') {

                this.defaultStartDate = await this.getLastMonday(await this.getLastMonday(this.today));
                this.defaultEndDate = await this.getLastSunday(await this.getLastSunday(this.today));
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'This month') {
                this.defaultStartDate = await this.getStartLastMonth(this.today);
                let current = await this.getStartMonth(this.today);
                this.defaultEndDate = new Date(current.getFullYear(), current.getMonth(), current.getDate() - 1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Last month') {
                this.defaultStartDate = await this.getStartLastMonth(await this.getStartLastMonth(this.today));
                let current = await this.getStartLastMonth(this.today);
                this.defaultEndDate = new Date(current.getFullYear(), current.getMonth(), current.getDate() - 1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            }
        }
    }

    async btnTime(param) {
        if (param == 'Today') {
            this.stage = 'Today';
            this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
            this.defaultEndDate = new Date();
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'Yesterday') {
            this.stage = 'Yesterday';
            this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 1, 0, 0, 0);
            this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 1, 0, 0, 0);
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'This week') {
            this.stage = 'This week';
            this.defaultStartDate = await this.getMonday(this.today);
            this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'Last week') {
            this.stage = 'Last week';
            this.defaultStartDate = await this.getLastMonday(this.today);
            this.defaultEndDate = await this.getLastSunday(this.today);
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'This month') {
            this.stage = 'This month';
            this.defaultStartDate = await this.getStartMonth(this.today);
            this.defaultEndDate = new Date();
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'Last month') {
            this.defaultStartDate = await this.getLastMonth(this.today);
            this.defaultEndDate = await this.getLastMonthEnd(this.today);
            this.stage = 'Last month';
            this.defaultStartDate = await this.getStartLastMonth(this.today);
            let current = await this.getStartMonth(this.today);
            this.defaultEndDate = new Date(current.getFullYear(), current.getMonth(), current.getDate() - 1, 0, 0, 0);
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        }
    }
    
    async getStartMonth(d) {
        d = new Date(d);
        return new Date(d.getFullYear(), d.getMonth(), 1);
    }

    async getLastMonth(d) {
        d = new Date(d);
        return new Date(d.getFullYear(), d.getMonth()-1, 1);
    }

    async getLastMonthEnd(d) {
        d = new Date(d);
        return new Date(d.getFullYear(), d.getMonth() , 0);
    }

    async getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    async getLastMonday(d) {
        d = new Date(d);
        d.setDate(d.getDate() - 7);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    async getNextMonday(d) {
        d = new Date(d);
        d.setDate(d.getDate() + 7);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    async getLastSunday(d) {
        d = new Date(d);
        d.setDate(d.getDate());
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -5 : 0); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }

    async getNextSunday(d) {
        d = new Date(d);
        d.setDate(d.getDate()+14);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -5 : 0); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }


    async getStartNextMonth(d) {
        d = new Date(d);
        return new Date(d.getFullYear(), d.getMonth()+1, 1);
    }

    async getStartLastMonth(d) {
        d = new Date(d);
        return new Date(d.getFullYear(), d.getMonth() - 1, 1);
    }


}

