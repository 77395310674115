import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../service/api.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DefaultVariable} from '../../../common/default.variable';
import * as XLSX from 'xlsx';
import {DatePipe} from '@angular/common';
import {AppComponent} from '../../../../app.component';
import {TranslateService} from '@ngx-translate/core';

declare let jQuery: any;

@Component({
    selector: 'app-report-total-bet',
    templateUrl: './forecast-report-profit.component.html',
    styleUrls: ['./forecast-report-profit.component.scss']
})
export class ForecastReportProfitComponent extends AppComponent implements OnInit {

    @ViewChild('printInvoice') myDiv: ElementRef<HTMLElement>;

    @ViewChild('TABLE', {static: false}) TABLE: ElementRef;
    title = 'Excel';


    public path: any = [];
    public password: any;
    public responseTransaction: any;

    public pageNumber: any;
    public level: any = 1;

    public maxPageLine: any = 20;
    public position: any = '';
    public currentPage: 1;
    public status_update: 'SUCCESS';
    public current_transaction: any;
    public status: any = '';

    public username: any;

    public total_fee: any = 0;
    public total_amount: any = 0;
    public service_id: any = '';
    public startDate: any;
    public startTime: any;
    public endDate: any;
    public endTime: any;
    sumDeposit: any = 0;
    sumWithdraw: any = 0;
    sumWallet: any = 0;
    sumWithdrawIncFee: any = 0;
    sumCost: any = 0;
    sumFee: any = 0;

    sumProfit: any = 0;


    public startPage: any = 1;
    public endPage: any = 20;

    public currentPosition: any = localStorage.getItem('role');
    public method: string = localStorage.getItem('payType');

    public category: any;
    public serviceCategory: any;


    public id: any = localStorage.getItem('id');


    constructor(
        public datepipe: DatePipe,
        private ref: ChangeDetectorRef,
        private apiService: ApiService,
        private _formBuilder: FormBuilder,
        private route: ActivatedRoute,
        public router: Router,
        public translate: TranslateService
    ) {
        super(translate);
    }


    async ngOnInit(): Promise<any> {
        this.current_transaction = {
            payment_id: '',
            order_id: '',
            amount: '',
            status: '',
            customer_actual_amount: '',
            merchant_fee: '',
            status_history: [],
        };
        await this.searchPayment();
        jQuery('.defult-active').addClass('active');
    }


    counter(i: number) {
        return new Array(i);
    }

    public async setDefault() {

    }


    async searchPayment() {
        this.sumDeposit = 0;
        this.sumProfit = 0;
        this.sumWallet = 0;
        this.sumWithdraw = 0;
        this.sumCost = 0;
        this.sumFee = 0;
        this.sumWithdrawIncFee = 0;


        this.responseTransaction = await this.apiService.getSummaryForecastProfit();

        for (let i = 0; i < this.responseTransaction.result.length; i++) {
            this.sumProfit += this.responseTransaction.result[i].profit;
            this.sumWallet += this.responseTransaction.result[i].wallet;
        }

        await this.sortByProfit();


    }

    async sortByProfit() {
        this.responseTransaction.result.sort(function(a, b) {
            return b.profit - a.profit;
        });

    }

    async modal(payment_id) {
        for (let i = 0; i < this.responseTransaction.result.transactions.length; i++) {
            if (payment_id == this.responseTransaction.result.transactions[i].payment_id) {
                this.current_transaction = this.responseTransaction.result.transactions[i];
                this.status_update = this.current_transaction.status;
                console.log(this.current_transaction);
            }
        }
        this.ref.detectChanges();
    }


}
