<body class="fixed-sn">
<div class="wrapper container">
    <app-header></app-header>
    <main>
        <h1>Payout</h1>


        <div class="tab-content section-group">

<!--            <div class="tab-pane" id="b-md" role="tabpanel" aria-labelledby="b-tab-md">-->
                <div class="form-row">
                    <div class="form-group col-12">
                        <div class="form-group row">
                            <div class="col-4 account-sub-title">Your Credit</div>
                            <div class="col-8 account-sub-value">
                                <div class="card-body">
                                    <div class="card-sub-info">
                                        <div class="row">
                                            <div class="col-4">
                                                <span class="card-sub-title">Dragon Coin</span>
                                            </div>
                                            <div class="col-4"><span
                                                    class="card-sub-value">{{userProfile.result.walletDw}}
                                                THB</span></div>
                                            <div class="col-4">
                                                <button class="btn btn-sm btn-yellow font-weight-bold" type="button"
                                                        (click)="payOut('dw')">Pay out
                                                </button>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-4"><span class="card-sub-title">Cash</span></div>
                                            <div class="col-4"><span
                                                    class="card-sub-value">{{userProfile.result.wallet}}
                                                THB</span></div>
                                            <div class="col-4">
                                                <button class="btn btn-sm btn-yellow font-weight-bold" type="button"
                                                        (click)="payOut('cash')">Pay out
                                                </button>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-4"><span class="card-sub-title">Credit</span></div>
                                            <div class="col-4"><span
                                                    class="card-sub-value">{{userProfile.result.walletCredit}}
                                                THB</span></div>
                                            <div class="col-4">
                                                <button class="btn btn-sm btn-yellow font-weight-bold" type="button"
                                                        (click)="payOut('credit')">Pay out
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
<!--            </div>-->

        </div>
    </main>
</div>
<!-- Modal -->
<div class="modal fade" id="modal-main" role="dialog" tabindex="-1" aria-hidden="true"></div>

</body>
