import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalDirective} from 'angular-bootstrap-md';
import {TranslateService} from '@ngx-translate/core';
import {AppComponent} from '../../../../app.component';
import {ApiService} from '../../../../service/api.service';

declare let jQuery: any;

@Component({
    selector: 'app-dashboard',
    templateUrl: './bot-setting.component.html'
})
export class BotSettingComponent extends AppComponent implements OnInit {

    @ViewChild('childModal') childModal: ModalDirective;

    bankList: any;
    action: any;
    currentBank: any;
    isActive: boolean = false;
    agent: number = 0;
    member: number = 0;
    wallet: any = localStorage.getItem('wallet');
    private type: string = '';

    loginForm: FormGroup;
    public password: any;


    constructor(
        private apiService: ApiService,
        private _formBuilder: FormBuilder,
        public translate: TranslateService
    ) {
        super(translate);
    }

    async ngOnInit(): Promise<any> {
        this.loginForm = this._formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        jQuery('.dashboard').addClass('active');
        this.bankList = await this.apiService.getBankList();
        this.currentBank = this.bankList.result[0];
        this.isActive = true;
    }

    async start(id) {
        let request = {
            bankId: id
        };
        let response = await this.apiService.startBot(request);
        alert(response.message);
        this.bankList = await this.apiService.getBankList();


    }

    async stop(id) {
        let request = {
            bankId: id
        };
        let response = await this.apiService.stopBot(request);
        alert(response.message);
        this.bankList = await this.apiService.getBankList();
    }

    async openModal(type, obj) {
        this.type = type;

        if (type === 'EDIT') {
            this.currentBank = obj;
        } else {
            this.currentBank = {};
            this.action = "ADD";
        }
        jQuery('#bankModal').modal({backdrop: 'static', keyboard: false});
    }


    async submit() {
        let request = {
            _id: this.currentBank._id,
            bank_name: this.currentBank.bankName,
            account_number: this.currentBank.accountNumber,
            account_name: this.currentBank.accountName,
            username: this.currentBank.user,
            password: this.currentBank.password,
            active: this.currentBank.active,
            no: this.currentBank.no,
            type: this.currentBank.type,
            account_set: this.currentBank.accountSet,
            file_name: this.currentBank.fileName,
            is_desktop: this.currentBank.isDesktop,
        };
        if (this.action === 'ADD') {
            let response = await this.apiService.addBot(request);
            if (response.code === 0) {
                alert(response.message);
            }
        } else {
            let response = await this.apiService.updateBot(request);
            if (response.code === 0) {
                alert(response.message);
            }
        }
    }
}

