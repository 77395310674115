<script src="bot-setting.component.ts"></script>

<body class="fixed-sn">
<div class="wrapper container">
    <app-header></app-header>
    <main>
        <ng-container *ngIf="isActive === false; else elseBlock">
            <app-spinner>
            </app-spinner>
        </ng-container>
        <ng-template #elseBlock>
            <div class="row">
                <div class="col">
                    <div class="card mb-4">
                        <div class="card-header">{{'bot.bankConfig'|translate}}</div>
                        <div class="card-body">
                            <ng-container *ngFor="let index of bankList.result">
                                <div class="row">
                                    <div class="col-1">
                                        <span *ngIf="index.status === 'START'" class="badge badge-success"
                                              title="Active">Start</span>
                                        <span *ngIf="index.status === 'STOP'" class="badge badge-danger" title="Active">Stop</span>
                                    </div>
                                    <div class="col-1">
                                        <button type="button" class="btn btn-success" tooltip="Start"
                                                (click)="start(index._id)"
                                                [disabled]="index.status === 'START' || !index.active || index.isSms">
                                            <i class="fa fa-play"></i>
                                        </button>
                                    </div>
                                    <div class="col-1">
                                        <button type="button" class="btn btn-danger" tooltip="Stop"
                                                [disabled]="index.status === 'STOP'" (click)="stop(index._id)">
                                            <i class="fa fa-stop"></i>
                                        </button>
                                    </div>
                                    <div class="col-1">
                                        <img src="../../../../../assets/img/banks/kbank.png" alt=""
                                             *ngIf="index.bankName === 'KBANK'"
                                             style="width: 20px">
                                        <img src="../../../../../assets/img/banks/scb.png" alt=""
                                             *ngIf="index.bankName === 'SCB'"
                                             style="width: 20px">
                                        <img src="../../../../../assets/img/banks/bay.png" alt=""
                                             *ngIf="index.bankName === 'BAY'"
                                             style="width: 20px">
                                    </div>
                                    <div class="col-2">
                                        {{index.accountNumber}}
                                    </div>
                                    <div class="col-2">
                                        {{index.accountName}}
                                    </div>
                                    <div class="col-1">
                                        <span *ngIf="index.isSms" class="badge badge-success" title="Type">SMS</span>
                                        <span *ngIf="!index.isSms" class="badge badge-danger"
                                              title="Type">Non-SMS</span>
                                    </div>
                                    <div class="col-1">
                                        {{index.ipAddress || '-' }}
                                    </div>
                                    <div class="col-1">

                                        <span *ngIf="index.active" class="badge badge-success"
                                              title="Active">Active</span>
                                        <span *ngIf="!index.active" class="badge badge-danger"
                                              title="Active">InActive</span>
                                    </div>
                                    <div class="col-1">
                                        <button type="button" class="btn btn-primary" tooltip="Edit"
                                                [disabled]="index.status === 'START'"
                                                (click)="openModal('EDIT', index)">
                                            <i class="fa fa-pencil"></i>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-1">
                    <button type="button" class="btn btn-lg btn-yellow font-weight-bold"
                            (click)="openModal('ADD','')">
                        เพิ่มธนาคาร
                    </button>
                </div>
            </div>

            <div id="bankModal" class="modal fade hide" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content model-customer-detail">

                        <div class="modal-header">
                            <h4 class="modal-title" style="color: white;">ข้อมูลธนาคาร</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div class="modal-body">
                            <!--                            <section widget class="widget">-->
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 main-header">


                                    <div class="row form-group">
                                        <label class="col-form-label col-md-2">ชื่อธนาคาร</label>
                                        <div class="col col-4">
                                            <div class="select-form">
                                                <div class="btn-group">
                                                    <select name="status" class="form-control-lg-select btn btn-primary"
                                                            [(ngModel)]="currentBank.bankName" name="bankName">
                                                        <option value="SCB">ธนาคารไทยพานิชย์</option>
                                                        <option value="KBANK">ธนาคารกสิกร</option>
                                                        <option value="BAY">ธนาคารกรุงศรี</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--                                                <section>-->
                                    <div class="row form-group">
                                        <label class="col-form-label col-md-2">ชื่อบัญชี</label>
                                        <div class="col col-4">
                                            <input type="text" name="accountName" class="form-control input-transparent"
                                                   [(ngModel)]="currentBank.accountName">

                                        </div>
                                        <label class="col-form-label col-md-2">หมายเลขบัญชี</label>
                                        <div class="col col-4">
                                            <input type="text" name="accountNumber"
                                                   class="form-control input-transparent"
                                                   [(ngModel)]="currentBank.accountNumber">
                                        </div>
                                    </div>
                                    <!--                                                </section>-->

                                    <!--                                                <section>-->
                                    <div class="row form-group">
                                        <label class="col-form-label col-md-2">ชื่อเข้าระบบ</label>
                                        <div class="col col-4">
                                            <input type="text" name="user" [(ngModel)]="currentBank.user"
                                                   class="form-control input-transparent">
                                        </div>
                                        <label class="col-form-label col-md-2">รหัสผ่าน</label>
                                        <div class="col col-4">
                                            <input type="text" name="password" [(ngModel)]="currentBank.password"
                                                   class="form-control input-transparent">
                                        </div>
                                    </div>
                                    <!--                                                </section>-->

                                    <!--                                                <section>-->
                                    <div class="row form-group">
                                        <label class="col-form-label col-md-2">ลำดับบัญชี</label>
                                        <div class="col col-4">
                                            <input type="text" name="no" [(ngModel)]="currentBank.no"
                                                   class="form-control input-transparent">
                                        </div>
                                        <label class="col-form-label col-md-2">บัญชีชุดที่</label>
                                        <div class="col col-4">
                                            <input type="text" name="accountSet" [(ngModel)]="currentBank.accountSet"
                                                   class="form-control input-transparent">
                                        </div>
                                    </div>
                                    <div class="row form-group">
                                        <label class="col-form-label col-md-2">Active</label>
                                        <div class="col col-4">
                                            <input type="checkbox"
                                                   name="active"
                                                   [(ngModel)]="currentBank.active">

                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-8 text-center">
                                            <button type="button" class="btn btn-lg btn-yellow font-weight-bold"
                                                    type="button" (click)="submit()">ทำรายการ
                                            </button>
                                            &nbsp;&nbsp;
                                            <button type="button" class="btn btn-lg btn-yellow font-weight-bold"
                                                    type="button" data-dismiss="modal">ปิด
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!--                            </section>-->
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
        </ng-template>
    </main>
</div>
</body>
