<body class="fixed-sn">
<div class="wrapper container">
    <app-header></app-header>
    <main>
        <h1>{{'report.forecast' | translate }}</h1>
        <div class="pb-3"></div>
        <!--        <div class="btn-toolbar mb-3" role="toolbar">-->

        <div class="btn-toolbar section-group" role="toolbar" style="padding-bottom: 0px !important;">
            <!--            <div class="form-row">-->
            <!--                <div class="form-group col-12">-->
            <div class="form-group col-md-12 row" style="padding-left: 0.6rem;">
                <label for="email"
                       class="col-form-label font-weight-bold">{{'common.merhcant' | translate }}</label>
                <div class="input-group table-search col-2" style="padding-left: 2.1rem;">
                    <input style="width: 150px;" type="text" class="form-control customer-border"
                           aria-describedby="button-addon2"
                           [(ngModel)]="username" name="username">
                </div>
            </div>
        </div>
        <br>
        <!--        </div>-->
        <div class="table-wrapper">

            <br/>
            <table class="table table-bordered sortTable" datatables="ng" dt-options="dtOptions">
                <thead>
                <tr style="border: 1px">
                    <td class="yellow" style="color: black" colspan="2">
                        <b>{{'report.total' | translate }}</b>
                    </td>
                    <td  class="yellow" style="color: black">
                        <b>{{sumWallet | number:'1.2-2'}}</b>
                    </td>
                    <td class="yellow" style="color: black" >
                        <b>{{sumProfit | number:'1.2-2'}}</b>
                    </td>
                    <td  class="yellow" style="color: black">

                    </td>
                </tr>
                </thead>
                <thead>
                <tr>
                    <th class="scaletd-1">{{'common.no' | translate }}</th>
                    <th class="scaletd-2"><span class="col-cell">{{'common.merchant' | translate }}</span></th>
                    <th class="scaletd-5"><span class="col-cell">{{'common.walletAmount' | translate }}</span></th>
                    <th class="scaletd-5"><span class="col-cell">{{'common.profit' | translate }}</span></th>
                    <th class="scaletd-6">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr class="hide">
                    <td></td>
                </tr>
                <tr *ngFor="let index of responseTransaction?.result | filter:username;let i=index">
                    <td class="tdNumber">{{i + 1}}</td>
                    <td>{{index.username}}</td>
                    <td>{{index.wallet | number:'1.2-2'}}</td>
                    <td>{{index.profit | number:'1.2-2'}}</td>
                    <td>
                        <a class="link" data-toggle="modal" data-target="#modal-transaction"
                           (click)="modal(index._id)">View</a>
                    </td>
                </tr>
                </tbody>

            </table>
        </div>

        <div class="modal fade" id="modal-transaction">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <!--                        <h4 class="modal-title">Payment information</h4>-->
                        <br/>
                    </div>
                    <div class="modal-body">
                        <form class="form-horizontal">
                            <div class="form-group row">
                                <div class="col-6">
                                    <fieldset>
                                        <legend style="color:blue;font-weight:bold;">Payment Detail</legend>
                                        <table class="table table-bordered sortTable" datatables="ng"
                                               dt-options="dtOptions">
                                            <tbody class="table-striped">
                                            <tr>
                                                <td><b>Merchant</b></td>
                                                <td>{{current_transaction.username}}</td>

                                            </tr>
                                            <tr>
                                                <td><b>Payment Id</b></td>
                                                <td>{{current_transaction.payment_id}}</td>

                                            </tr>
                                            <tr>
                                                <td><b>Order Id</b></td>
                                                <td>{{current_transaction.order_id}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Ref Id</b></td>
                                                <td>{{current_transaction.ref_id}}</td>

                                            </tr>
                                            <tr>
                                                <td><b>Amount</b></td>
                                                <td>{{current_transaction.amount}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Fee</b></td>
                                                <td>{{current_transaction.merchant_fee}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Net Amount</b></td>
                                                <td>{{current_transaction.customer_actual_amount}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Status</b></td>
                                                <td>{{current_transaction.status}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Account No.</b></td>
                                                <td>{{current_transaction.payer_account_no}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Account Name.</b></td>
                                                <td>{{current_transaction.payer_account_name}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Paid with Account No.</b></td>
                                                <td>{{current_transaction.payer_account_no_dest}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Paid with Account Name.</b></td>
                                                <td>{{current_transaction.payer_account_name_dest}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </fieldset>
                                </div>

                                <div class="col-6">
                                    <fieldset>
                                        <legend style="color:blue;font-weight:bold;">Payment History</legend>
                                        <table class="table table-bordered sortTable" datatables="ng"
                                               dt-options="dtOptions">
                                            <thead>
                                            <tr>
                                                <th style="text-align: left;"><span class="col-cell">Status</span></th>
                                                <th style="text-align: left;"><span class="col-cell">Date & Time</span>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody class="table-striped">
                                            <tr *ngFor="let index of current_transaction.status_history;let i= index">
                                                <td><b>{{index.status}}</b></td>
                                                <td>{{index.date | date:'d/M/yy, h:mm a'}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </fieldset>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
</body>


