<body class="fixed-sn">
<div class="wrapper container">
    <app-header></app-header>
    <main>
        <ng-container *ngIf="isActive === false; else elseBlock">
            <app-spinner>
            </app-spinner>
        </ng-container>
        <ng-template #elseBlock>
            <h1>{{'report.report' | translate }}</h1>
            <div class="pb-3"></div>
            <!--        <div class="btn-toolbar mb-3" role="toolbar">-->

            <div class="btn-toolbar section-group" role="toolbar" style="padding-bottom: 0px !important;">
                <!--            <div class="form-row">-->
                <!--                <div class="form-group col-12">-->
                <div class="form-group col-md-12 row" style="padding-left: 0.6rem;">
                    <label for="email"
                           class="col-form-label font-weight-bold">{{'common.merhcant' | translate }}</label>
                    <div class="input-group table-search col-2" style="padding-left: 2.1rem;">
                        <input style="width: 150px;" type="text" class="form-control customer-border"
                               aria-describedby="button-addon2"
                               [(ngModel)]="username" name="username">
                    </div>
                </div>
                <div class="row col-md-12">
                    <div class="form-group row" style="padding-left: 0.6rem;">
                        <label for="email" class="col-form-label font-weight-bold" set-lan="text:Start date_">Start date
                            :</label>
                        <div class="input-group table-search" style="padding-left: 2.1rem;">
                            <input style="width: 150px;" type="date" id="startDate" class="form-control customer-border"
                                   [ngModel]="defaultStartDate | date:'yyyy-MM-dd'" placeholder="Payment Id"
                                   aria-label="Start Date" name="defaultStartDate"
                                   (change)="setStartDate($event.target.value)">
                        </div>
                        <div style="padding-left: 0.7rem;">
                            <input type="time" data-date="12:00 AM" class="testDate form-control customer-border"
                                   [(ngModel)]="defaultStartTime" id="starttime" data-date-format="hh:mm A">
                        </div>
                    </div>

                    <div class="form-group row" style="padding-left: 2.5rem;">
                        <label for="email" class="col-form-label font-weight-bold" set-lan="text:To date_">To date
                            :</label>
                        <div class="input-group table-search" style="padding-left: 1rem;">
                            <input type="date" id="endDate" style="width: 150px;" class="form-control customer-border"
                                   aria-label="End Date" [ngModel]="defaultEndDate | date:'yyyy-MM-dd'"
                                   (change)="setEndtDate($event.target.value)">
                        </div>
                        <div class="input-group table-search" style="padding-left: 0.7rem;">
                            <input type="time" data-date="12:00 AM" class="testDate form-control customer-border"
                                   [(ngModel)]="defaultEndTime" id="endtime" data-date-format="hh:mm A">
                        </div>
                    </div>

                    <div class="form-group row" style="padding-left: 2rem;">
                        <button class="btn btn-yellow font-weight-bold m-0 px-3 py-2 z-depth-0 waves-effect btnMenu"
                                type="button" set-lan="text:Search" (click)="searchPayment(1)">Search
                        </button>
                    </div>
                    <a (click)="ExportTOExcel();" id="btnExport" set-lan="title:Export Excel" title="Export Excel"
                       style="display: initial; padding-left: 0.5rem;">
                        <img src="../../../../../assets/img/icons/Excel.png"
                             style="width: 37px; margin-left: .5rem; padding-top: 3px;"></a>
                    <label id="txtExport" set-lan="text:ExportNote"
                           style="font-size: 10pt; width: 185px; margin-left: 0.5rem; line-height: 1rem; display: initial;">Remark:
                        limit to 50000 records per download</label>
                </div>
                <div class="form-group col-md-12 row">
                    <label for="email" class="col-form-label font-weight-bold"
                           set-lan="text:Start date_">{{'common.service' | translate }}</label>
                    <div class="input-group table-search col-4">
                        <div class="select-form  customer-border" style="border-radius: 3px; margin-left: 2.5rem;">
                            <div class="btn-group">
                                <select name="service_id" class="form-control-lg-select btn btn-primary"
                                        [(ngModel)]="service_id">
                                    <option value="" selected>
                                        ALL
                                    </option>
                                    <option *ngFor="let service of serviceCategory.result" [value]=service.id>
                                        {{service.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <br>
            <div class="form-group col-md-12 row">
                <div class="btn-group btn-group-green mr-3" data-toggle="buttons">
                    <label class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                           (click)="btnTimePN('Previous');">
                        <input class="form-check-input" type="radio" name="options" id="option1" autocomplete="off">
                        <span set-lan="text:Previous">Previous</span>
                    </label>
                    <label class="btn btn-white border border-success z-depth-0 form-check-label defult-active waves-effect waves-light"
                           (click)="btnTime('Today');">
                        <input class="form-check-input" type="radio" name="options" id="option2" autocomplete="off">
                        <span set-lan="text:Today">Today</span>
                    </label>
                    <label class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                           (click)="btnTime('Yesterday');">
                        <input class="form-check-input" type="radio" name="options" id="option3" autocomplete="off">
                        <span set-lan="text:Yesterday">Yesterday</span>
                    </label>
                    <label class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                           (click)="btnTime('This week');">
                        <input class="form-check-input" type="radio" name="options" id="option4" autocomplete="off">
                        <span set-lan="text:This week">This week</span>
                    </label>
                    <label class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                           (click)="btnTime('Last week');">
                        <input class="form-check-input" type="radio" name="options" id="option5" autocomplete="off">
                        <span set-lan="text:Last week">Last week</span>
                    </label>
                    <label class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                           (click)="btnTime('This month');">
                        <input class="form-check-input" type="radio" name="options" id="option6" autocomplete="off">
                        <span set-lan="text:This month">This month</span>
                    </label>
                    <label class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                           (click)="btnTime('Last month');">
                        <input class="form-check-input" type="radio" name="options" id="option7" autocomplete="off">
                        <span set-lan="text:Last month">Last month</span>
                    </label>
                    <label class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                           (click)="btnTimePN('Next');">
                        <input class="form-check-input" type="radio" name="options" id="option8" autocomplete="off">
                        <span set-lan="text:Next">Next</span>
                    </label>
                </div>
            </div>
            <!--        </div>-->
            <div class="table-wrapper">


                <!--            <table class="table table-bordered sortTable" datatables="ng" dt-options="dtOptions">-->
                <!--                <thead>-->
                <!--                <tr>-->
                <!--                    <th class="scaletd-3"><span class="col-cell">{{'common.sumDeposit' | translate }}</span></th>-->
                <!--                    <th class="scaletd-4"><span class="col-cell">{{'common.sumWithdraw' | translate }}</span></th>-->
                <!--                    <th class="scaletd-5"><span class="col-cell">{{'common.sumWithdrawIncFee' | translate }}</span></th>-->
                <!--                    <th class="scaletd-5"><span class="col-cell">{{'common.sumCost' | translate }}</span></th>-->
                <!--                    <th class="scaletd-5"><span class="col-cell">{{'common.sumProfit' | translate }}</span></th>-->
                <!--                    <th class="scaletd-5"><span class="col-cell">{{'common.sumWalletAmount' | translate }}</span></th>-->
                <!--                </tr>-->
                <!--                <tbody>-->
                <!--                <tr class="total">-->
                <!--                    <td>-->
                <!--                        {{sumDeposit | number:'1.2-2'}}-->
                <!--                    </td>-->
                <!--                    <td>-->
                <!--                        {{sumWithdraw | number:'1.2-2'}}-->
                <!--                    </td>-->
                <!--                    <td>-->
                <!--                        {{sumWithdrawIncFee | number:'1.2-2'}}-->
                <!--                    </td>-->
                <!--                    <td>-->
                <!--                        {{sumCost | number:'1.2-2'}}-->
                <!--                    </td>-->
                <!--                    <td>{{sumProfit | number:'1.2-2'}}</td>-->
                <!--                    <td>-->
                <!--                        {{sumWallet | number:'1.2-2'}}-->
                <!--                    </td>-->
                <!--                </tr>-->
                <!--            </tbody>-->
                <!--            </table>-->
                <br/>
                <table class="table table-bordered sortTable" datatables="ng" dt-options="dtOptions">
                    <thead>
                    <tr style="border: 1px">
                        <td class="yellow" style="color: black" colspan="2">
                            <b>{{'report.total' | translate }}</b>
                        </td>
                        <td class="yellow" style="color: black">
                            <b>{{sumDeposit | number:'1.2-2'}}</b>
                        </td>
                        <td class="yellow" style="color: black">
                            <b>{{sumWithdraw | number:'1.2-2'}}</b>
                        </td>
                        <td class="yellow" style="color: black">
                            <b>{{sumWithdrawIncFee | number:'1.2-2'}}</b>
                        </td>
                        <td class="yellow" style="color: black">

                        </td>
                        <td class="yellow" style="color: black">

                        </td>
                        <td class="yellow" style="color: black">
                            <b>{{sumCost | number:'1.2-2'}}</b>
                        </td>
                        <td class="yellow hide" style="color: black">

                        </td>
                        <td class="yellow" style="color: black">

                        </td>

                        <td class="yellow" style="color: black">

                        </td>
                        <td class="yellow" style="color: black">
                            <b>{{sumProfit | number:'1.2-2'}}</b>
                        </td>
                        <td class="yellow" style="color: black">
                        </td>
                    </tr>
                    </thead>
                    <thead>
                    <tr>
                        <th class="scaletd-1" rowspan="2">{{'common.no' | translate }}</th>
                        <th class="scaletd-2" rowspan="2"><span
                                class="col-cell">{{'common.merchant' | translate }}</span>
                        </th>
                        <th class="scaletd-3" rowspan="2"><span
                                class="col-cell">{{'common.deposit' | translate }}</span>
                        </th>
                        <th class="scaletd-4" rowspan="2"><span
                                class="col-cell">{{'common.withdraw' | translate }}</span>
                        </th>
                        <th class="scaletd-4" rowspan="2"><span
                                class="col-cell">{{'common.withdrawIncFee' | translate }}</span>
                        </th>
                        <th class="scaletd-5" colspan="3" style="text-align: center">
                            <span class="col-cell">Vpay</span>
                        </th>
                        <th class="scaletd-5" colspan="3" style="text-align: center">
                            <span class="col-cell">Company</span>
                        </th>
                        <th class="scaletd-6" rowspan="2">Actions</th>
                    </tr>
                    <tr>
                        <th class="scaletd-5"><span class="col-cell">{{'common.vpayFee' | translate }}</span></th>
                        <th class="scaletd-5"><span class="col-cell">{{'common.withdrawIncFee' | translate }}</span></th>
                        <th class="scaletd-5"><span class="col-cell">{{'common.cost' | translate }}</span></th>
                        <th class="scaletd-5"><span class="col-cell">{{'common.fee' | translate }}</span></th>
                        <th class="scaletd-5"><span class="col-cell">{{'common.withdrawIncFee' | translate }}</span></th>
                        <th class="scaletd-5"><span class="col-cell">{{'common.profit' | translate }}</span></th>
                    </tr>
                    <tr>

                        <!--                                <th class="scaletd-5 hide"><span-->
                        <!--                                        class="col-cell">{{'common.walletAmount' | translate }}</span>-->
                        <!--                                </th>-->

                        <!--                                <th class="scaletd-5 hide"><span-->
                        <!--                                        class="col-cell">{{'common.walletAmount' | translate }}</span>-->
                        <!--                                </th>-->


                    </tr>
                    </thead>
                    <tbody>
                    <tr class="hide">
                        <td></td>
                    </tr>
                    <tr *ngFor="let index of responseTransaction?.result.data;let i=index">
                        <td class="tdNumber">{{i + 1}}</td>
                        <td>{{index.merchant}}</td>
                        <td>{{index.deposit | number:'1.2-2'}}</td>
                        <td>{{index.withdraw | number:'1.2-2'}}</td>
                        <td>{{index.withdraw_inc_fee | number:'1.2-2'}}</td>
                        <td>{{index.vpay_fee | number:'1.2-2'}} %</td>
                        <td>{{index.withdraw - index.cost | number:'1.2-2'}}</td>
                        <td>{{index.cost | number:'1.2-2'}}</td>
                        <td>{{ index.payout_fee - index.vpay_fee  | number:'1.2-2'}} %</td>
                        <td>{{index.withdraw - index.cost- index.profit | number:'1.2-2'}}</td>
                        <td>{{index.profit | number:'1.2-2'}}</td>
                        <td>
                            <a class="link" data-toggle="modal" data-target="#modal-transaction"
                               (click)="modal(index._id)">View</a>
                        </td>
                    </tr>
                    </tbody>

                </table>
            </div>

            <div class="modal fade" id="modal-transaction">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <!--                        <h4 class="modal-title">Payment information</h4>-->
                            <br/>
                        </div>
                        <div class="modal-body">
                            <form class="form-horizontal">
                                <div class="form-group row">
                                    <div class="col-6">
                                        <fieldset>
                                            <legend style="color:blue;font-weight:bold;">Payment Detail</legend>
                                            <table class="table table-bordered sortTable" datatables="ng"
                                                   dt-options="dtOptions">
                                                <tbody class="table-striped">
                                                <tr>
                                                    <td><b>Merchant</b></td>
                                                    <td>{{current_transaction.username}}</td>

                                                </tr>
                                                <tr>
                                                    <td><b>Payment Id</b></td>
                                                    <td>{{current_transaction.payment_id}}</td>

                                                </tr>
                                                <tr>
                                                    <td><b>Order Id</b></td>
                                                    <td>{{current_transaction.order_id}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Ref Id</b></td>
                                                    <td>{{current_transaction.ref_id}}</td>

                                                </tr>
                                                <tr>
                                                    <td><b>Amount</b></td>
                                                    <td>{{current_transaction.amount}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Fee</b></td>
                                                    <td>{{current_transaction.merchant_fee}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Net Amount</b></td>
                                                    <td>{{current_transaction.customer_actual_amount}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Status</b></td>
                                                    <td>{{current_transaction.status}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Account No.</b></td>
                                                    <td>{{current_transaction.payer_account_no}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Account Name.</b></td>
                                                    <td>{{current_transaction.payer_account_name}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Paid with Account No.</b></td>
                                                    <td>{{current_transaction.payer_account_no_dest}}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Paid with Account Name.</b></td>
                                                    <td>{{current_transaction.payer_account_name_dest}}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </fieldset>
                                    </div>

                                    <div class="col-6">
                                        <fieldset>
                                            <legend style="color:blue;font-weight:bold;">Payment History</legend>
                                            <table class="table table-bordered sortTable" datatables="ng"
                                                   dt-options="dtOptions">
                                                <thead>
                                                <tr>
                                                    <th style="text-align: left;"><span class="col-cell">Status</span>
                                                    </th>
                                                    <th style="text-align: left;"><span
                                                            class="col-cell">Date & Time</span>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody class="table-striped">
                                                <tr *ngFor="let index of current_transaction.status_history;let i= index">
                                                    <td><b>{{index.status}}</b></td>
                                                    <td>{{index.date | date:'d/M/yy, h:mm a'}}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </fieldset>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </main>
</div>
</body>


