<script src="register.component.ts"></script>

<body class="fixed-sn">
<div class="wrapper container">
    <app-header></app-header>

    <main>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">{{'sidebar.setting' | translate }}</li>
                <li class="breadcrumb-item active">
                    <ng-container *ngIf="role=='ADMIN'" >
                        {{ 'sidebar.merchantAccountSetting' | translate }}
                    </ng-container>

                    <ng-container *ngIf="role=='MERCHANT'" >
                        Add Sub Account
                    </ng-container>
                </li>
            </ol>
        </nav>
        <h1>
            <ng-container *ngIf="role=='ADMIN'" >
                {{ 'sidebar.merchantAccountSetting' | translate }}
            </ng-container>
            <ng-container *ngIf="role=='MERCHANT'" >
                Add Sub Account
            </ng-container>

        </h1>
        <ng-container *ngIf="this.role==='ADMIN'">
            <div class="section-group mb-4">
                <div class="form-row">

                    <div class="form-group col-5">
                        <div class="title-holder row">
                            <h4 class="col-4">Basic Info</h4>
                            <div class="col-8"></div>
                        </div>
                        <div class="form-group row">
                            <label for="username" class="col-4 col-form-label">Username</label>
                            <div class="col-8">
                                <input type="text" class="form-control" id="username" placeholder="Enter username"
                                       aria-describedby="usernameHelper" name="username" [(ngModel)]="username">
                                <small id="usernameHelper" class="text-muted form-text">
                                    Will be used as a login
                                </small>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="remark" class="col-4 col-form-label">Remark</label>
                            <div class="col-8">
                                <input type="text" class="form-control" id="remark" placeholder="Enter remark"
                                       aria-describedby="remarkHelper">
                                <small id="remarkHelper" class="text-muted form-text">
                                    Remark to identify the user. Name, nickname or anything else
                                </small>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="password" class="col-4 col-form-label">Password</label>
                            <div class="col-8">
                                <div class="md-form md-outline" >
                                    <input id="password" type="password" class="form-control   mb-0" autocomplete="off" name="password" [(ngModel)]="password" placeholder="Enter password">
<!--                                    <label for="password" class="label">Enter password</label>-->
                                    <span toggle="#password" class="toggle-password field-icon">
                                        <i class="fa"
                                           [class]="{'fa-low-vision': fieldTextTypess, 'fa-eye': !fieldTextTypess}"
                                           (click)="toggleFieldTextType()"></i></span>
                                </div>
                                <div class="" style="margin-top: 8px">
                                    <a href="" class="link link-sm mr-3">Set random</a>
                                    <a href="" class="link link-sm">Copy to clipboard</a>
                                </div>
                                <small id="passwordHelper" class="text-muted form-text pt-1">Password must contain at
                                    least 8 characters</small>
                                <small class="text-muted form-text">Password strength</small>
                                <div id="progress">
                                    <div id="progress-bar" class="progress-bar"></div>
                                    <span class="password-strength" data-warning="Middle" data-error="Weak"
                                          data-success="Strong"></span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="line" class="col-4 col-form-label">Prefix</label>
                            <div class="col-8">
                                <input type="text" class="form-control" id="prefix" placeholder="Prefix" [(ngModel)]="prefix" name="prefix">
                            </div>
                        </div>
<!--                        <div class="form-group row">-->
<!--                            <label for="line" class="col-4 col-form-label">Account no.</label>-->
<!--                            <div class="col-8">-->
<!--                                <input type="text" class="form-control" id="account_no" placeholder="Enter Line" [(ngModel)]="" name="account_no">-->
<!--                            </div>-->
<!--                        </div>-->
                        <div class="form-group row">
                            <div class="col-12">
                                <div class="switch">
                                    <label>
                                        <input type="checkbox" checked name="status" [(ngModel)]="isActive">
                                        <span class="lever ml-0"></span>
                                        <span class="blue-text text-13">Active</span>
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-1"></div>
                    <div class="form-group col-5">
                        <div class="title-holder row">
                            <h4 class="col-6">Personal Info</h4>
                            <div class="col-6"></div>
                        </div>
                        <div class="form-group row">
                            <label for="email" class="col-4 col-form-label">Email</label>
                            <div class="col-8">
                                <input type="text" class="form-control" id="email" placeholder="Enter Email" [(ngModel)]="email_address" name="email_address">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="line" class="col-4 col-form-label">Company name</label>
                            <div class="col-8">
                                <input type="text" class="form-control" id="company_name" placeholder="Company Name" [(ngModel)]="company_name" name="company_name">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="line" class="col-4 col-form-label">Bank Name</label>
                            <div class="col-8">
                                <div class="select-form">
                                    <div class="btn-group">
                                        <select name="status" class="form-control form-control-sm"
                                                [(ngModel)]="bank_name">
                                            <option value="NO">---- เลือกธนาคารของท่าน ----</option>
                                            <option value="SCB">ธนาคารไทยพาณิชย์ จำกัด (มหาชน)</option>
                                            <option value="BBL">ธนาคารกรุงเทพ จำกัด (มหาชน)</option>
                                            <option value="KBANK">ธนาคารกสิกรไทย จำกัด (มหาชน)</option>
                                            <option value="KTB">ธนาคารกรุงไทย จำกัด (มหาชน)</option>
                                            <option value="BAAC">ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร</option>
                                            <option value="TMB">ธนาคารทหารไทย จำกัด (มหาชน)</option>
                                            <option value="CIMB">ธนาคาร ซีไอเอ็มบี ไทย จำกัด (มหาชน)</option>
                                            <option value="UOB">ธนาคารยูโอบี จำกัด (มหาชน)</option>
                                            <option value="BAY">ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)</option>
                                            <option value="GSB">ธนาคารออมสิน</option>
                                            <option value="LHBANK">ธนาคารแลนด์ แอนด์ เฮ้าส์ จำกัด (มหาชน)
                                            </option>
                                            <option value="TBANK">ธนาคารธนชาต จำกัด (มหาชน)</option>
                                            <option value="TISCO">ธนาคารทิสโก้ จำกัด (มหาชน)</option>
                                            <option value="KKP">ธนาคารเกียรตินาคิน จำกัด (มหาชน)</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="line" class="col-4 col-form-label">Bank Account No</label>
                            <div class="col-8">
                                <input type="text" class="form-control" id="account_number" placeholder="Bank Account No" [(ngModel)]="account_number" name="account_number">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="status" class="col-4 col-form-label">Role</label>
                            <div class="col-8">
                                <div class="select-form">
                                    <div class="btn-group">
                                        <select name="status" class="form-control form-control-sm"
                                                [(ngModel)]="role_register">
                                            <option selected value="MERCHANT">MERCHANT</option>
                                            <option value="ADMIN">ADMIN</option>
                                            <option value="ADMIN_READ_ONLY">ADMIN_READ_ONLY</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="this.role==='MERCHANT'">
            <div class="section-group mb-4">
                <div class="form-row">

                    <div class="form-group col-5">
                        <div class="title-holder row">
                            <h4 class="col-4">Basic Info</h4>
                            <div class="col-8"></div>
                        </div>
                        <div class="form-group row">
                            <label for="username" class="col-4 col-form-label">Username</label>
                            <div class="col-2">
                                <input type="text" class="form-control" id="prefix_register" placeholder="Enter prefix_register"
                                       [(ngModel)]="prefix_register" name="prefix_register"
                                       aria-describedby="usernameHelper" readonly>
                            </div>
                            <div class="col-6">

                                <input type="text" class="form-control" id="username" placeholder="Enter username"
                                       [(ngModel)]="username" name="username"
                                       aria-describedby="usernameHelper">
                                <small id="usernameHelper" class="text-muted form-text">
                                    Will be used as a login
                                </small>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="remark" class="col-4 col-form-label">Remark</label>
                            <div class="col-8">
                                <input type="text" class="form-control" id="remark" placeholder="Enter remark"
                                       aria-describedby="remarkHelper">
                                <small id="remarkHelper" class="text-muted form-text">
                                    Remark to identify the user. Name, nickname or anything else
                                </small>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="password" class="col-4 col-form-label">Password</label>
                            <div class="col-8">
                                <div class="md-form md-outline">
                                    <input id="password" type="password"
                                           [(ngModel)]="password" name="password"
                                           class="form-control   mb-0" autocomplete="off" placeholder="Enter password">
<!--                                    <label for="password" class="label">Enter password</label>-->
                                    <span toggle="#password" class="toggle-password field-icon">
                                        <i class="fa"
                                           [class]="{'fa-low-vision': fieldTextTypess, 'fa-eye': !fieldTextTypess}"
                                           (click)="toggleFieldTextType()"></i></span>
                                </div>
                                <div class="">
                                    <a  class="link link-sm mr-3">Set random</a>
                                    <a  class="link link-sm">Copy to clipboard</a>
                                </div>
                                <small class="text-muted form-text">Password strength</small>
                                <div id="progress">
                                    <div id="progress-bar" class="progress-bar"></div>
                                    <span class="password-strength" data-warning="Middle" data-error="Weak"
                                          data-success="Strong"></span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-12">
                                <div class="switch">
                                    <label>
                                        <input type="checkbox" checked name="status" [(ngModel)]="isActive">
                                        <span class="lever ml-0"></span>
                                        <span class="blue-text text-13">Active</span>
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-1"></div>
                    <div class="form-group col-5">
                        <div class="title-holder row">
                            <h4 class="col-6">Personal Info</h4>
                            <div class="col-6"></div>
                        </div>
                        <div class="form-group row">
                            <label for="email" class="col-4 col-form-label">Firstname</label>
                            <div class="col-8">
                                <input type="text" class="form-control" id="firstname" placeholder="Enter Email"  [(ngModel)]="firstname" name="firstname">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="line" class="col-4 col-form-label">Lastname</label>
                            <div class="col-8">
                                <input type="text" class="form-control" id="lastname" placeholder="Enter Line" [(ngModel)]="lastname" name="lastname">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="status" class="col-4 col-form-label">Role</label>
                            <div class="col-8">
                                <div class="select-form">
                                    <div class="select-form">
                                        <div class="btn-group">
                                            <select name="status" class="form-control form-control-sm"
                                                    [(ngModel)]="role_register">
                                                <option value="STAFF">STAFF</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <!--            <div class="form-row">-->
        <!--                <div class="form-group col-5">-->
        <!--                    <div class="pb-2 pt-1"></div>-->
        <!--                    <h4>Permissions</h4>-->
        <!--                    <div class="form-group">-->
        <!--                        <div class="row mb-1">-->
        <!--                            <label class="col-4 col-form-label input-text-label">Member management</label>-->
        <!--                            <div class="col-8">-->
        <!--                                <div class="form-check form-check-inline">-->
        <!--                                    <input type="radio" class="form-check-input" id="MemberManagementRadio_off" name="MemberManagementRadio">-->
        <!--                                    <label class="form-check-label red-text" for="MemberManagementRadio_off">Off</label>-->
        <!--                                </div>-->
        <!--                                <div class="form-check form-check-inline">-->
        <!--                                    <input type="radio" checked class="form-check-input" id="MemberManagementRadio_view" name="MemberManagementRadio">-->
        <!--                                    <label class="form-check-label orange-text" for="MemberManagementRadio_view">View</label>-->
        <!--                                </div>-->
        <!--                                <div class="form-check form-check-inline">-->
        <!--                                    <input type="radio" class="form-check-input" id="MemberManagementRadio_edit" name="MemberManagementRadio">-->
        <!--                                    <label class="form-check-label green-text" for="MemberManagementRadio_edit">Edit</label>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        <div class="row mb-1">-->
        <!--                            <label class="col-4 col-form-label input-text-label">Report</label>-->
        <!--                            <div class="col-8">-->
        <!--                                <div class="form-check form-check-inline">-->
        <!--                                    <input type="radio" class="form-check-input" id="ReportRadio_off" name="ReportRadio">-->
        <!--                                    <label class="form-check-label red-text" for="ReportRadio_off">Off</label>-->
        <!--                                </div>-->
        <!--                                <div class="form-check form-check-inline">-->
        <!--                                    <input type="radio" checked class="form-check-input" id="ReportRadio_view" name="ReportRadio">-->
        <!--                                    <label class="form-check-label orange-text" for="ReportRadio_view">View</label>-->
        <!--                                </div>-->
        <!--                                <div class="form-check form-check-inline">-->
        <!--                                    <input type="radio" class="form-check-input" id="ReportRadio_edit" name="ReportRadio">-->
        <!--                                    <label class="form-check-label green-text" for="ReportRadio_edit">Edit</label>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        <div class="row mb-1">-->
        <!--                            <label class="col-4 col-form-label input-text-label">Payment</label>-->
        <!--                            <div class="col-8">-->
        <!--                                <div class="form-check form-check-inline">-->
        <!--                                    <input type="radio" class="form-check-input" id="PaymentRadio_off" name="PaymentRadio">-->
        <!--                                    <label class="form-check-label red-text" for="PaymentRadio_off">Off</label>-->
        <!--                                </div>-->
        <!--                                <div class="form-check form-check-inline">-->
        <!--                                    <input type="radio" checked class="form-check-input" id="PaymentRadio_view" name="PaymentRadio">-->
        <!--                                    <label class="form-check-label orange-text" for="PaymentRadio_view">View</label>-->
        <!--                                </div>-->
        <!--                                <div class="form-check form-check-inline">-->
        <!--                                    <input type="radio" class="form-check-input" id="PaymentRadio_edit" name="PaymentRadio">-->
        <!--                                    <label class="form-check-label green-text" for="PaymentRadio_edit">Edit</label>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        <div class="row mb-1">-->
        <!--                            <label class="col-4 col-form-label input-text-label">Announcements</label>-->
        <!--                            <div class="col-8">-->
        <!--                                <div class="form-check form-check-inline">-->
        <!--                                    <input type="radio" class="form-check-input" id="AnnouncementsRadio_off" name="AnnouncementsRadio">-->
        <!--                                    <label class="form-check-label red-text" for="AnnouncementsRadio_off">Off</label>-->
        <!--                                </div>-->
        <!--                                <div class="form-check form-check-inline">-->
        <!--                                    <input type="radio" checked class="form-check-input" id="AnnouncementsRadio_view" name="AnnouncementsRadio">-->
        <!--                                    <label class="form-check-label orange-text" for="AnnouncementsRadio_view">View</label>-->
        <!--                                </div>-->
        <!--                                <div class="form-check form-check-inline">-->
        <!--                                    <input type="radio" class="form-check-input" id="AnnouncementsRadio_edit" name="AnnouncementsRadio">-->
        <!--                                    <label class="form-check-label green-text" for="AnnouncementsRadio_edit">Edit</label>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        <div class="row mb-1">-->
        <!--                            <label class="col-4 col-form-label input-text-label">Limit settings</label>-->
        <!--                            <div class="col-8">-->
        <!--                                <div class="form-check form-check-inline">-->
        <!--                                    <input type="radio" class="form-check-input" id="LimitSettingsRadio_off" name="LimitSettingsRadio">-->
        <!--                                    <label class="form-check-label red-text" for="LimitSettingsRadio_off">Off</label>-->
        <!--                                </div>-->
        <!--                                <div class="form-check form-check-inline">-->
        <!--                                    <input type="radio" checked class="form-check-input" id="LimitSettingsRadio_view" name="LimitSettingsRadio">-->
        <!--                                    <label class="form-check-label orange-text" for="LimitSettingsRadio_view">View</label>-->
        <!--                                </div>-->
        <!--                                <div class="form-check form-check-inline">-->
        <!--                                    <input type="radio" class="form-check-input" id="LimitSettingsRadio_edit" name="LimitSettingsRadio">-->
        <!--                                    <label class="form-check-label green-text" for="LimitSettingsRadio_edit">Edit</label>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                        <div class="row mb-1">-->
        <!--                            <label class="col-4 col-form-label input-text-label">Draw management</label>-->
        <!--                            <div class="col-8">-->
        <!--                                <div class="form-check form-check-inline">-->
        <!--                                    <input type="radio" class="form-check-input" id="DrawManagementRadio_off" name="DrawManagementRadio">-->
        <!--                                    <label class="form-check-label red-text" for="DrawManagementRadio_off">Off</label>-->
        <!--                                </div>-->
        <!--                                <div class="form-check form-check-inline">-->
        <!--                                    <input type="radio" checked class="form-check-input" id="DrawManagementRadio_view" name="DrawManagementRadio">-->
        <!--                                    <label class="form-check-label orange-text" for="DrawManagementRadio_view">View</label>-->
        <!--                                </div>-->
        <!--                                <div class="form-check form-check-inline">-->
        <!--                                    <input type="radio" class="form-check-input" id="DrawManagementRadio_edit" name="DrawManagementRadio">-->
        <!--                                    <label class="form-check-label green-text" for="DrawManagementRadio_edit">Edit</label>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div class="pb-2 pt-1"></div>-->
        <div class="button-group">
            <ng-container *ngIf="role=='ADMIN'" >
            <button class="btn btn-lg btn-yellow font-weight-bold" type="submit" (click)="registeration()">Add Merchant
                Account
            </button>
            </ng-container>
            <ng-container *ngIf="role=='MERCHANT'" >
                <button class="btn btn-lg btn-yellow font-weight-bold" type="submit" (click)="registeration()">Add Sub
                    Account
                </button>
            </ng-container>
        </div>
        <!--                </div>-->
        <!--                <div class="col-1"></div>-->
        <!--                <div class="form-group col-5"></div>-->
        <!--            </div>-->

    </main>


    <!--    <main>-->
    <!--        <ng-container *ngIf="isActive === false; else elseBlock">-->
    <!--            <app-spinner>-->
    <!--            </app-spinner>-->
    <!--        </ng-container>-->
    <!--        <ng-template #elseBlock>-->
    <!--            <ng-container *ngIf="this.role==='ADMIN'">-->
    <!--                <div class="row">-->
    <!--                    <div class="col">-->
    <!--                        <div class="card mb-12">-->
    <!--                            <div class="card-header">Register</div>-->
    <!--                            <div class="card-body">-->
    <!--                                <div class="row">-->
    <!--                                    <div class="col-2"><span-->
    <!--                                            class="card-total-row">Username: </span></div>-->
    <!--                                    <div class="col-3"><span class="card-sub-value-total">-->
    <!--                                     <div class="mb-1">-->
    <!--                                        <input type="text" class="form-control" id="username"-->
    <!--                                               placeholder="Enter username"-->
    <!--                                               aria-describedby="remarkHelper"-->
    <!--                                               [(ngModel)]="username" name="username">-->
    <!--                                        <small id="remarkHelper" class="text-muted form-text">-->
    <!--                                           Enter Your Username-->
    <!--                                        </small>-->
    <!--                                    </div>-->
    <!--                                </span></div>-->

    <!--                                    <div class="col-2"><span-->
    <!--                                            class="card-total-row">Password: </span></div>-->
    <!--                                    <div class="col-3"><span class="card-sub-value-total">-->
    <!--                                     <div class="mb-1">-->
<!--                                            <input type="text" class="form-control" id="password"-->
<!--                                                   placeholder="Enter password"-->
<!--                                                   aria-describedby="remarkHelper"-->
<!--                                                   [(ngModel)]="password" name="password">-->
<!--                                            <small id="remarkHelper" class="text-muted form-text">-->
    <!--                                          Enter Your Password-->
    <!--                                        </small>-->
    <!--                                    </div>-->
    <!--                                </span></div>-->
    <!--                                </div>-->

    <!--                                <div class="row">-->
    <!--                                    <div class="col-2"><span-->
    <!--                                            class="card-total-row">Role: </span></div>-->
    <!--                                    <div class="col-3"><span class="card-sub-value-total">-->
    <!--                                      <div class="select-form">-->
    <!--                                            <div class="btn-group">-->
    <!--                                                <select name="status" class="form-control form-control-sm"-->
    <!--                                                        [(ngModel)]="role_register">-->
    <!--                                                    <option selected value="MERCHANT">MERCHANT</option>-->
    <!--                                                    <option value="ADMIN">ADMIN</option>-->
    <!--                                                    <option value="ADMIN_READ_ONLY">ADMIN_READ_ONLY</option>-->
    <!--                                                </select>-->
    <!--                                            </div>-->
    <!--                                        </div>-->
    <!--                                </span></div>-->
    <!--                                    <div class="col-2"><span-->
    <!--                                            class="card-total-row">Bank Name : </span></div>-->
    <!--                                    <div class="col-4"><span class="card-sub-value-total">-->
    <!--&lt;!&ndash;                                     <div class="input-group table-search col-3">&ndash;&gt;-->
<!--                                            <div class="select-form">-->
<!--                                                <div class="btn-group">-->
<!--                                                    <select name="status" class="form-control-lg-select btn btn-primary"-->
<!--                                                            [(ngModel)]="bank_name">-->
<!--                                                        <option value="NO">&#45;&#45;&#45;&#45; เลือกธนาคารของท่าน &#45;&#45;&#45;&#45;</option>-->
<!--                                                        <option value="SCB">ธนาคารไทยพาณิชย์ จำกัด (มหาชน)</option>-->
<!--                                                        <option value="BBL">ธนาคารกรุงเทพ จำกัด (มหาชน)</option>-->
<!--                                                        <option value="KBANK">ธนาคารกสิกรไทย จำกัด (มหาชน)</option>-->
<!--                                                        <option value="KTB">ธนาคารกรุงไทย จำกัด (มหาชน)</option>-->
<!--                                                        <option value="BAAC">ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร</option>-->
<!--                                                        <option value="TMB">ธนาคารทหารไทย จำกัด (มหาชน)</option>-->
<!--                                                        <option value="CIMB">ธนาคาร ซีไอเอ็มบี ไทย จำกัด (มหาชน)</option>-->
<!--                                                        <option value="UOB">ธนาคารยูโอบี จำกัด (มหาชน)</option>-->
<!--                                                        <option value="BAY">ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)</option>-->
<!--                                                        <option value="GSB">ธนาคารออมสิน</option>-->
<!--                                                        <option value="LHBANK">ธนาคารแลนด์ แอนด์ เฮ้าส์ จำกัด (มหาชน)-->
<!--                                                        </option>-->
<!--                                                        <option value="TBANK">ธนาคารธนชาต จำกัด (มหาชน)</option>-->
<!--                                                        <option value="TISCO">ธนาคารทิสโก้ จำกัด (มหาชน)</option>-->
<!--                                                        <option value="KKP">ธนาคารเกียรตินาคิน จำกัด (มหาชน)</option>-->
<!--                                                    </select>-->
<!--                                                </div>-->
<!--                                            </div>-->
    <!--                                        &lt;!&ndash;                                    </div>&ndash;&gt;-->
    <!--                                </span></div>-->
    <!--                                </div>-->

    <!--                                <div class="row">-->

    <!--                                    <div class="col-2"><span-->
    <!--                                            class="card-total-row">Email : </span></div>-->
    <!--                                    <div class="col-3"><span class="card-sub-value-total">-->
    <!--                                     <div class="mb-1">-->
    <!--                                        <input type="text" class="form-control" id="email_address"-->
    <!--                                               placeholder="Enter email_address"-->
    <!--                                               aria-describedby="remarkHelper"-->
    <!--                                               [(ngModel)]="email_address" name="email_address">-->
    <!--                                    </div>-->
    <!--                                </span></div>-->


    <!--                                    <div class="col-2"><span-->
    <!--                                            class="card-total-row">Company name: </span></div>-->
    <!--                                    <div class="col-3"><span class="card-sub-value-total">-->
    <!--                                     <div class="mb-1">-->
    <!--                                        <input type="text" class="form-control" id="company_name"-->
    <!--                                               placeholder="Enter company_name"-->
    <!--                                               aria-describedby="remarkHelper"-->
    <!--                                               [(ngModel)]="company_name" name="company_name">-->
    <!--                                    </div>-->
    <!--                                </span></div>-->
    <!--                                </div>-->

    <!--                                <div class="row">-->

    <!--                                    <div class="col-2"><span-->
    <!--                                            class="card-total-row">Prefix: </span></div>-->
    <!--                                    <div class="col-3"><span class="card-sub-value-total">-->
    <!--                                     <div class="mb-1">-->
    <!--                                        <input type="text" class="form-control" id="prefix" placeholder="Enter prefix"-->
    <!--                                               aria-describedby="remarkHelper"-->
    <!--                                               [(ngModel)]="prefix" name="prefix">-->
    <!--                                        <small id="remarkHelper" class="text-muted form-text">-->
    <!--                                          Enter Your Password-->
    <!--                                        </small>-->
    <!--                                    </div>-->
    <!--                                </span></div>-->


    <!--                                    <div class="col-2"><span-->
    <!--                                            class="card-total-row">Account No: </span></div>-->
    <!--                                    <div class="col-3"><span class="card-sub-value-total">-->
    <!--                                     <div class="mb-1">-->
    <!--                                        <input type="text" class="form-control" id="account_number"-->
    <!--                                               placeholder="Enter account_number"-->
    <!--                                               aria-describedby="remarkHelper"-->
    <!--                                               [(ngModel)]="account_number" name="account_number">-->
    <!--                                        <small id="remarkHelper" class="text-muted form-text">-->
    <!--                                           Enter Your Username-->
    <!--                                        </small>-->
    <!--                                    </div>-->
    <!--                                </span></div>-->
    <!--                                </div>-->
    <!--                                <div class="row">-->
    <!--                                    <div class="col-2">-->
    <!--                                        <button class="btn btn-lg btn-yellow font-weight-bold" type="button"-->
    <!--                                                (click)="registeration()">-->
    <!--                                            Save-->
    <!--                                        </button>-->
    <!--                                    </div>-->
    <!--                                    <div class="col-2">-->
    <!--                                        <button class="btn btn-lg btn-yellow font-weight-bold" type="button">-->
    <!--                                            Clear-->
    <!--                                        </button>-->
    <!--                                    </div>-->
    <!--                                </div>-->
    <!--                            </div>-->
    <!--                        </div>-->

    <!--                    </div>-->
    <!--                </div>-->
    <!--            </ng-container>-->
    <!--            <ng-container *ngIf="this.role==='MERCHANT'">-->
    <!--                <div class="row">-->
    <!--                    <div class="col">-->
    <!--                        <div class="card mb-12">-->
    <!--                            <div class="card-header">Register</div>-->
    <!--                            <div class="card-body">-->
    <!--                                <div class="row">-->
    <!--                                    <div class="col-2"><span-->
    <!--                                            class="card-total-row">Username: </span></div>-->
    <!--                                    <div class="col-3"><span class="card-sub-value-total">-->
    <!--                                     <div class="mb-1">-->
    <!--                                          <div class="row">-->
    <!--                                                <div class="col-4">-->
    <!--                                                     <span>{{prefix_register}}_</span>-->
    <!--                                                </div>-->
    <!--                                               <div class="col-6">-->
    <!--                                       <input type="text" class="form-control" id="usernameStaff"-->
    <!--                                              placeholder="username"-->
    <!--                                              aria-describedby="remarkHelper"-->
    <!--                                              [(ngModel)]="username" name="username">-->
    <!--                                        <small id="remarkHelper" class="text-muted form-text">-->
    <!--                                           Enter Your Username-->
    <!--                                        </small>-->
    <!--                                               </div>-->
    <!--                                          </div>-->
    <!--                                    </div>-->
    <!--                                </span>-->
    <!--                                    </div>-->

    <!--                                    <div class="col-2"><span-->
    <!--                                            class="card-total-row">Password: </span></div>-->
    <!--                                    <div class="col-3"><span class="card-sub-value-total">-->
    <!--                                     <div class="mb-1">-->
    <!--                                        <input type="text" class="form-control" id="passwordStaff"-->
    <!--                                               placeholder="password"-->
    <!--                                               aria-describedby="remarkHelper"-->
    <!--                                               [(ngModel)]="password" name="password">-->
    <!--                                        <small id="remarkHelper" class="text-muted form-text">-->
    <!--                                          Enter Your Password-->
    <!--                                        </small>-->
    <!--                                    </div>-->
    <!--                                </span></div>-->
    <!--                                </div>-->
    <!--                                <div class="row">-->
    <!--                                    <div class="col-2"><span-->
    <!--                                            class="card-total-row">Firstname : </span></div>-->
    <!--                                    <div class="col-3"><span class="card-sub-value-total">-->
    <!--                                     <div class="mb-1">-->
    <!--                                        <input type="text" class="form-control" id="firstname"-->
    <!--                                               placeholder="firstname"-->
    <!--                                               aria-describedby="remarkHelper"-->
    <!--                                               [(ngModel)]="firstname" name="email_address">-->
    <!--                                    </div>-->
    <!--                                </span></div>-->
    <!--                                    <div class="col-2"><span-->
    <!--                                            class="card-total-row">Lastname: </span></div>-->
    <!--                                    <div class="col-3"><span class="card-sub-value-total">-->
    <!--                                     <div class="mb-1">-->
    <!--                                        <input type="text" class="form-control" id="lastname"-->
    <!--                                               placeholder="lastname"-->
    <!--                                               aria-describedby="remarkHelper"-->
    <!--                                               [(ngModel)]="lastname" name="company_name">-->
    <!--                                    </div>-->
    <!--                                </span></div>-->
    <!--                                </div>-->
    <!--                                <div class="row">-->
    <!--                                    <div class="col-2"><span-->
    <!--                                            class="card-total-row">Role: </span></div>-->
    <!--                                    <div class="col-3"><span class="card-sub-value-total">-->
<!--                                          <div class="select-form">-->
<!--                                                <div class="btn-group">-->
<!--                                                    <select name="status" class="form-control form-control-sm"-->
<!--                                                            [(ngModel)]="role_register">-->
<!--                                                        <option value="STAFF">STAFF</option>-->
<!--                                                    </select>-->
<!--                                                </div>-->
<!--                                            </div>-->
    <!--                                </span></div>-->
    <!--                                    <div class="col-2"><span-->
    <!--                                            class="card-total-row">Active: </span></div>-->
    <!--                                    <div class="col-3">-->
    <!--                                        <div class="select-form">-->
    <!--                                            <div class="btn-group">-->
    <!--                                                <select name="status" class="form-control form-control-sm"-->
    <!--                                                        [(ngModel)]="isActive">-->
    <!--                                                    <option value=true>Active</option>-->
    <!--                                                    <option value=false>Inactive</option>-->
    <!--                                                </select>-->
    <!--                                            </div>-->
    <!--                                        </div>-->
    <!--                                    </div>-->
    <!--                                </div>-->
    <!--                                <div class="row">-->
    <!--                                    <div class="col-2">-->
    <!--                                        <button class="btn btn-lg btn-yellow font-weight-bold" type="button"-->
    <!--                                                (click)="registeration()">-->
    <!--                                            Save-->
    <!--                                        </button>-->
    <!--                                    </div>-->
    <!--                                </div>-->
    <!--                            </div>-->
    <!--                        </div>-->

    <!--                    </div>-->
    <!--                </div>-->
    <!--            </ng-container>-->
    <!--        </ng-template>-->
    <!--    </main>-->
</div>
</body>
