<body class="fixed-sn">
<div class="wrapper container">
    <app-header></app-header>

  <main>
    <h1>Member Management</h1>
    <div class="card-group card-deck sections-list">
      <div class="card">
        <div class="card-body">
          <a href="" [routerLink]="'/report-payment'">
            <span class="card-text">{{'sidebar.title2.1'|translate}}</span>
          </a>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <a [routerLink]="'/report-payout'">
            <span class="card-text">{{'sidebar.title2.2'|translate}}</span>
          </a>
        </div>
      </div>
    </div>
  </main>
</div>

<!-- Modal -->
<div class="modal fade" id="modal-main" role="dialog" tabindex="-1" aria-hidden="true"></div>

</body>
