import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {APP_BASE_HREF, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {HeaderComponent} from './components/header/header.component';
import {AuthService} from './service/auth.service';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import { ChartsModule } from 'ng2-charts';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {DashboardComponent} from './components/pages/dashboard/dashboard.component';
import {AccountPortalComponent} from './components/pages/member/account-portal/account-portal.component';
import {ReportPaymentComponent} from './components/pages/report/report-payment/report-payment.component';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { DatePipe } from '@angular/common';
import {A11yModule} from '@angular/cdk/a11y';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NameBet} from './components/common/pip';
import {NgxPrintModule} from 'ngx-print';
import {PersonalComponent} from './components/pages/setting/personal/personal.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {ReportPayoutComponent} from './components/pages/report/report-payout/report-payout.component';
import {BotSettingComponent} from './components/pages/setting/bot-setting/bot-setting.component';
import {RegisterComponent} from './components/pages/register/register.component';
import {MerchantSettingComponent} from './components/pages/setting/merchant-setting/merchant-setting.component';
import {ReportProfitComponent} from './components/pages/report/report-profit/report-profit.component';
import {ChangepasswordComponent} from './components/pages/change-password/changepassword.component';
import {CallbackAndKeySettingComponent} from './components/pages/setting/callback-and-key-setting/callback-and-key-setting.component';
import {PayoutComponent} from './components/pages/payout/payout.component';
import {ForecastReportProfitComponent} from './components/pages/report/forecast-report/forecast-report-profit.component';
// import {ReportPayementComponent} from './components/pages/report/report-payement/report-payement.component';

const appRoutes: Routes = [
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthService]
    },
    {
        path: 'merchant-setting',
        component: MerchantSettingComponent,
        canActivate: [AuthService]
    },
    {
        path: 'account-portal',
        component: AccountPortalComponent,
        canActivate: [AuthService]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [AuthService]
    },
    {
        path: 'bank-setting',
        component: BotSettingComponent,
        canActivate: [AuthService]
    },
    {
        path: 'report-payment',
        component: ReportPaymentComponent,
        canActivate: [AuthService]
    },
    {
        path: 'report-profit',
        component: ReportProfitComponent,
        canActivate: [AuthService]
    },
    {
        path: 'forecast-report-profit',
        component: ForecastReportProfitComponent,
        canActivate: [AuthService]
    },
    {
        path: 'change-password',
        component: ChangepasswordComponent,
        canActivate: [AuthService]
    },
    {
        path: 'report-payout',
        component: ReportPayoutComponent,
        canActivate: [AuthService]
    },
    {
        path: 'personal',
        component: PersonalComponent,
        canActivate: [AuthService]
    },
    {
        path: 'payout',
        component: PayoutComponent,
        canActivate: [AuthService]
    },
    {
        path: 'callback-and-key-setting',
        component: CallbackAndKeySettingComponent,
        canActivate: [AuthService]
    },
    {
        path: 'spinner',
        component: SpinnerComponent,
        canActivate: [AuthService]
    },
    {
        path: '**',
        component: LoginComponent,
    }
];

@NgModule({
    declarations: [
        NameBet,
        AppComponent,
        SidebarComponent,
        HeaderComponent,
        DashboardComponent,
        AccountPortalComponent,
        ReportPaymentComponent,
        PersonalComponent,
        SpinnerComponent,
        ReportPayoutComponent,
        BotSettingComponent,
        RegisterComponent,
        MerchantSettingComponent,
        ReportProfitComponent,
        ChangepasswordComponent,
        CallbackAndKeySettingComponent,
        PayoutComponent,
        ForecastReportProfitComponent
    ],
    imports: [
        NgxPrintModule,
        HttpClientModule,
        ChartsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(appRoutes),
        BrowserAnimationsModule,
        Ng2SearchPipeModule,
        MatDatepickerModule,
        MatNativeDateModule,
        A11yModule,
        ClipboardModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        PortalModule,
        ScrollingModule,
        MatFormFieldModule,
        BrowserModule,
        BrowserAnimationsModule
    ],
    providers: [{provide: APP_BASE_HREF, useValue: '/'}, {provide: LocationStrategy, useClass: HashLocationStrategy}, AuthService,
        DatePipe],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
    // schemas: [ NO_ERRORS_SCHEMA ]
})
export class AppModule {
}
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
