<header>
  <nav class="navbar navbar-light white p-0 fixed-top">
    <a class="navbar-brand logo">
      <img src="assets/img/logo.svg" alt="logo">
    </a>
    <ng-container *ngIf="isActive === false; else elseBlock">
      <app-spinner>
      </app-spinner>
    </ng-container>
    <ng-template #elseBlock>
    <div class="navbar-panel-box">
      <div class="navbar-panel d-flex justify-content-between">
        <div class="d-flex align-items-center nav-top">
          <div class="btn-group dropdown-group">
            <a href="" class="nav-link"  aria-haspopup="true"
               aria-expanded="false">
              <i class="far fa-user-circle"></i>
              {{username}}
            </a>
<!--            <div class="dropdown-menu">-->
<!--              <a class="dropdown-item" [routerLink]="'/personal'" [queryParams]="{tab:'basicInfo'}" >Basic Info</a>-->
<!--              <div class="dropdown-divider"></div>-->
<!--              <ng-container *ngIf="role != 'STAFF' && role != 'ADMIN_READ_ONLY'">-->
<!--              <a class="dropdown-item" [routerLink]="'/personal'" [queryParams]="{tab:'balance'}">Balance</a>-->
<!--              <div class="dropdown-divider"></div>-->
<!--              <a class="dropdown-item" [routerLink]="'/personal'" [queryParams]="{tab:'callback'}">Callback</a>-->
<!--              <div class="dropdown-divider"></div>-->
<!--              </ng-container>-->
<!--&lt;!&ndash;              <a class="dropdown-item" [routerLink]="'/personal'" [queryParams]="{tab:'security'}">Security</a>&ndash;&gt;-->
<!--              <div class="dropdown-divider"></div>-->
<!--&lt;!&ndash;              <a class="dropdown-item" [routerLink]="'/personal'" [queryParams]="{tab:'creditSetting'}">Credit Settings</a>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="dropdown-divider"></div>&ndash;&gt;-->
<!--              <ng-container *ngIf="role != 'STAFF' && role != 'ADMIN_READ_ONLY'">-->
<!--              <a class="dropdown-item" [routerLink]="'/personal'" [queryParams]="{tab:'productSetting'}">Product Settings</a>-->
<!--              <div class="dropdown-divider"></div>-->
<!--              </ng-container>-->
<!--              <a class="dropdown-item" (click)="onLogut()">Log Out</a>-->
<!--            </div>-->
          </div>
          <a class="nav-link nav-topbar">
            Role: {{role}}

          </a>
          &nbsp;<ng-container *ngIf="role != 'STAFF'">
          <span class="nav-topbar">
             <i class="fas">
             </i>

           Credit: {{wallet+walletCredit+walletDw}} THB

          </span>

        </ng-container>
        </div>
        <div class="d-flex align-items-center nav-top-links">
          <ul class="nav nav-pills languages" role="tablist">
            <li class="nav-item">
              <ng-container *ngIf="lang==='th'">
              <a href="" class="nav-link active" (click)="changeLang('th')" data-toggle="tab">ไทย</a>
              </ng-container>
              <ng-container *ngIf="lang==='en'">
                <a href="" class="nav-link" (click)="changeLang('th')" data-toggle="tab">ไทย</a>
              </ng-container>
            </li>
            <li class="nav-item">
              <ng-container *ngIf="lang==='en'">
              <a href="" class="nav-link active" (click)="changeLang('en')" data-toggle="tab">Eng</a>
              </ng-container>
              <ng-container *ngIf="lang==='th'">
                <a href="" class="nav-link" (click)="changeLang('en')" data-toggle="tab">Eng</a>
              </ng-container>
            </li>
          </ul>
<!--          <a href="" class="nav-link">-->
<!--            <i class="fas fa-mobile"></i>-->
<!--            Mobile Version-->
<!--          </a>-->
          <a class="dashboard collapsible-header waves-effect nav-topbar " (click)="onLogut()"><i class="sv-slim-icon fas fa-sign-out-alt fa-lg"></i>
            <b>   {{'sidebar.logout' | translate }}</b></a>
        </div>
      </div>
      <div class="promo-panel d-flex justify-content-between yellow">
        <div class="promo-text">
        </div>
<!--        <a href="" class="promo-link" data-toggle="modal" data-openmodal="modals/announcements.html">View all-->
<!--          announcements</a>-->
      </div>
    </div>
    </ng-template>
  </nav>
  <app-sidebar></app-sidebar>
</header>
