import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../service/api.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DefaultVariable} from '../../../common/default.variable';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import {AppComponent} from '../../../../app.component';
import {TranslateService} from '@ngx-translate/core';
declare let jQuery: any;

@Component({
    selector: 'app-report-total-bet',
    templateUrl: './report-payment.component.html',
    styleUrls: ['./report-payment.component.scss']
})
export class ReportPaymentComponent extends AppComponent implements OnInit {

    @ViewChild('printInvoice') myDiv: ElementRef<HTMLElement>;

    @ViewChild('TABLE', {static: false}) TABLE: ElementRef;
    title = 'Excel';

    public path: any = [];
    public password: any;
    public responseTransaction: any;

    public pageNumber: any;
    public level: any = 1;

    public maxPageLine: any = 20;
    public position: any = '';
    public refId: any = '';
    public currentPage: 1;
    public status_update: 'SUCCESS';
    public current_transaction: any;
    public status: any = '';

    public username: any = '';

    public total_fee: any = 0;
    public total_amount: any = 0;
    public service_id: any = '';
    public startDate: any;
    public endDate: any;
    public paymentIdSearch: any;
    public orderIdSearch: any;

    public startPage: any = 1;
    public endPage: any = 20;

    public currentPosition: any = localStorage.getItem('role');
    public method: string = localStorage.getItem('payType');

    public sumActualAmount: number = 0;
    public category: any;
    public sumFee: number = 0;
    public serviceCategory: any;


    public id: any = localStorage.getItem('id');




    constructor(
        public datepipe: DatePipe,
        private ref: ChangeDetectorRef,
        private apiService: ApiService,
        private _formBuilder: FormBuilder,
        private route: ActivatedRoute,
        public router: Router,
        public translate: TranslateService
    ) {
        super(translate);
    }


    async ExportTOExcel() {
        this.startDate = await this.combineDateAndTime(this.defaultStartDate,this.defaultStartTime);
        this.endDate = await this.combineDateAndTime(this.defaultEndDate,this.defaultEndTime);
        let request = {
            date_from: this.startDate,
            date_to: this.endDate,
            payment_id: this.paymentIdSearch,
            order_id: this.orderIdSearch,
            service_id: this.service_id,
            status: this.status,
            ref_id: this.refId,
            limit: 50000,
            page: 1
        };
        let exportField;
        if (this.role === 'MERCHANT') {
            exportField = await this.apiService.getPaymentTransaction(request);
        } else if (this.role === 'ADMIN' || this.role === 'ADMIN_READ_ONLY') {
            exportField = await this.apiService.adminGetPaymentTransaction(request);
        }
        for(let i=0;i<exportField.result.transactions.length;i++){
            exportField.result.transactions[i].create_date = this.datepipe.transform(exportField.result.transactions[i].create_date, 'd/M/yy, h:mm a');
            exportField.result.transactions[i].update_date = this.datepipe.transform(exportField.result.transactions[i].update_date, 'd/M/yy, h:mm a');
            console.log(exportField.result.transactions[i]);
        }

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportField.result.transactions);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        let fileName = new Date().toString() + '.xlsx';
        XLSX.writeFile(wb, fileName);
    }

    async ngOnInit(): Promise<any> {
        this.current_transaction = {
            payment_id: '',
            order_id: '',
            amount: '',
            status: '',
            customer_actual_amount: '',
            merchant_fee: '',
            status_history: [],
        };

        this.serviceCategory = await this.apiService.getAllService();

        // jQuery('#showMenuList').addClass('active');
        jQuery('.report-payment').addClass('active');


        jQuery('#showPaymentList').slideDown(300, function() {
            jQuery('#showPaymentList').show();
        });

        await this.btnTime("Today");
        this.startDate = await this.combineDateAndTime(this.defaultStartDate,this.defaultStartTime);
        this.endDate = await this.combineDateAndTime(this.defaultEndDate,this.defaultEndTime);
        let request = {
            date_from: this.startDate,
            date_to: this.endDate,
            payment_id: '',
            order_id: '',
            merchant_id: '',
            service_id: '',
            limit: this.maxSize,
            page: this.page
        };

        if (this.role === 'MERCHANT' || this.role === 'STAFF') {
            this.responseTransaction = await this.apiService.getPaymentTransaction(request);
        } else if (this.role === 'ADMIN' || this.role === 'ADMIN_READ_ONLY') {
            this.responseTransaction = await this.apiService.adminGetPaymentTransaction(request);
        }
        this.pageNumber = this.responseTransaction.result.total[0].total_record / this.maxSize;
        this.pageNumber = Math.ceil(this.pageNumber);
        this.sumFee = this.responseTransaction.result.total[0].total_fee;
        this.sumActualAmount = this.responseTransaction.result.total[0].customer_actual_amount;
        jQuery('.defult-active').addClass('active');
    }


    counter(i: number) {
        return new Array(i);
    }

    public async setDefault() {

    }

    async setNewPageSet(pageSet) {
        if (pageSet < 0) {
            pageSet++;
            this.endPage = parseInt(this.endPage) + parseInt(pageSet);
            if ((this.startPage - pageSet) < 1) {
                this.startPage = 1;
            } else {
                this.startPage = parseInt(this.startPage) + parseInt(pageSet);
            }
        } else {
            pageSet--;
            this.startPage = parseInt(this.endPage);
            if (this.endPage + parseInt(pageSet) > parseInt(this.pageNumber) ) {
                this.endPage = this.pageNumber;
            } else {
                this.endPage = parseInt(this.endPage) + parseInt(pageSet);
            }

        }
    }

    async searchPayment(page) {
        this.isActive = false;
        this.startDate = await this.combineDateAndTime(this.defaultStartDate,this.defaultStartTime);
        this.endDate = await this.combineDateAndTime(this.defaultEndDate,this.defaultEndTime);

        this.currentPage = page;
        let request = {
            date_from: this.startDate,
            date_to: this.endDate,
            payment_id: this.paymentIdSearch,
            order_id: this.orderIdSearch,
            merchant_id: this.username,
            service_id: this.service_id,
            status: this.status,
            ref_id: this.refId,
            limit: this.maxSize,
            page: this.currentPage
        };

        if (this.role === 'MERCHANT' || this.role === 'STAFF' ) {
            this.responseTransaction = await this.apiService.getPaymentTransaction(request);
        } else if (this.role === 'ADMIN' || this.role === 'ADMIN_READ_ONLY') {
            this.responseTransaction = await this.apiService.adminGetPaymentTransaction(request);
        }
        this.isActive = true;
        this.pageNumber = this.responseTransaction.result.total[0].total_record / this.maxSize;
        this.pageNumber = Math.ceil(this.pageNumber);


        this.sumFee = this.responseTransaction.result.total[0].total_fee;
        this.sumActualAmount = this.responseTransaction.result.total[0].customer_actual_amount;

    }

    async selectFirstPage() {
        this.startPage = 1;
        this.endPage = this.maxPageLine;
        this.searchPayment(this.startPage);
    }

    async selectLastPage() {
        if(parseInt(this.pageNumber) - this.maxPageLine > 0) {
            this.startPage = parseInt(this.pageNumber) - this.maxPageLine;
            this.endPage = parseInt(this.pageNumber);
        }
        this.searchPayment(this.pageNumber);
    }


    async modal(payment_id) {
        for (let i = 0; i < this.responseTransaction.result.transactions.length; i++) {
            if (payment_id == this.responseTransaction.result.transactions[i].payment_id) {
                this.current_transaction = this.responseTransaction.result.transactions[i];
                this.status_update = this.current_transaction.status;
                console.log(this.current_transaction);
            }
        }
        this.ref.detectChanges();
    }


    async callback() {
        let request = {
            order_id: this.current_transaction.order_id,
            username:  this.current_transaction.username,
            prefix:  this.current_transaction.prefix
        };

        if (this.role === 'MERCHANT' || this.role === 'STAFF' ) {
            let response = await this.apiService.retryCallback(request);
        } else if (this.role === 'ADMIN' || this.role === 'ADMIN_READ_ONLY') {
            let response = await this.apiService.retryAdminCallback(request);
        }

        this.searchPayment(this.currentPage);

        this.ref.detectChanges();
    }

    async updatestatus() {
        let request = {
            order_id: this.current_transaction.order_id,
            status: this.status_update,
            username: this.current_transaction.username,
            amount: this.current_transaction.customer_actual_amount,
            service_id: this.current_transaction.service_id
        };

        if (this.role === 'ADMIN' || this.role === 'ADMIN_READ_ONLY') {
            let response = await this.apiService.adminUpdatePaymentTransaction(request);
            alert(response.message);
        }
        this.searchPayment(this.currentPage);
    }

    async export() {


    }

    async btnTimePN(param) {
        if (param == 'Next') {
            this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
            this.defaultEndDate = new Date();
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'Previous') {
            this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
            this.defaultEndDate = new Date();
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
            if (this.stage == 'Today') {
                this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()+1, 0, 0, 0);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()+1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Yesterday') {

                this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() , 0, 0, 0);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'This week') {
                this.defaultStartDate = await this.getNextMonday(this.today);
                this.defaultEndDate = await this.getNextSunday(this.today);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Last week') {
                this.stage = 'This week';
                this.defaultStartDate = await this.getMonday(this.today);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'This month') {
                this.defaultStartDate = await this.getStartNextMonth(this.today);
                let current = await this.getStartNextMonth(this.today);
                this.defaultEndDate = new Date(current.getFullYear(), current.getMonth()+1, current.getDate() - 1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Last month') {
                this.stage = 'This month';
                this.defaultStartDate = await this.getStartMonth(this.today);
                this.defaultEndDate = new Date();
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            }

        } else if (param == 'Previous') {
            if (this.stage == 'Today') {
                this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()-1, 0, 0, 0);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()-1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Yesterday') {

                this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 2, 0, 0, 0);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 2, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'This week') {
                this.defaultStartDate = await this.getLastMonday(this.today);
                this.defaultEndDate = await this.getLastSunday(this.today);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Last week') {

                this.defaultStartDate = await this.getLastMonday(await this.getLastMonday(this.today));
                this.defaultEndDate = await this.getLastSunday(await this.getLastSunday(this.today));
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'This month') {
                this.defaultStartDate = await this.getStartLastMonth(this.today);
                let current = await this.getStartMonth(this.today);
                this.defaultEndDate = new Date(current.getFullYear(), current.getMonth(), current.getDate() - 1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Last month') {
                this.defaultStartDate = await this.getStartLastMonth(await this.getStartLastMonth(this.today));
                let current = await this.getStartLastMonth(this.today);
                this.defaultEndDate = new Date(current.getFullYear(), current.getMonth(), current.getDate() - 1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            }
        }
        this.searchPayment(this.currentPage);
    }

    async btnTime(param) {
        if (param == 'Today') {
            this.stage = 'Today';
            this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
            this.defaultEndDate = new Date();
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'Yesterday') {
            this.stage = 'Yesterday';
            this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 1, 0, 0, 0);
            this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 1, 0, 0, 0);
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'This week') {
            this.stage = 'This week';
            this.defaultStartDate = await this.getMonday(this.today);
            this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'Last week') {
            this.stage = 'Last week';
            this.defaultStartDate = await this.getLastMonday(this.today);
            this.defaultEndDate = await this.getLastSunday(this.today);
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'This month') {
            this.stage = 'This month';
            this.defaultStartDate = await this.getStartMonth(this.today);
            this.defaultEndDate = new Date();
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'Last month') {
            this.defaultStartDate = await this.getLastMonth(this.today);
            this.defaultEndDate = await this.getLastMonthEnd(this.today);
            this.stage = 'Last month';
            this.defaultStartDate = await this.getStartLastMonth(this.today);
            let current = await this.getStartMonth(this.today);
            this.defaultEndDate = new Date(current.getFullYear(), current.getMonth(), current.getDate() - 1, 0, 0, 0);
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        }
        this.searchPayment(this.currentPage);
    }



}
