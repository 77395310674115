import {Component, OnInit} from '@angular/core';
import {LoginRequestModel} from '../login/login.component';
import {Router} from '@angular/router';
import {ApiService} from '../../service/api.service';
import {AppComponent} from '../../app.component';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
})
export class HeaderComponent extends AppComponent implements OnInit {

    position = localStorage.getItem('group');
    responseProfile: any;
    username: any;
    userProfile: any;
    role: any;
    wallet: any;
    walletCredit: any;
    walletDw: any;
    responseAnnouncement: any;
    lang = 'en';

    constructor(public apiService: ApiService,
                public router: Router,
                public translate: TranslateService) {
        super(translate);
    }

    async ngOnInit() {
        if (localStorage.getItem('lang') === null) {
            localStorage.setItem('lang', 'en');
        } else {
            this.lang = localStorage.getItem('lang');
        }

        this.responseProfile = {};
        this.userProfile = await this.apiService.getUserProfile();
            // await this.apiService.getUserProfile(localStorage.getItem('userId'));
        // this.responseAnnouncement = await this.apiService.getAnnounceMent();
        this.username = localStorage.getItem("username");
        this.role = localStorage.getItem("role");
        this.wallet =   this.userProfile.result.wallet;
        this.walletCredit = this.userProfile.result.walletCredit;
        this.walletDw =  this.userProfile.result.walletDw;
        this.isActive = true;
    }

    async onLogut() {
        localStorage.clear();
        this.router.navigate(['pages/login']);
    }

    // async changeLang(lang) {
    //     localStorage.setItem('lang', lang);
    //     this.router.navigate(['dashboard']);
    // }

}
