import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../service/api.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DefaultVariable} from '../../../common/default.variable';
import * as XLSX from 'xlsx';
import {DatePipe} from '@angular/common';
import {AppComponent} from '../../../../app.component';
import {TranslateService} from '@ngx-translate/core';

declare let jQuery: any;

@Component({
    selector: 'app-report-total-bet',
    templateUrl: './report-profit.component.html',
    styleUrls: ['./report-profit.component.scss']
})
export class ReportProfitComponent extends AppComponent implements OnInit {

    @ViewChild('printInvoice') myDiv: ElementRef<HTMLElement>;

    @ViewChild('TABLE', {static: false}) TABLE: ElementRef;
    title = 'Excel';


    public path: any = [];
    public password: any;
    public responseTransaction: any;

    public pageNumber: any;
    public level: any = 1;

    public maxPageLine: any = 20;
    public position: any = '';
    public currentPage: 1;
    public status_update: 'SUCCESS';
    public current_transaction: any;
    public status: any = '';

    public username: any;

    public total_fee: any = 0;
    public total_amount: any = 0;
    public service_id: any = '';
    public startDate: any;
    public startTime: any;
    public endDate: any;
    public endTime: any;
    sumDeposit: any = 0;
    sumWithdraw: any = 0;
    sumWallet: any = 0;
    sumWithdrawIncFee: any = 0;
    sumCost: any = 0;
    sumFee: any = 0;

    sumProfit: any = 0;


    public startPage: any = 1;
    public endPage: any = 20;

    public currentPosition: any = localStorage.getItem('role');
    public method: string = localStorage.getItem('payType');

    public category: any;
    public serviceCategory: any;


    public id: any = localStorage.getItem('id');


    constructor(
        public datepipe: DatePipe,
        private ref: ChangeDetectorRef,
        private apiService: ApiService,
        private _formBuilder: FormBuilder,
        private route: ActivatedRoute,
        public router: Router,
        public translate: TranslateService
    ) {
        super(translate);
    }


    async ngOnInit(): Promise<any> {
        this.current_transaction = {
            payment_id: '',
            order_id: '',
            amount: '',
            status: '',
            customer_actual_amount: '',
            merchant_fee: '',
            status_history: [],
        };
        await this.btnTime('Today');
        this.serviceCategory = await this.apiService.getAllService();
        await this.searchPayment(1);
        jQuery('.defult-active').addClass('active');

    }


    counter(i: number) {
        return new Array(i);
    }

    public async setDefault() {

    }

    async setNewPageSet(pageSet) {
        if (pageSet < 0) {
            pageSet++;
            this.endPage = parseInt(this.endPage) + parseInt(pageSet);
            if ((this.startPage - pageSet) < 1) {
                this.startPage = 1;
            } else {
                this.startPage = parseInt(this.startPage) + parseInt(pageSet);
            }
        } else {
            pageSet--;
            this.startPage = parseInt(this.endPage);
            if (this.endPage + parseInt(pageSet) > parseInt(this.pageNumber)) {
                this.endPage = this.pageNumber;
            } else {
                this.endPage = parseInt(this.endPage) + parseInt(pageSet);
            }

        }
    }

    async searchPayment(page) {
        this.isActive = false;
        this.currentPage = page;
        this.startDate = await this.combineDateAndTime(this.defaultStartDate, this.defaultStartTime);
        this.endDate = await this.combineDateAndTime(this.defaultEndDate, this.defaultEndTime);
        this.sumDeposit = 0;
        this.sumProfit = 0;
        this.sumWallet = 0;
        this.sumWithdraw = 0;
        this.sumCost = 0;
        this.sumFee = 0;
        this.sumWithdrawIncFee = 0;
        let request = {
            start_date: this.startDate,
            end_date: this.endDate,
            username: this.username,
            service_id: this.service_id,
            page: this.currentPage,
            limit: this.maxSize
        };

        this.responseTransaction = await this.apiService.getSummaryProfit(request);

        for (let i = 0; i < this.responseTransaction.result.data.length; i++) {
            this.sumDeposit += this.responseTransaction.result.data[i].deposit;
            this.sumProfit += this.responseTransaction.result.data[i].profit;
            this.sumWithdraw += this.responseTransaction.result.data[i].withdraw;
            this.sumWallet += this.responseTransaction.result.data[i].wallet_amount;
            this.sumWithdrawIncFee += this.responseTransaction.result.data[i].withdraw_inc_fee;
            this.sumCost += this.responseTransaction.result.data[i].cost;
            this.sumFee += this.responseTransaction.result.data[i].fee;
        }

        await this.sortByProfit();
        this.isActive = true;
        this.pageNumber = this.responseTransaction.result.total[0].total_record / this.maxSize;
        this.pageNumber = Math.ceil(this.pageNumber);
    }

    async sortByProfit() {
        this.responseTransaction.result.data.sort(function(a, b) {
            return b.profit - a.profit;
        });

    }


    async selectFirstPage() {
        this.startPage = 1;
        this.endPage = this.maxPageLine;
        this.searchPayment(this.startPage);
    }

    async selectLastPage() {
        this.startPage = parseInt(this.pageNumber) - this.maxPageLine;
        this.endPage = parseInt(this.pageNumber);
        this.searchPayment(this.pageNumber);
    }


    async modal(payment_id) {
        for (let i = 0; i < this.responseTransaction.result.transactions.length; i++) {
            if (payment_id == this.responseTransaction.result.transactions[i].payment_id) {
                this.current_transaction = this.responseTransaction.result.transactions[i];
                this.status_update = this.current_transaction.status;
                console.log(this.current_transaction);
            }
        }
        this.ref.detectChanges();
    }

    async ExportTOExcel() {
        let exportField;

        let request = {
            start_date: this.startDate,
            end_date: this.endDate,
            username: this.username,
            service_id: this.service_id,
            page: this.currentPage,
            limit: 50000
        };

        exportField = await this.apiService.getSummaryProfit(request);

        const ws: XLSX.WorkSheet = await XLSX.utils.json_to_sheet(exportField.result.data);
        const wb: XLSX.WorkBook = await XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        let fileName = new Date().toString() + '.xlsx';
        XLSX.writeFile(wb, fileName);
    }

    async btnTimePN(param) {
        if (param == 'Next') {
            this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
            this.defaultEndDate = new Date();
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'Previous') {
            this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
            this.defaultEndDate = new Date();
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
            if (this.stage == 'Today') {
                this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()+1, 0, 0, 0);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()+1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Yesterday') {
                this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() , 0, 0, 0);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'This week') {
                this.defaultStartDate = await this.getNextMonday(this.today);
                this.defaultEndDate = await this.getNextSunday(this.today);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Last week') {
                this.stage = 'This week';
                this.defaultStartDate = await this.getMonday(this.today);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'This month') {
                this.defaultStartDate = await this.getStartNextMonth(this.today);
                let current = await this.getStartNextMonth(this.today);
                this.defaultEndDate = new Date(current.getFullYear(), current.getMonth()+1, current.getDate() - 1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Last month') {
                this.stage = 'This month';
                this.defaultStartDate = await this.getStartMonth(this.today);
                this.defaultEndDate = new Date();
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            }

        } else if (param == 'Previous') {
            if (this.stage == 'Today') {
                this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()-1, 0, 0, 0);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()-1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Yesterday') {

                this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 2, 0, 0, 0);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 2, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'This week') {
                this.defaultStartDate = await this.getLastMonday(this.today);
                this.defaultEndDate = await this.getLastSunday(this.today);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Last week') {

                this.defaultStartDate = await this.getLastMonday(await this.getLastMonday(this.today));
                this.defaultEndDate = await this.getLastSunday(await this.getLastSunday(this.today));
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'This month') {
                this.defaultStartDate = await this.getStartLastMonth(this.today);
                let current = await this.getStartMonth(this.today);
                this.defaultEndDate = new Date(current.getFullYear(), current.getMonth(), current.getDate() - 1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Last month') {
                this.defaultStartDate = await this.getStartLastMonth(await this.getStartLastMonth(this.today));
                let current = await this.getStartLastMonth(this.today);
                this.defaultEndDate = new Date(current.getFullYear(), current.getMonth(), current.getDate() - 1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            }
        }
        this.searchPayment(this.currentPage);
    }

    async btnTime(param) {
        if (param == 'Today') {
            this.stage = 'Today';
            this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
            this.defaultEndDate = new Date();
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'Yesterday') {
            this.stage = 'Yesterday';
            this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 1, 0, 0, 0);
            this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 1, 0, 0, 0);
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'This week') {
            this.stage = 'This week';
            this.defaultStartDate = await this.getMonday(this.today);
            this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'Last week') {
            this.stage = 'Last week';
            this.defaultStartDate = await this.getLastMonday(this.today);
            this.defaultEndDate = await this.getLastSunday(this.today);
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'This month') {
            this.stage = 'This month';
            this.defaultStartDate = await this.getStartMonth(this.today);
            this.defaultEndDate = new Date();
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'Last month') {
            this.defaultStartDate = await this.getLastMonth(this.today);
            this.defaultEndDate = await this.getLastMonthEnd(this.today);
            this.stage = 'Last month';
            this.defaultStartDate = await this.getStartLastMonth(this.today);
            let current = await this.getStartMonth(this.today);
            this.defaultEndDate = new Date(current.getFullYear(), current.getMonth(), current.getDate() - 1, 0, 0, 0);
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        }
        this.searchPayment(this.currentPage);
    }

}
