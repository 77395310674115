import {Injectable} from '@angular/core';
// import {Observable} from 'rxjs/Observable';
import {
    HttpClient, HttpHeaders
} from '@angular/common/http';
import {Router} from '@angular/router';
import {AddMemberRequest} from '../response/common/add-member-request';

declare let jQuery: any;

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private headers = new HttpHeaders({'Content-Type': 'application/json'});

    private baseUrl = `https://ambpayment.cola168.com/`; //pro
    // private baseUrl = `http://157.245.49.129:8001/`; //pro
    // private baseUrl = `http://localhost:8700/`; //pro
    
    private loginUrl = `${this.baseUrl}ambpayment/common/login/`;
    private getUserProfileUrl = `${this.baseUrl}ambpayment/common/get-user-info`;
    private changePasswordUrl = `${this.baseUrl}ambpayment/frontapi/user/change-password`;
    private adminChangePasswordUrl = `${this.baseUrl}ambpayment/common/change-password`;
    private generateKeyUrl = `${this.baseUrl}ambpayment/frontapi/user/generate-key`;
    private payoutUrl = `${this.baseUrl}ambpayment/frontapi/user/payout`;
    private getBalanceUrl = `${this.baseUrl}ambpayment/frontapi/user/v2/get-balance`;
    private getPaymentTransactionUrl = `${this.baseUrl}ambpayment/frontapi/payment/search-payment`;
    private retryCallbackUrl = `${this.baseUrl}ambpayment/frontapi/user/recall-callback`;
    private retryCallbackAdminUrl = `${this.baseUrl}ambpayment/merchant/recall-callback`;
    private adminGetPaymentTransactionUrl = `${this.baseUrl}ambpayment/backoffice/search-payment`;
    private adminUpdatePaymentTransactionUrl = `${this.baseUrl}ambpayment/backoffice/update-status`;
    private searchMerchantTransactionUrl = `${this.baseUrl}ambpayment/backoffice/get-user-info`;
    private searchSubAccountUrl = `${this.baseUrl}ambpayment/frontapi/user/list-sub-account`;
    private adminGetPayoutTransactionUrl = `${this.baseUrl}ambpayment/report/search-payout-payment`;
    private adminGetProfitReportUrl = `${this.baseUrl}ambpayment/report/summary-profit`;
    private adminGetForecastProfitReportUrl = `${this.baseUrl}ambpayment/report/get-forecast-summary-report`;
    private getPayoutTransactionUrl = `${this.baseUrl}ambpayment/frontapi/payment/search-payout-payment`;
    private updateStatusUrl = `${this.baseUrl}ambpayment/backoffice/update-payout-status`;
    private saveCallbackUrl = `${this.baseUrl}ambpayment/frontapi/user/update-merchant-profile`;
    private getBankListUrl = `${this.baseUrl}ambpayment/backoffice/bank-config`;
    private startBotUrl = `${this.baseUrl}ambpayment/backoffice/start`;
    private registerUrl = `${this.baseUrl}ambpayment/common/register`;
    private addSubAccountUrl = `${this.baseUrl}ambpayment/frontapi/user/add-sub-account`;
    private updateSubAccountUrl = `${this.baseUrl}ambpayment/frontapi/user/update-sub-account`;
    private stopBotUrl = `${this.baseUrl}ambpayment/backoffice/stop`;
    private updateBotUrl = `${this.baseUrl}ambpayment/backoffice/update-bank-config`;
    private addBotUrl = `${this.baseUrl}ambpayment/backoffice/bank-config`;
    private listMerchantUrl = `${this.baseUrl}ambpayment/merchant/list-merchant`;
// /
    // https://ambpayment.cola168.com/ambpayment/frontapi/user/update-merchant-profile
    // private getPayoutTransactionUrl = `${this.baseUrl}/ambpayment/report/search-payout-payment`;


    private getServiceCategoryUrl = `${this.baseUrl}ambpayment/merchant/get-category`;
    private getAllServiceUrl = `${this.baseUrl}ambpayment/merchant/get-all-service`;

    private getSummaryTransactionUrl = `${this.baseUrl}ambpayment/frontapi/payment/get-summary-budget`;
    private getSummaryPayoutUrl = `${this.baseUrl}ambpayment/frontapi/payment/get-summary-withdraw`;

    private getBarchartUrl = `${this.baseUrl}ambpayment/report/daily-dashboard-x-time-y-amount`;
    private getBarchartFrequencyUrl = `${this.baseUrl}ambpayment/report/daily-dashboard-x-time-y-frequency`;


    private getBarcharFronttUrl = `${this.baseUrl}ambpayment/frontapi/payment/daily-dashboard-x-time-y-amount`;
    private getBarchartFrequencyFrontUrl = `${this.baseUrl}ambpayment/frontapi/payment/daily-dashboard-x-time-y-frequency`;

    // private getPayoutTransactionUrl = `${this.baseUrl}/ambpayment/report/search-payout-payment`;

    private getPiechartUrl = `${this.baseUrl}ambpayment/report/daily-dashboard-bank`;
    private getPiechartFontUrl = `${this.baseUrl}ambpayment/frontapi/payment/daily-dashboard-bank`;

    constructor(private http: HttpClient, private router: Router) {
    }

    public async login(credentail) {
        let response = await this.http.post<any>(this.loginUrl, credentail, {
            headers: this.headers
        }).toPromise();
        console.log('Login : ');
        console.log(response);
        if (response.code !== 0) {
            alert(response.message);
        } else {
            localStorage.setItem('role', response.result.profile.role);
            localStorage.setItem('wallet', response.result.profile.wallet);
            localStorage.setItem('wallet_dw', response.result.profile.wallet_dw);
            localStorage.setItem('wallet_credit', response.result.profile.wallet_credit);
            localStorage.setItem('username', response.result.profile.username);
            localStorage.setItem('services', response.result.profile.services);
            localStorage.setItem('token', response.result.token);
            localStorage.setItem('prefix', response.result.profile.prefix);

            this.router.navigate(['/dashboard']);
        }
    }


    public async getSummaryTransaction(request) {
        let response = await this.http.post<any>(this.getSummaryTransactionUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get Agent Profile Id');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }
        return Promise.resolve(response);
    }

    public async getBarchart(request) {
        let response = await this.http.post<any>(this.getBarchartUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get Agent Profile Id');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }
        return Promise.resolve(response);
    }

    public async getPiechart(request) {
        let response = await this.http.post<any>(this.getPiechartUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get Agent Profile Id');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }
        return Promise.resolve(response);
    }

    public async getPiechartFont(request) {
        let response = await this.http.post<any>(this.getPiechartFontUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get Agent Profile Id');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }
        return Promise.resolve(response);
    }

    public async getBarchartFront(request) {
        let response = await this.http.post<any>(this.getBarcharFronttUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get Agent Profile Id');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }
        return Promise.resolve(response);
    }

    public async getBarchartFrequency(request) {
        let response = await this.http.post<any>(this.getBarchartFrequencyUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get Agent Profile Id');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }
        return Promise.resolve(response);
    }

    public async getBarchartFrequencyFront(request) {
        let response = await this.http.post<any>(this.getBarchartFrequencyFrontUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get Agent Profile Id');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }
        return Promise.resolve(response);
    }

    public async listMerchant() {
        let response = await this.http.get<any>(this.listMerchantUrl, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get Agent Profile Id');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }
        return Promise.resolve(response);
    }

    public async getSummaryProfit(request) {
        let response = await this.http.post<any>(this.adminGetProfitReportUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get Agent Profile Id');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }
        return Promise.resolve(response);
    }

    public async getSummaryForecastProfit() {
        let response = await this.http.get<any>(this.adminGetForecastProfitReportUrl, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get Agent Profile Id');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }
        return Promise.resolve(response);
    }

    public async getSummaryPayout(request) {
        let response = await this.http.post<any>(this.getSummaryPayoutUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get Agent Profile Id');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }
        return Promise.resolve(response);
    }


    public async getServiceCategory() {
        let response = await this.http.get<any>(this.getServiceCategoryUrl, {
            headers: this.headers
        }).toPromise();
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }
        return Promise.resolve(response);

    }

    public async getAllService() {
        let response = await this.http.get<any>(this.getAllServiceUrl, {
            headers: this.headers
        }).toPromise();
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }
        return Promise.resolve(response);

    }

    public async getUserProfile() {
        let response = await this.http.get<any>(this.getUserProfileUrl, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        } else if (response.code !== 0) {
            alert(response.message);
        }
        return Promise.resolve(response);
    }

    public async getBankList() {
        let response = await this.http.get<any>(this.getBankListUrl, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        } else if (response.code !== 0) {
            alert(response.message);
        }
        return Promise.resolve(response);
    }

    public async register(request) {
        let response = await this.http.post<any>(this.registerUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if (response.code !== 0) {
            alert(response.message);
        }
        return Promise.resolve(response);
    }

    public async addSubAccount(request) {
        let response = await this.http.post<any>(this.addSubAccountUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if (response.code !== 0) {
            alert(response.message);
        }
        return Promise.resolve(response);
    }

    public async updateSubAccount(request) {
        let response = await this.http.post<any>(this.updateSubAccountUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if (response.code !== 0) {
            alert(response.message);
        }
        return Promise.resolve(response);
    }

    public async startBot(request) {
        let response = await this.http.post<any>(this.startBotUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        } else if (response.code !== 0) {
            alert(response.message);
        }
        return Promise.resolve(response);
    }

    public async stopBot(request) {
        let response = await this.http.post<any>(this.stopBotUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }
        if (response.code !== 0) {
            alert(response.message);
        }
        return Promise.resolve(response);
    }


    public async updateBot(request) {
        let response = await this.http.post<any>(this.updateBotUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        } else if (response.code !== 0) {
            alert(response.message);
        }
        return Promise.resolve(response);
    }


    public async addBot(request) {
        let response = await this.http.post<any>(this.addBotUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }
        if (response.code !== 0) {
            alert(response.message);
        }
        return Promise.resolve(response);
    }

    public async getPaymentTransaction(request) {
        let response = await this.http.post<any>(this.getPaymentTransactionUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        } else if (response.code !== 0) {
            alert(response.message);
        }
        return Promise.resolve(response);
    }

    public async retryCallback(request) {
        let response = await this.http.post<any>(this.retryCallbackUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        } else if (response.code !== 0) {
            alert(response.message);
        } else {
            alert(response.message);
        }
        return Promise.resolve(response);
    }

    public async retryAdminCallback(request) {
        let response = await this.http.post<any>(this.retryCallbackAdminUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if (response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        } else if (response.code !== 0) {
            alert(response.message);
        } else {
            alert(response.message);
        }
        return Promise.resolve(response);
    }

    public async adminGetPaymentTransaction(request) {
        let response = await this.http.post<any>(this.adminGetPaymentTransactionUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if(response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }else if (response.code !== 0) {
            alert(response.message);
        }
        return Promise.resolve(response);
    }

    public async adminUpdatePaymentTransaction(request) {
        let response = await this.http.post<any>(this.adminUpdatePaymentTransactionUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if(response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }else if (response.code !== 0) {
            alert(response.message);
        }
        return Promise.resolve(response);
    }

    public async searchMerchant(request) {
        let response = await this.http.post<any>(this.searchMerchantTransactionUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if(response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        } else if (response.code !== 0) {
            alert(response.message);
        }
        return Promise.resolve(response);
    }

    public async searchSubAccount(request) {
        let response = await this.http.post<any>(this.searchSubAccountUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if(response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        } else if (response.code !== 0) {
            alert(response.message);
        }
        return Promise.resolve(response);
    }

    public async adminGetPayoutTransaction(request) {
        let response = await this.http.post<any>(this.adminGetPayoutTransactionUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if(response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }else if (response.code !== 0) {
            alert(response.message);
        }
        return Promise.resolve(response);
    }

    public async saveCallback(request) {
        let response = await this.http.post<any>(this.saveCallbackUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if(response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }else if (response.code !== 0) {
            alert(response.message);
        }
        return Promise.resolve(response);
    }

    public async getPayoutTransaction(request) {
        let response = await this.http.post<any>(this.getPayoutTransactionUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if(response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }else if (response.code !== 0) {
            alert(response.message);
        }
        return Promise.resolve(response);
    }

    public async updateStatus(request) {
        let response = await this.http.post<any>(this.updateStatusUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if(response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }else if (response.code !== 0) {
            alert(response.message);
        }
        return Promise.resolve(response);
    }

    public async changePassword(request) {
        let response = await this.http.post<any>(this.changePasswordUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        alert(response.message);
        return Promise.resolve(response);
    }

    public async adminChangePassword(request) {
        let response = await this.http.post<any>(this.adminChangePasswordUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        alert(response.message);
        return Promise.resolve(response);
    }

    public async generateSecretKey() {
        let response = await this.http.get<any>(this.generateKeyUrl, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if(response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }
        alert(response.message);
        return Promise.resolve(response);
    }


    public async payout(request) {
        let response = await this.http.post<any>(this.payoutUrl, request, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if(response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }
        alert(response.message);
        return Promise.resolve(response);
    }


    public async getBalance() {
        let response = await this.http.get<any>(this.getBalanceUrl, {
            headers: await this.getTokenHeader()
        }).toPromise();
        console.log('Get User Profile');
        console.log(response);
        if(response.code === 9003) {
            alert(response.message);
            this.router.navigate(['/login']);
        }
        alert(response.message);
        return Promise.resolve(response);
    }

    private async getTokenHeader() {
        const token = localStorage.getItem('token');
        let authHeader = new HttpHeaders({'Authorization': `Bearer ${token}`});
        return Promise.resolve(authHeader);
    }

    // getBetListErrorUrl
}
