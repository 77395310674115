import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from 'angular-bootstrap-md';
import {HeaderComponent} from '../header/header.component';
import {ApiService} from '../../service/api.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

declare let jQuery: any;


@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {

    @ViewChild('childModal') childModal: ModalDirective;
    lang = localStorage.getItem('lang');
    public role = localStorage.getItem('role');

    constructor(public router: Router) {
    }

    ngOnInit(): any {

    }

    showMemberMenu() {
        if (jQuery('#showMenuList').attr('style').search('none') != -1) {
            jQuery('#showMenuList').slideDown(300, function() {
                jQuery('#showMenuList').show();
            });
        } else {
            jQuery('#showMenuList').slideUp(300, function() {
                jQuery('#showMenuList').hide();
            });

        }
    }

    showManagementMenu() {
        if (jQuery('#showManagementList').attr('style').search('none') != -1) {
            jQuery('#showManagementList').slideDown(300, function() {
                jQuery('#showManagementList').show();
            });
        } else {
            jQuery('#showManagementList').slideUp(300, function() {
                jQuery('#showManagementList').hide();
            });

        }
    }

    showPaymentMenu() {
        if (jQuery('#paymentList').attr('style').search('none') != -1) {
            jQuery('#paymentList').slideDown(300, function() {
                jQuery('#paymentList').show();
            });
        } else {
            jQuery('#paymentList').slideUp(300, function() {
                jQuery('#paymentList').hide();
            });

        }

    }

    showReportMenu() {
        if (jQuery('#reportList').attr('style').search('none') != -1) {
            jQuery('#reportList').slideDown(300, function() {
                jQuery('#reportList').show();
            });
        } else {
            jQuery('#reportList').slideUp(300, function() {
                jQuery('#reportList').hide();
            });

        }

    }

    showSettingMenu() {
        if (jQuery('#settingList').attr('style').search('none') != -1) {
            // alert("Here");
            jQuery('#settingList').slideDown(300, function() {
                jQuery('#settingList').show();
            });
        } else {
            // alert("Here1");
            jQuery('#settingList').slideUp(300, function() {
                jQuery('#settingList').hide();
            });

        }

    }

    async onLogut() {
        localStorage.clear();
        this.router.navigate(['pages/login']);
    }

}
