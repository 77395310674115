import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../service/api.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  public password: any;
  fieldTextType: boolean;

  request: LoginRequestModel;

  constructor(
    private apiService: ApiService,
    private _formBuilder: FormBuilder
  ) {
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  async ngOnInit(): Promise<any> {
    this.loginForm = this._formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  async onLogin(formGroup) {
    console.log(formGroup);
    this.request = new LoginRequestModel(formGroup.username, formGroup.password);
    await this.apiService.login(this.request);
  }

}

export class LoginRequestModel {
  public username: any;
  public password: any;

  constructor(username, password) {
    this.password = password;
    this.username = username;
  }
}


