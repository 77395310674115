import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../service/api.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DefaultVariable} from '../../../common/default.variable';
import {AppComponent} from '../../../../app.component';
import {TranslateService} from '@ngx-translate/core';

declare let jQuery: any;

@Component({
    selector: 'app-report-total-bet',
    templateUrl: './merchant-setting.component.html',
    styleUrls: ['./merchant-setting.component.scss']
})
export class MerchantSettingComponent extends AppComponent implements OnInit {

    @ViewChild('printInvoice') myDiv: ElementRef<HTMLElement>;

    loginForm: FormGroup;
    mySubscription: any;
    public path: any = [];

    public password: any;

    public responseMerchant: any;

    public usernameRegister: any;
    public passwordRegister: any;


    public prefix: any;
    public prefixRegister: any;
    public companyName: any;
    public emailAddress: any;
    public roleRegister: any;
    public accountNumber: any;
    public bankName: any;
    public isActive: boolean = false;
    public firstname: any;
    public lastname: any;
    public roleRegisterUpdate: any;

    public firstnameUpdate: any;
    public lastnameUpdate: any;
    public usernameUpdate: any;
    public passwordUpdate: any;
    public isActiveUpdate: boolean;


    public pageNumber: any;
    public level: any = 1;
    public position: any = '';
    public refId: any = '';
    public currentPage: 1;
    public username: '';
    public current_transaction: any;
    public status: any = '';

    public total_fee: any = 0;
    public total_amount: any = 0;
    public totalWallet: any = 0;
    public totalWalletDw: any = 0;
    public totalWalletCredit: any = 0;
    public isUpdate: any = false;

    public currentPosition: any = localStorage.getItem('role');
    public method: string = localStorage.getItem('payType');

    public sumActualAmount: number = 0;
    public category: any;
    public sumFee: number = 0;
    public serviceCategory: any;


    public id: any = localStorage.getItem('id');


    constructor(
        private ref: ChangeDetectorRef,
        private apiService: ApiService,
        private _formBuilder: FormBuilder,
        private route: ActivatedRoute,
        public router: Router,
        public translate: TranslateService
    ) {
        super(translate);
    }


    async ngOnInit(): Promise<any> {
        this.current_transaction = {
            payment_id: '',
            order_id: '',
            amount: '',
            status: '',
            customer_actual_amount: '',
            merchant_fee: '',
            status_history: [],
        };

        // jQuery('#showMenuList').addClass('active');
        jQuery('.report-payment').addClass('active');

        jQuery('#merchant-setting').slideDown(300, function() {
            jQuery('#merchant-setting').show();
        });

        this.prefixRegister = localStorage.getItem('prefix');
        if (this.role == 'MERCHANT') {
            this.roleRegister = 'STAFF';
        }
        this.isActive = true;
        let request = {
            username: '',
            is_active: true,
            company_name: '',
            bank_name: '',
            role: '',
            secret_key: '',
            page: this.page,
            limit: this.maxSize
        };
        if (this.role === 'ADMIN') {
            this.responseMerchant = await this.apiService.searchMerchant(request);
            this.pageNumber = this.responseMerchant.result.total[0].total_record / this.maxSize;
            this.pageNumber = Math.ceil(this.pageNumber);
            this.totalWallet = this.responseMerchant.result.total[0].total_wallet;
            this.totalWalletDw = this.responseMerchant.result.total[0].total_wallet_dw;
            this.totalWalletCredit = this.responseMerchant.result.total[0].total_wallet_credit;
        } else if (this.role === 'MERCHANT') {
            this.responseMerchant = await this.apiService.searchSubAccount(request);
        }

    }


    counter(i: number) {
        return new Array(i);
    }

    public async setDefault() {

    }

    async searchPayment(page) {
        this.currentPage = page;
        let request = {
            username: this.username,
            is_active: this.status,
            company_name: '',
            bank_name: '',
            role: '',
            secret_key: '',
            page: this.currentPage,
            limit: this.maxSize
        };
        if (this.role === 'ADMIN') {
            this.responseMerchant = await this.apiService.searchMerchant(request);
            this.pageNumber = this.responseMerchant.result.total[0].total_record / this.maxSize;
            this.pageNumber = Math.ceil(this.pageNumber);
            this.totalWallet = this.responseMerchant.result.total[0].total_wallet;
            this.totalWalletDw = this.responseMerchant.result.total[0].total_wallet_dw;
            this.totalWalletCredit = this.responseMerchant.result.total[0].total_wallet_credit;
        } else if (this.role === 'MERCHANT') {
            this.responseMerchant = await this.apiService.searchSubAccount(request);
        }
    }


    async modal(username) {
        this.isUpdate = true;
        for (let i = 0; i < this.responseMerchant.result.length; i++) {
            if (username == this.responseMerchant.result[i].username) {
                this.current_transaction = this.responseMerchant.result[i];
                this.isActiveUpdate =  this.responseMerchant.result[i].active;
                this.firstnameUpdate =  this.responseMerchant.result[i].firstname;
                this.lastnameUpdate =  this.responseMerchant.result[i].lastname;
                this.usernameUpdate =  this.responseMerchant.result[i].username;
                this.passwordUpdate =  this.responseMerchant.result[i].password;
                this.roleRegisterUpdate =  this.responseMerchant.result[i].role;
                console.log(this.current_transaction);
            }
        }
        this.ref.detectChanges();
    }

    async registerOpen() {
        this.isUpdate = false;
    }

    async update(){
        let request = {
            username: this.usernameUpdate,
            password: this.passwordUpdate,
            active: this.isActiveUpdate,
            firstname: this.firstnameUpdate,
            lastname: this.lastnameUpdate,
            role: this.roleRegisterUpdate
        };
        // /update-sub-account
        let response = await this.apiService.updateSubAccount(request);
        if(response.code == 0) {
            alert("username :" + response.result.username + " password: " + response.result.password);
            this.searchPayment(this.currentPage);
        }else{
            alert(response.message);

        }
    }

    async callback() {
        let request = {
            order_id: this.current_transaction.order_id
        };

        let response = await this.apiService.retryCallback(request);

        this.ref.detectChanges();
    }


    async registeration() {
        if (this.role === 'ADMIN') {
            let request = {
                username: this.usernameRegister,
                password: this.passwordRegister,
                prefix: this.prefix,
                company_name: this.companyName,
                email: this.emailAddress,
                role: this.roleRegister,
                account_number: this.accountNumber,
                bank_name: this.bankName
            };
            let response;

            response = await this.apiService.register(request);
            alert(response.message);

        } else if (this.role === 'MERCHANT') {
            let request = {
                username: this.prefixRegister + '_' + this.usernameRegister,
                password: this.passwordRegister,
                prefix: this.prefixRegister,
                role: this.roleRegister,
                firstname: this.firstname,
                lastname: this.lastname,
                active: this.isActive,
            };
            let response = await this.apiService.addSubAccount(request);
            alert("username :"+response.result.username+" password: "+response.result.password);
            this.searchPayment(this.currentPage);
        }

    }
}

