import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../service/api.service';
import {ModalDirective} from 'angular-bootstrap-md';
import {HeaderComponent} from '../../header/header.component';
import {AppComponent} from '../../../app.component';
import {TranslateService} from '@ngx-translate/core';
import {DefaultVariable} from '../../common/default.variable';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Color, Label, SingleDataSet} from 'ng2-charts';

declare let jQuery: any;

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends AppComponent implements OnInit {

    @ViewChild('childModal') childModal: ModalDirective;

    userProfile: any;
    isActive: boolean = false;
    agent: number = 0;
    member: number = 0;
    wallet: any = parseFloat(localStorage.getItem('wallet'));
    walletDw: any = parseFloat(localStorage.getItem('wallet_dw'));
    walletCredit: any = parseFloat(localStorage.getItem('wallet_credit'));

    public pieChartColors: Color[] = [
        {
            backgroundColor: ['rgba(0,53,127)', 'rgba(0,122,199)', 'rgba(255,101,0)',
                'rgb(90,33,135)', 'rgb(0,168,237)', 'rgb(0,172,68)',
                'rgb(255,0,144)', 'rgb(255,150,20)', 'rgb(0,50,160)',
                'rgb(255,211,0)', 'rgb(191,0,60)', 'rgb(0,95,17)']
        },
    ];

    public chartColors: Array<any> = [
        {
            backgroundColor: ['rgba(89,177,66)', 'rgba(89,177,66)', 'rgba(89,177,66)'
                , 'rgba(89,177,66)', 'rgba(89,177,66)', 'rgba(89,177,66)'
                , 'rgba(89,177,66)', 'rgba(89,177,66)', 'rgba(89,177,66)'
                , 'rgba(89,177,66)', 'rgba(89,177,66)', 'rgba(89,177,66)'
                , 'rgba(89,177,66)', 'rgba(89,177,66)', 'rgba(89,177,66)'
                , 'rgba(89,177,66)', 'rgba(89,177,66)', 'rgba(89,177,66)'
                , 'rgba(89,177,66)', 'rgba(89,177,66)', 'rgba(89,177,66)'
                , 'rgba(89,177,66)', 'rgba(89,177,66)', 'rgba(89,177,66)'
                , 'rgba(89,177,66)', 'rgba(89,177,66)', 'rgba(89,177,66)'
                , 'rgba(89,177,66)', 'rgba(89,177,66)', 'rgba(89,177,66)'
                , 'rgba(89,177,66)', 'rgba(89,177,66)', 'rgba(89,177,66)'
                , 'rgba(89,177,66)', 'rgba(89,177,66)', 'rgba(89,177,66)'
                , 'rgba(89,177,66)', 'rgba(89,177,66)', 'rgba(89,177,66)']
        }
    ];
    listMerchant: any;

    summaryBudget: any;
    summaryWithdraw: any;
    username: any;

    mounth: any = 1;
    day: any = 1;
    allDate: any = 30;
    type: any = 'T';

    ttl_amount: any = 0;
    ttl_count: any = 0;

    barChartOptions: ChartOptions = {
        responsive: true,
    };
    barChartLabels: Label[];
    barChartType: ChartType = 'bar';
    barChartLegend = true;
    barChartPlugins = [];

    barChartData: ChartDataSets[];

    barChartFrequencyOptions: ChartOptions = {
        responsive: true,
    };
    barChartFrequencyLabels: Label[];
    barChartFrequencyType: ChartType = 'bar';
    barChartFrequencyLegend = true;
    barCharFrequencyPlugins = [];

    barChartFrequencyData: ChartDataSets[];


    public pieChartOptions: ChartOptions = {
        responsive: true,
    };
    public pieChartLabels: Label[] = [['No data']];
    public pieChartData: SingleDataSet = [100];
    public pieChartType: ChartType = 'pie';
    public pieChartLegend = true;
    public pieChartPlugins = [];


    public currentPage: 1;
    public pageNumber: any;
    loginForm: FormGroup;
    public password: any;

    request: LoginRequestModel;

    constructor(
        private apiService: ApiService,
        private _formBuilder: FormBuilder,
        public translate: TranslateService
    ) {
        super(translate);
    }

    async ngOnInit(): Promise<any> {
        this.loginForm = this._formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });


        this.listMerchant = await this.apiService.listMerchant();
        jQuery('.dashboard').addClass('active');
        // this.userProfile = await this.apiService.getUserProfile(localStorage.getItem('userId'));
        // for (let i = 0; i < this.userProfile.downline.length; i++) {
        //     if (this.userProfile.downline[i]._id === 'AGENT') {
        //         this.agent = this.userProfile.downline[i].totalCount;
        //     }
        //     if (this.userProfile.downline[i]._id === 'MEMBER') {
        //         this.member = this.userProfile.downline[i].totalCount;
        //     }
        // }

        let request = {
            start_date: '',
            end_date: ''
        };

        let request_bar = {
            mounth: this.mounth,
            day: this.day,
            type: this.type
        };

        this.userProfile = await this.apiService.getUserProfile();

        if (this.role == 'ADMIN' || this.role == 'ADMIN_READ_ONLY') {
            this.searchChart();
        } else {
            this.searchChart();
            this.summaryBudget = await this.apiService.getSummaryTransaction(request);
            this.summaryWithdraw = await this.apiService.getSummaryPayout(request);
        }
        this.wallet = this.userProfile.result.wallet;
        this.walletCredit = this.userProfile.result.walletCredit;
        this.walletDw = this.userProfile.result.walletDw;
        // this.summaryBudget = await this.apiService.getSummaryTransaction({});
        this.isActive = true;
    }


    async searchPayment(page) {
        this.currentPage = page;
    }

    async searchChart() {
        this.isActive = false;
        this.barChartLabels;
        this.barChartData = [{label: 'No data'}];

        this.barChartFrequencyLabels;
        this.barChartFrequencyData = [{label: 'No data'}];
        let request_bar = {
            mounth: this.mounth,
            day: this.day,
            type: this.type,
            username: this.username
        };
        let response = {
            data: [],
            label: []
        };
        let response_requency = {
            data: [],
            label: []
        };
        let response_pie = {
            data: [100],
            label: ['No data']
        };
        if (this.role == 'ADMIN' || this.role == 'ADMIN_READ_ONLY') {
            response = await this.apiService.getBarchart(request_bar);
            response_pie = await this.apiService.getPiechart(request_bar);
            response_requency = await this.apiService.getBarchartFrequency(request_bar);
            this.ttl_amount = 0;
            this.ttl_count = 0;
            for (let i = 0; i < response.data.length; i++) {
                this.ttl_amount = this.ttl_amount + response.data[i];
            }
            for (let k = 0; k < response_requency.data.length; k++) {
                this.ttl_count = this.ttl_count + response_requency.data[k];
            }
        } else {
            response = await this.apiService.getBarchartFront(request_bar);
            response_requency = await this.apiService.getBarchartFrequencyFront(request_bar);
            response_pie = await this.apiService.getPiechartFont(request_bar);
        }

        setTimeout(() => {
            this.barChartData = [];
            this.barChartData.push({data: response.data, label: 'Total Amount / Time'});
            this.barChartLabels = response.label;
            this.barChartFrequencyData = [];
            this.barChartFrequencyData.push({data: response_requency.data, label: 'Total Frequency / Time'});
            this.barChartFrequencyLabels = response_requency.label;
            if (response_pie.data.length > 0) {
                this.pieChartData = response_pie.data;
                this.pieChartLabels = response_pie.label;
            }
        }, 1000);


        this.isActive = true;
    }

    counter(i: number) {
        return new Array(i);
    }

    getDateFromMounth() {
        let d = new Date();
        d.setMonth(parseInt(this.mounth));
        let data2 = new Date(d.getFullYear(), d.getMonth() + 1, 0);
        this.allDate = data2.getDate();
    }


}


export class LoginRequestModel {
    public username: any;
    public password: any;

    constructor(username, password) {
        this.password = password;
        this.username = username;
    }

}
