import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../service/api.service';
import {ModalDirective} from 'angular-bootstrap-md';
import {AppComponent} from '../../../../app.component';
import {TranslateService} from '@ngx-translate/core';

declare let jQuery: any;

@Component({
    selector: 'app-member',
    templateUrl: './account-portal.component.html'
})
export class AccountPortalComponent extends AppComponent implements OnInit {

    @ViewChild('childModal') childModal: ModalDirective;

    loginForm: FormGroup;
    public password: any;

    constructor(
        private apiService: ApiService,
        private _formBuilder: FormBuilder,
        public translate: TranslateService
    ) {
        super(translate);
    }

    async ngOnInit(): Promise<any> {
        this.loginForm = this._formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        jQuery('.account-portal').addClass('active');
    }

}
