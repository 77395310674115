import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../service/api.service';
import {ModalDirective} from 'angular-bootstrap-md';
import {HeaderComponent} from '../../header/header.component';
import {AppComponent} from '../../../app.component';
import {TranslateService} from '@ngx-translate/core';

declare let jQuery: any;

@Component({
    selector: 'app-dashboard',
    templateUrl: './register.component.html'
})
export class RegisterComponent extends AppComponent implements OnInit {

    @ViewChild('childModal') childModal: ModalDirective;
    userProfile: any;
    fieldTextTypess: boolean;

    agent: number = 0;
    member: number = 0;
    wallet: any = parseFloat(localStorage.getItem('wallet'));
    walletDw: any = parseFloat(localStorage.getItem('wallet_dw'));
    walletCredit: any = parseFloat(localStorage.getItem('wallet_credit'));

    toggleFieldTextType() {
        this.fieldTextTypess = !this.fieldTextTypess;
    }

    loginForm: FormGroup;
    public username: any;
    public password: any;
    public prefix: any;
    public prefix_register: any;
    public company_name: any;
    public company_name_en: any;
    public email_address: any;
    public role_register: any;
    public account_number: any;
    public bank_name: any;
    isActive: boolean = false;
    public firstname: any;
    public lastname: any;


    constructor(
        private apiService: ApiService,
        private _formBuilder: FormBuilder,
        public translate: TranslateService
    ) {
        super(translate);
    }

    async ngOnInit(): Promise<any> {
        this.loginForm = this._formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.prefix_register = localStorage.getItem('prefix');
        jQuery('.account-portal').addClass('active');
        // this.userProfile = await this.apiService.getUserProfile(localStorage.getItem('userId'));
        // for (let i = 0; i < this.userProfile.downline.length; i++) {
        //     if (this.userProfile.downline[i]._id === 'AGENT') {
        //         this.agent = this.userProfile.downline[i].totalCount;
        //     }
        //     if (this.userProfile.downline[i]._id === 'MEMBER') {
        //         this.member = this.userProfile.downline[i].totalCount;
        //     }
        // }
        if (this.role == 'MERCHANT') {
            this.role_register = 'STAFF';
        }
        this.isActive = true;
    }


    async registeration() {
        if (this.role === 'ADMIN') {
            let request = {
                username: this.username,
                password: this.password,
                prefix: this.prefix,
                company_name: this.company_name,
                email: this.email_address,
                role: this.role_register,
                account_number: this.account_number,
                bank_name: this.bank_name
            };
            let response;

            response = await this.apiService.register(request);
            alert(response.message);

        } else if (this.role === 'MERCHANT') {
            let request = {
                username: this.prefix_register + '_' + this.username,
                password: this.password,
                prefix: this.prefix_register,
                role: this.role_register,
                firstname: this.firstname,
                lastname: this.lastname,
                active: this.isActive,
            };
            let response;

            response = await this.apiService.addSubAccount(request);
            alert(response.message);

        }

    }


}




