import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../service/api.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DefaultVariable} from '../../../common/default.variable';
import * as XLSX from 'xlsx';
import {AppComponent} from '../../../../app.component';
import {TranslateService} from '@ngx-translate/core';

declare let jQuery: any;

@Component({
    selector: 'app-report-total-bet',
    templateUrl: './report-payout.component.html',
    styleUrls: ['./report-payout.component.scss']
})
export class ReportPayoutComponent extends AppComponent implements OnInit {

    @ViewChild('printInvoice') myDiv: ElementRef<HTMLElement>;

    loginForm: FormGroup;
    mySubscription: any;
    public path: any = [];

    public password: any;
    public username: any;

    public responseTransaction: any;

    public pageNumber: any;
    public level: any = 1;
    public position: any = '';
    public currentPage: 1;


    public status: any = '';
    public status_payout: any = '';

    public total_fee: any = 0;
    public total_amount: any = 0;
    public service_id: any = '';
    public startDate: any;
    public endDate: any;
    public transactionId: any;


    public currentPosition: any = localStorage.getItem('role');
    public method: string = localStorage.getItem('payType');

    public currentPayoutTransaction: any;

    public sumActualAmount: number = 0;
    public sumAmount: number = 0;
    public category: any;
    public sumFee: number = 0;
    public serviceCategory: any;


    public id: any = localStorage.getItem('id');


    constructor(
        private ref: ChangeDetectorRef,
        private apiService: ApiService,
        private _formBuilder: FormBuilder,
        private route: ActivatedRoute,
        public router: Router,
        public translate: TranslateService
    ) {
        super(translate);
    }


    async ngOnInit(): Promise<any> {
        await this.btnTime('Today');
        this.serviceCategory = await this.apiService.getAllService();
        this.startDate = new Date();
        this.endDate = new Date();
        // jQuery('#showMenuList').addClass('active');
        jQuery('.report-payout').addClass('active');

        jQuery('#showMenuList').slideDown(300, function() {
            jQuery('#showMenuList').show();
        });

        this.currentPayoutTransaction = {
            payment_id: '',
            order_id: '',
            amount: '',
            status: '',
            customer_actual_amount: '',
            merchant_fee: '',
            status_history: [],
        };
        this.startDate = await this.combineDateAndTime(this.defaultStartDate,this.defaultStartTime);
        this.endDate = await this.combineDateAndTime(this.defaultEndDate,this.defaultEndTime);

        let request = {
            date_from: this.startDate,
            date_to: this.endDate,
            transaction_Id: '',
            merchant_id: this.username,
            status: '',
            limit: this.maxSize,
            page: this.page
        };

        if (this.role === 'MERCHANT' || this.role === 'STAFF') {
            jQuery('.defult-active').addClass('active');
            this.responseTransaction = await this.apiService.getPayoutTransaction(request);
        } else if (this.role === 'ADMIN' || this.role == 'ADMIN_READ_ONLY') {
            this.responseTransaction = await this.apiService.adminGetPayoutTransaction(request);
        }

        this.pageNumber = this.responseTransaction.result.total / this.maxSize;
        this.pageNumber = Math.ceil(this.pageNumber);
        this.sumFee = 0;
        this.sumActualAmount = 0;
        this.sumAmount = 0;
        for (let i = 0; i < this.responseTransaction.result.summary.length; i++) {
            this.sumFee = this.sumFee + parseFloat(this.responseTransaction.result.summary[i].total_fee);
            this.sumActualAmount = this.sumActualAmount + parseFloat(this.responseTransaction.result.summary[i].total_amount_inc_fee) + parseFloat(this.responseTransaction.result.summary[i].total_amount_dw_inc_fee);
            this.sumAmount = this.sumAmount + parseFloat(this.responseTransaction.result.summary[i].total_amount) + parseFloat(this.responseTransaction.result.summary[i].total_amount_dw);
        }
        this.isActive = true;
        jQuery('.defult-active').addClass('active');
    }

    async ExportTOExcel() {
        let exportField;
        this.startDate = await this.combineDateAndTime(this.defaultStartDate,this.defaultStartTime);
        this.endDate = await this.combineDateAndTime(this.defaultEndDate,this.defaultEndTime);

        let request = {
            date_from: this.startDate,
            date_to: this.endDate,
            transaction_Id: this.transactionId,
            merchant_id: this.username,
            status: this.status,
            limit: 50000,
            page: this.currentPage
        };

        if (this.role === 'MERCHANT' || this.role === 'STAFF') {
            exportField = await this.apiService.getPayoutTransaction(request);
        } else if (this.role === 'ADMIN'|| this.role == 'ADMIN_READ_ONLY') {
            exportField = await this.apiService.adminGetPayoutTransaction(request);
        }

        const ws: XLSX.WorkSheet = await XLSX.utils.json_to_sheet(exportField.result.transactions);
        const wb: XLSX.WorkBook = await XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        let fileName = new Date().toString() + '.xlsx';
        XLSX.writeFile(wb, fileName);
    }


    counter(i: number) {
        return new Array(i);
    }

    public async setDefault() {

    }

    async searchPayment(page) {
        this.isActive = false;
        this.currentPage = page;
        this.startDate = await this.combineDateAndTime(this.defaultStartDate,this.defaultStartTime);
        this.endDate = await this.combineDateAndTime(this.defaultEndDate,this.defaultEndTime);
        let request = {
            date_from: this.startDate,
            date_to: this.endDate,
            transaction_Id: this.transactionId,
            merchant_id: this.username,
            status: this.status,
            limit: this.maxSize,
            page: this.currentPage
        };


        if (this.role === 'MERCHANT' || this.role === 'STAFF') {
            this.responseTransaction = await this.apiService.getPayoutTransaction(request);
        } else if (this.role === 'ADMIN'|| this.role == 'ADMIN_READ_ONLY') {
            this.responseTransaction = await this.apiService.adminGetPayoutTransaction(request);
        }
        this.pageNumber = this.responseTransaction.result.total / this.maxSize;
        this.pageNumber = Math.ceil(this.pageNumber);
        this.sumFee = 0;
        this.sumActualAmount = 0;
        this.sumAmount = 0;
        for (let i = 0; i < this.responseTransaction.result.summary.length; i++) {
            this.sumFee = this.sumFee + parseFloat(this.responseTransaction.result.summary[i].total_fee);
            this.sumActualAmount = this.sumActualAmount + parseFloat(this.responseTransaction.result.summary[i].total_amount_inc_fee) + parseFloat(this.responseTransaction.result.summary[i].total_amount_dw_inc_fee);
            this.sumAmount = this.sumAmount + parseFloat(this.responseTransaction.result.summary[i].total_amount) + parseFloat(this.responseTransaction.result.summary[i].total_amount_dw);
        }
        this.isActive = true;
    }

    async modal(transaction_id) {
        for (let i = 0; i < this.responseTransaction.result.transactions.length; i++) {
            if (transaction_id == this.responseTransaction.result.transactions[i].transaction_id) {
                this.currentPayoutTransaction = this.responseTransaction.result.transactions[i];
                console.log(this.currentPayoutTransaction);
            }
        }
        this.ref.detectChanges();
    }

    async updateStatus() {
        let request = {
            transaction_id: this.currentPayoutTransaction.transaction_id,
            status: this.status_payout
        };

        if (this.role === 'ADMIN' || this.role === 'ADMIN_READ_ONLY') {
            if (confirm('Do you want to update status?')) {
                let response = await this.apiService.updateStatus(request);
                alert(response.message);
            }
        }
        await this.searchPayment(this.currentPage);
    }

    async btnTimePN(param) {
        if (param == 'Next') {
            this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
            this.defaultEndDate = new Date();
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'Previous') {
            this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
            this.defaultEndDate = new Date();
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
            if (this.stage == 'Today') {
                this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()+1, 0, 0, 0);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()+1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Yesterday') {

                this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() , 0, 0, 0);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'This week') {
                this.defaultStartDate = await this.getNextMonday(this.today);
                this.defaultEndDate = await this.getNextSunday(this.today);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Last week') {
                this.stage = 'This week';
                this.defaultStartDate = await this.getMonday(this.today);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'This month') {
                this.defaultStartDate = await this.getStartNextMonth(this.today);
                let current = await this.getStartNextMonth(this.today);
                this.defaultEndDate = new Date(current.getFullYear(), current.getMonth()+1, current.getDate() - 1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Last month') {
                this.stage = 'This month';
                this.defaultStartDate = await this.getStartMonth(this.today);
                this.defaultEndDate = new Date();
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            }

        } else if (param == 'Previous') {
            if (this.stage == 'Today') {
                this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()-1, 0, 0, 0);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()-1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Yesterday') {

                this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 2, 0, 0, 0);
                this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 2, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'This week') {
                this.defaultStartDate = await this.getLastMonday(this.today);
                this.defaultEndDate = await this.getLastSunday(this.today);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Last week') {

                this.defaultStartDate = await this.getLastMonday(await this.getLastMonday(this.today));
                this.defaultEndDate = await this.getLastSunday(await this.getLastSunday(this.today));
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'This month') {
                this.defaultStartDate = await this.getStartLastMonth(this.today);
                let current = await this.getStartMonth(this.today);
                this.defaultEndDate = new Date(current.getFullYear(), current.getMonth(), current.getDate() - 1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            } else if (this.stage == 'Last month') {
                this.defaultStartDate = await this.getStartLastMonth(await this.getStartLastMonth(this.today));
                let current = await this.getStartLastMonth(this.today);
                this.defaultEndDate = new Date(current.getFullYear(), current.getMonth(), current.getDate() - 1, 0, 0, 0);
                this.defaultEndTime = '23:59';
                this.defaultStartTime = '00:00';
            }
        }
        this.searchPayment(this.currentPage);
    }

    async btnTime(param) {
        if (param == 'Today') {
            this.stage = 'Today';
            this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
            this.defaultEndDate = new Date();
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'Yesterday') {
            this.stage = 'Yesterday';
            this.defaultStartDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 1, 0, 0, 0);
            this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 1, 0, 0, 0);
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'This week') {
            this.stage = 'This week';
            this.defaultStartDate = await this.getMonday(this.today);
            this.defaultEndDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0);
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'Last week') {
            this.stage = 'Last week';
            this.defaultStartDate = await this.getLastMonday(this.today);
            this.defaultEndDate = await this.getLastSunday(this.today);
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'This month') {
            this.stage = 'This month';
            this.defaultStartDate = await this.getStartMonth(this.today);
            this.defaultEndDate = new Date();
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        } else if (param == 'Last month') {
            this.defaultStartDate = await this.getLastMonth(this.today);
            this.defaultEndDate = await this.getLastMonthEnd(this.today);
            this.stage = 'Last month';
            this.defaultStartDate = await this.getStartLastMonth(this.today);
            let current = await this.getStartMonth(this.today);
            this.defaultEndDate = new Date(current.getFullYear(), current.getMonth(), current.getDate() - 1, 0, 0, 0);
            this.defaultEndTime = '23:59';
            this.defaultStartTime = '00:00';
        }
        this.searchPayment(this.currentPage);
    }

}
