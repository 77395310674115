<body class="fixed-sn">
<div class="wrapper container">
    <app-header></app-header>
    <main>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a [routerLink]="">Report</a></li>
                <li class="breadcrumb-item"><a [routerLink]="">

                    <ng-container *ngIf="this.role === 'ADMIN'">
                        Merchant Report
                    </ng-container>
                    <ng-container *ngIf="this.role === 'MERCHANT'">Sub Account Setting
                    </ng-container>
                </a></li>
            </ol>
        </nav>
        <ng-container *ngIf="this.role === 'ADMIN'">
            <h1>Merchant Report</h1>
        </ng-container>
        <ng-container *ngIf="this.role === 'MERCHANT'"><h1>Sub Account Setting</h1>
        </ng-container>
        <div class="pb-3"></div>
        <!--        <div class="btn-toolbar mb-3" role="toolbar">-->
        <!--        <div class="form-row">-->
        <!--            <div class="form-group col-12">-->
        <!--                <div class="form-group row">-->
        <!--                    <div class="input-group table-search col-2">-->
        <!--                        <input type="text" class="form-control" placeholder="Username" aria-label="Username"-->
        <!--                               aria-describedby="button-addon2" [(ngModel)]="username" name="username">-->
        <!--                    </div>-->

        <!--                    <div class="input-group table-search col-3">-->
        <!--                        <div class="select-form">-->
        <!--                            <div class="btn-group">-->
        <!--                                <select name="status" class="form-control-lg-select btn btn-primary"-->
        <!--                                        [(ngModel)]="status">-->
        <!--                                    <option selected="selected" value="">All</option>-->
        <!--                                    <option value="SUCCESS">Active</option>-->
        <!--                                    <option value="DISPUTE">Suspend</option>-->
        <!--                                </select>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="form-group row">-->
        <!--                    <div class="input-group table-search col-2">-->
        <!--                        <button class="btn btn-lg btn-yellow font-weight-bold" type="button" (click)="searchPayment(1)">-->
        <!--                            Search-->
        <!--                        </button>-->
        <!--                    </div>-->
        <!--                    <div class="input-group table-search col-2">-->
        <!--                        &lt;!&ndash;                        <a>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                        <a class="link" data-toggle="modal" data-target="#modal-transaction">&ndash;&gt;-->
<!--                                <button class="btn btn-lg btn-yellow font-weight-bold link" type="button" data-toggle="modal"-->
<!--                                        data-target="#register" (click)="registerOpen()">Add-->
        <!--                        </button>-->
        <!--                        &lt;!&ndash;                        </a>&ndash;&gt;-->
        <!--                        &lt;!&ndash;                        </a>&ndash;&gt;-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
        <div class="btn-toolbar section-group" role="toolbar" style="padding-bottom: 0px !important;">
            <div class="form-group col-md-12 row">
                <div class="input-group table-search" style="padding-left: 0.7rem;">
                    <label for="email" class="col-form-label font-weight-bold"
                           set-lan="text:Start date_">{{'common.username' | translate }}:</label>
                    <div class="input-group table-search col-8">
                        <input type="text" class="form-control customer-border" placeholder="username"
                               aria-label="username" aria-describedby="button-addon2" [(ngModel)]="username"
                               name="username">
                    </div>
                </div>

                <div class="input-group table-search" style="padding-left: 0.7rem;">
                    <label for="email" class="col-form-label font-weight-bold"
                           set-lan="text:Start date_">{{'common.status' | translate }}:</label>
                    <div class="select-form  customer-border" style="border-radius: 3px; margin-left: 1.6rem;">
                        <div class="btn-group">
                            <select name="status" style="color:#008F6B; padding-left: 0px;"
                                    class="form-control-lg-select btn btn-primary" [(ngModel)]="status">
                                <option selected="selected" value="">All</option>
                                <option value="SUCCESS">Active</option>
                                <option value="DISPUTE">Suspend</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="input-group table-search" style="padding-left: 0.7rem;">
                    <button class="btn btn-yellow font-weight-bold m-0 px-3 py-2 z-depth-0 waves-effect btnMenu"
                            type="button" set-lan="text:Search" (click)="searchPayment(1)"
                            style="border-radius: 3px; margin-left: 1.6rem;">Search
                    </button>

                </div>
                <div class="input-group table-search" style="padding-left: 0.7rem;">
                    <button class="btn btn-md btn-yellow font-weight-bold link" type="button" data-toggle="modal"
                            data-target="#register" (click)="registerOpen()">Add</button>
                </div>
            </div>
            <!--            <div class="row col-md-12">-->
            <!--                <div class="form-group row" style="padding-left: 0.6rem;">-->
            <!--                    <label for="email" class="col-form-label font-weight-bold" set-lan="text:Start date_">Start date-->
            <!--                        :</label>-->
            <!--                    <div class="input-group table-search" style="padding-left: 1.15rem;">-->
            <!--                        <input style="width: 150px;" type="date" id="startDate" class="form-control customer-border"-->
            <!--                               [ngModel]="defaultStartDate | date:'yyyy-MM-dd'" placeholder="Payment Id"-->
            <!--                               aria-label="Start Date" name="defaultStartDate"-->
            <!--                               (change)="setStartDate($event.target.value)">-->
            <!--                    </div>-->
            <!--                    <div style="padding-left: 0.7rem;">-->
            <!--                        <input type="time" data-date="12:00 AM" class="testDate form-control customer-border"-->
            <!--                               [(ngModel)]="defaultStartTime" id="starttime" data-date-format="hh:mm A">-->
            <!--                    </div>-->
            <!--                </div>-->

            <!--                <div class="form-group row" style="padding-left: 2.5rem;">-->
            <!--                    <label for="email" class="col-form-label font-weight-bold" set-lan="text:To date_">To date-->
            <!--                        :</label>-->
            <!--                    <div class="input-group table-search" style="padding-left: 1rem;">-->
            <!--                        <input type="date" id="endDate" style="width: 150px;" class="form-control customer-border"-->
            <!--                               aria-label="End Date" [ngModel]="defaultEndDate | date:'yyyy-MM-dd'"-->
            <!--                               (change)="setEndtDate($event.target.value)">-->
            <!--                    </div>-->
            <!--                    <div class="input-group table-search" style="padding-left: 0.7rem;">-->
            <!--                        <input type="time" data-date="12:00 AM" class="testDate form-control customer-border"-->
            <!--                               [(ngModel)]="defaultEndTime" id="endtime" data-date-format="hh:mm A">-->
            <!--                    </div>-->
            <!--                </div>-->

            <!--                            <div class="form-group row" style="padding-left: 2rem;">-->
            <!--                                <button class="btn btn-yellow font-weight-bold m-0 px-3 py-2 z-depth-0 waves-effect btnMenu"-->
            <!--                                        type="button" set-lan="text:Search" (click)="searchPayment(1)">Search</button>-->
            <!--                            </div>-->
            <!--                            <a onclick="btnExport();" id="btnExport" set-lan="title:Export Excel" title="Export Excel"-->
            <!--                               style="display: initial; padding-left: 0.5rem;">-->
            <!--                                <img src="../../../../../assets/img/icons/Excel.png"-->
            <!--                                     style="width: 37px; margin-left: .5rem; padding-top: 3px;"></a>-->
            <!--                            <label id="txtExport" set-lan="text:ExportNote"-->
            <!--                                   style="font-size: 10pt; width: 185px; margin-left: 0.5rem; line-height: 1rem; display: initial;">Remark:-->
            <!--                                limit to 100 records per download</label>-->
            <!--                        </div>-->
            <!--            <div class="form-group col-md-12 row">-->
            <!--                <label for="email" class="col-form-label font-weight-bold"-->
            <!--                       set-lan="text:Start date_">{{'common.status' | translate }}</label>-->
            <!--                <div class="input-group table-search col-4">-->
            <!--                    <div class="select-form  customer-border" style="border-radius: 3px; margin-left: 1.6rem;">-->
            <!--                        <div class="btn-group">-->
            <!--                            <select name="status" style="color:#008F6B; padding-left: 0px;"-->
            <!--                                    class="form-control-lg-select btn btn-primary" [(ngModel)]="status">-->
            <!--                                <option selected="selected" value="">All</option>-->
            <!--                                <option value="SUCCESS">Active</option>-->
            <!--                                <option value="DISPUTE">Suspend</option>-->
            <!--                            </select>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>


        <!--        </div>-->

        <div class="table-wrapper">
            <nav aria-label="Page navigation example" style="float: right;">
                <ul class="pagination">
                    <li class="page-item">
                        <a class="page-link" (click)="searchPayment(currentPage - 1)">Previous</a>
                    </li>
                    <li class="page-item" *ngFor="let page of counter(pageNumber); let i = index">
                        <a class="page-link" (click)="searchPayment(i+1)">{{i + 1}}</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" (click)="searchPayment(currentPage + 1)">Next</a>
                    </li>
                </ul>
            </nav>
<!--            <nav aria-label="Page navigation example" style="float: left">-->

<!--            </nav>-->

            <ng-container *ngIf="this.role === 'ADMIN'">
                <table class="table table-bordered sortTable" datatables="ng" dt-options="dtOptions">
                    <thead>
                    <tr>
                        <th class="no-sort">Username</th>
                        <th><span class="col-cell">Account No.</span></th>
                        <th><span class="col-cell">Bank</span></th>
                        <th><span class="col-cell">Cash</span></th>
                        <th><span class="col-cell">Credit</span></th>
                        <th><span class="col-cell">Dragon Coin</span></th>
                        <th><span class="col-cell">Type</span></th>
                        <th><span class="col-cell">Status</span></th>
                        <th class="text-nowrap"><span class="col-cell">Date & Time</span></th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="hide">
                        <td></td>
                    </tr>
                    <tr *ngFor="let index of responseMerchant?.result.transactions;let i=index">
                        <td>{{index.username}}</td>
                        <td>{{index.accountNumber}}</td>
                        <td>{{index.bankName}}</td>
                        <td>{{index.wallet}}</td>
                        <td>{{index.walletCredit}}</td>
                        <td>
                            {{index.walletDw}}
                        </td>
                        <td>{{index.role}}</td>
                        <td>
                            {{index.active}}
                        </td>
                        <td>
                            {{index.createDate}}
                        </td>
                        <td>
                            <a class="link" data-toggle="modal" data-target="#modal-transaction"
                               (click)="modal(index.username)">View</a>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot class="rgba-yellw-slight">
                    <tr class="total">
                        <td colspan="3" class="total-cell">Total</td>
                        <td>{{totalWallet  | number:'.2'}}</td>
                        <td>{{totalWalletCredit | number:'.2'}}</td>
                        <td>
                            {{totalWalletDw | number:'.2'}}
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td></td>
                    </tr>
                    </tfoot>
                </table>
            </ng-container>

            <ng-container *ngIf="this.role === 'MERCHANT'">
                <table class="table table-bordered sortTable" datatables="ng" dt-options="dtOptions">
                    <thead>
                    <tr>
                        <th class="no-sort">Username</th>
                        <th><span class="col-cell">Firstname</span></th>
                        <th><span class="col-cell">Lastname</span></th>
                        <th><span class="col-cell">Type</span></th>
                        <th><span class="col-cell">Status</span></th>
                        <th class="text-nowrap"><span class="col-cell">Date & Time</span></th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="hide">
                        <td></td>
                    </tr>
                    <tr *ngFor="let index of responseMerchant?.result;let i=index">
                        <td>{{index.username}}</td>
                        <td>{{index.firstname}}</td>
                        <td>{{index.lastname}}</td>

                        <td>{{index.role}}</td>
                        <td>
                            {{index.active}}
                        </td>
                        <td>
                            {{index.createDate | date:'d/M/yy, h:mm a'}}
                        </td>
                        <td>
                            <a class="link" data-toggle="modal" data-target="#register"
                               (click)="modal(index.username)">View</a>
                        </td>
                    </tr>
                    </tbody>
                    <tfoot class="rgba-yellw-slight">
                    </tfoot>
                </table>
            </ng-container>

        </div>

        <div class="modal fade" id="modal-transaction">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <!--                        <h4 class="modal-title">Payment information</h4>-->
                        <br/>
                    </div>
                    <div class="modal-body">
                        <form class="form-horizontal">
                            <div class="row">
                                <div class="col-6">
                                    <fieldset>
                                        <legend style="color:blue;font-weight:bold;">Payment Detail</legend>
                                        <table class="table table-bordered sortTable" datatables="ng"
                                               dt-options="dtOptions">
                                            <tbody class="table-striped">
                                            <tr>
                                                <td><b>Payment Id</b></td>
                                                <td>{{current_transaction.payment_id}}</td>

                                            </tr>
                                            <tr>
                                                <td><b>Order Id</b></td>
                                                <td>{{current_transaction.order_id}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Amount</b></td>
                                                <td>{{current_transaction.amount}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Fee</b></td>
                                                <td>{{current_transaction.merchant_fee}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Net Amount</b></td>
                                                <td>{{current_transaction.customer_actual_amount}}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Status</b></td>
                                                <td>{{current_transaction.status}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </fieldset>
                                </div>

                                <div class="col-6">
                                    <fieldset>
                                        <legend style="color:blue;font-weight:bold;">Payment History</legend>
                                        <table class="table table-bordered sortTable" datatables="ng"
                                               dt-options="dtOptions">
                                            <thead>
                                            <tr>
                                                <th style="text-align: left;"><span class="col-cell">Status</span></th>
                                                <th style="text-align: left;"><span class="col-cell">Date & Time</span>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody class="table-striped">
                                            <tr *ngFor="let index of current_transaction.status_history;let i= index">
                                                <td><b>{{index.status}}</b></td>
                                                <td>{{index.date}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </fieldset>
                                </div>
                            </div>

                            <br/>

                            <div class="row">
                                <div *ngIf="current_transaction.status == 'DISPUTE'">
                                    <button type="button" class="btn btn-default" (click)="callback()"
                                            data-dismiss="modal">
                                        Callback Api
                                    </button>
                                    &nbsp;
                                    &nbsp;
                                </div>

                                <button type="button" class="btn btn-default" data-dismiss="modal">
                                    Close
                                </button>
                            </div>
                            <!--                                                        <div class="modal-footer justify-content-between">-->

                            <!--                                                            <button type="button" class="btn btn-default" data-dismiss="modal">-->
                            <!--                                                                Close-->
                            <!--                                                            </button>-->
                            <!--                                                        </div>-->
                        </form>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade" id="register">
            <div class="modal-dialog modal-custom">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 style="color: white"><b>Register</b></h3>
                        <br/>
                    </div>
                    <div class="modal-body">
                        <ng-container *ngIf="this.role==='ADMIN'">
                            <div class="row">
                                <div class="col">
                                    <div class="card mb-12">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-2" style=" font-size: .7rem"><span
                                                        class="card-total-row"><b>Username: </b></span></div>
                                                <div class="col-3" style=" font-size: .7rem"><span class="card-sub-value-total">
                                     <div class="mb-1" style=" font-size: .7rem">
                                        <input type="text" class="form-control" id="usernameRegister"
                                               placeholder="Enter username"
                                               aria-describedby="remarkHelper"
                                               [(ngModel)]="usernameRegister" name="usernameRegister">
                                        <small id="remarkHelper" class="text-muted form-text"  style=" font-size: .7rem">
                                           Enter Your Username
                                        </small>
                                    </div>
                                </span></div>

                                                <div class="col-2" style=" font-size: .7rem"><span
                                                        class="card-total-row"><b>Password: </b></span></div>
                                                <div class="col-3"><span class="card-sub-value-total">
                                     <div class="mb-1" style=" font-size: .7rem">
                                        <input type="text" class="form-control" id="passwordRegister"
                                               placeholder="Enter password"
                                               aria-describedby="remarkHelper"
                                               [(ngModel)]="passwordRegister" name="passwordRegister">
                                        <small id="remarkHelper" class="text-muted form-text" style=" font-size: .7rem">
                                          Enter Your Password
                                        </small>
                                    </div>
                                </span></div>
                                            </div>

                                            <div class="row">
                                                <div class="col-2" style=" font-size: .7rem"><span
                                                        class="card-total-row"><b>Role: </b></span></div>
                                                <div class="col-3" style=" font-size: .7rem"><span class="card-sub-value-total">
                                      <div class="select-form">
                                            <div class="btn-group" style=" font-size: .7rem">
                                                <select name="roleRegister" class="form-control form-control-sm"
                                                        [(ngModel)]="roleRegister">
                                                    <option selected value="MERCHANT" style=" font-size: .7rem">MERCHANT</option>
                                                    <option value="ADMIN" style=" font-size: .7rem">ADMIN</option>
                                                    <option value="ADMIN_READ_ONLY" style=" font-size: .7rem">ADMIN_READ_ONLY</option>
                                                </select>
                                            </div>
                                        </div>
                                </span></div>
                                                <div class="col-2" style=" font-size: .7rem"><span
                                                        class="card-total-row"><b>Bank Name : </b></span></div>
                                                <div class="col-4"  style=" font-size: .7rem"><span class="card-sub-value-total">
<!--                                     <div class="input-group table-search col-3">-->
                                        <div class="select-form" style=" font-size: .7rem">
                                            <div class="btn-group"  style=" font-size: .7rem">
                                                <select name="bankName" class="form-control form-control-sm"
                                                        [(ngModel)]="bankName">
                                                    <option value="NO">---- เลือกธนาคารของท่าน ----</option>
                                                    <option value="SCB">ธนาคารไทยพาณิชย์ จำกัด (มหาชน)</option>
                                                    <option value="BBL">ธนาคารกรุงเทพ จำกัด (มหาชน)</option>
                                                    <option value="KBANK">ธนาคารกสิกรไทย จำกัด (มหาชน)</option>
                                                    <option value="KTB">ธนาคารกรุงไทย จำกัด (มหาชน)</option>
                                                    <option value="BAAC">ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร</option>
                                                    <option value="TMB">ธนาคารทหารไทย จำกัด (มหาชน)</option>
                                                    <option value="CIMB">ธนาคาร ซีไอเอ็มบี ไทย จำกัด (มหาชน)</option>
                                                    <option value="UOB">ธนาคารยูโอบี จำกัด (มหาชน)</option>
                                                    <option value="BAY">ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)</option>
                                                    <option value="GSB">ธนาคารออมสิน</option>
                                                    <option value="LHBANK">ธนาคารแลนด์ แอนด์ เฮ้าส์ จำกัด (มหาชน)
                                                    </option>
                                                    <option value="TBANK">ธนาคารธนชาต จำกัด (มหาชน)</option>
                                                    <option value="TISCO">ธนาคารทิสโก้ จำกัด (มหาชน)</option>
                                                    <option value="KKP">ธนาคารเกียรตินาคิน จำกัด (มหาชน)</option>
                                                </select>
                                            </div>
                                        </div>
                                                    <!--                                    </div>-->
                                </span></div>
                                            </div>

                                            <div class="row">

                                                <div class="col-2"  style=" font-size: .7rem"><span
                                                        class="card-total-row"><b>Email : </b></span></div>
                                                <div class="col-3"><span class="card-sub-value-total">
                                     <div class="mb-1">
                                        <input type="text" class="form-control" id="emailAddress"
                                               placeholder="Enter email_address"
                                               aria-describedby="remarkHelper"
                                               [(ngModel)]="emailAddress" name="emailAddress">
                                    </div>
                                </span></div>


                                                <div class="col-2" style=" font-size: .7rem"><span
                                                        class="card-total-row"><b>Company name: </b></span></div>
                                                <div class="col-3"><span class="card-sub-value-total">
                                     <div class="mb-1">
                                        <input type="text" class="form-control" id="companyName"
                                               placeholder="Enter company_name"
                                               aria-describedby="remarkHelper"
                                               [(ngModel)]="companyName" name="companyName">
                                    </div>
                                </span></div>
                                            </div>

                                            <div class="row">

                                                <div class="col-2" style=" font-size: .7rem"><span
                                                        class="card-total-row"><b>Prefix: </b></span></div>
                                                <div class="col-3"><span class="card-sub-value-total">
                                     <div class="mb-1" style=" font-size: .7rem">
                                        <input type="text" class="form-control" id="prefix" placeholder="Enter prefix"
                                               aria-describedby="remarkHelper"
                                               [(ngModel)]="prefix" name="prefix">
                                        <small id="remarkHelper" class="text-muted form-text" style=" font-size: .7rem">
                                          Enter Your Password
                                        </small>
                                    </div>
                                </span></div>


                                                <div class="col-2" style=" font-size: .7rem"><span
                                                        class="card-total-row"><b>Account No: </b></span></div>
                                                <div class="col-3"><span class="card-sub-value-total">
                                     <div class="mb-1" style=" font-size: .7rem">
                                        <input type="text" class="form-control" id="accountNumber"
                                               placeholder="Enter account_number"
                                               aria-describedby="remarkHelper"
                                               [(ngModel)]="accountNumber" name="accountNumber">
                                        <small id="remarkHelper" class="text-muted form-text" style=" font-size: .7rem">
                                           Enter Your Username
                                        </small>
                                    </div>
                                </span></div>
                                            </div>
                                            <div class="row">
                                                <div class="col-2">
                                                    <button class="btn btn-lg btn-yellow font-weight-bold" type="button"
                                                            (click)="registeration()">
                                                        Save
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="this.role==='MERCHANT'">
                            <div class="row">
                                <div class="col">
                                    <div class="card mb-lg-12">
                                        <ng-container *ngIf="isUpdate==false">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-2"><span
                                                            class="card-total-row"><b>Username: </b></span></div>
                                                    <div class="col-3"><span class="card-sub-value-total">
                                     <div class="mb-1">
                                          <div class="row">

                                                <div class="col-3" >
                                                     <span style="line-height: 4">{{prefixRegister}}_</span>
                                                </div>
                                               <div class="col-9">
                                       <input type="text" class="form-control" id="usernameStaff"
                                              placeholder="username"
                                              aria-describedby="remarkHelper"
                                              [(ngModel)]="usernameRegister" name="usernameRegister">
                                               </div>


                                          </div>
                                    </div>
                                </span>
                                                    </div>

                                                    <div class="col-2"><span
                                                            class="card-total-row"><b>Password: </b></span></div>
                                                    <div class="col-3"><span class="card-sub-value-total">
                                     <div class="mb-1">
                                        <input type="text" class="form-control" id="passwordStaff"
                                               placeholder="password"
                                               aria-describedby="remarkHelper"
                                               [(ngModel)]="passwordRegister" name="passwordRegister">

                                    </div>
                                </span></div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-2"><span
                                                            class="card-total-row"><b>Firstname : </b></span></div>
                                                    <div class="col-3"><span class="card-sub-value-total">
                                     <div class="mb-1">
                                        <input type="text" class="form-control" id="firstname"
                                               placeholder="firstname"
                                               aria-describedby="remarkHelper"
                                               [(ngModel)]="firstname" name="email_address">
                                    </div>
                                </span></div>
                                                    <div class="col-2"><span
                                                            class="card-total-row"><b>Lastname: </b></span></div>
                                                    <div class="col-3"><span class="card-sub-value-total">
                                     <div class="mb-1">
                                        <input type="text" class="form-control" id="lastname"
                                               placeholder="lastname"
                                               aria-describedby="remarkHelper"
                                               [(ngModel)]="lastname" name="company_name">
                                    </div>
                                </span></div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-2"><span
                                                            class="card-total-row"><b>Role: </b></span></div>
                                                    <div class="col-3"><span class="card-sub-value-total">
                                      <div class="select-form">
                                            <div class="btn-group">
                                                <select name="roleRegister" class="form-control form-control-sm"
                                                        [(ngModel)]="roleRegister">
                                                    <option value="STAFF">STAFF</option>
                                                </select>
                                            </div>
                                        </div>
                                </span></div>
                                                    <div class="col-2"><span
                                                            class="card-total-row"><b>Active: </b></span></div>
                                                    <div class="col-3">
                                                        <div class="select-form">
                                                            <div class="btn-group">
                                                                <select name="isActive"
                                                                        class="form-control form-control-sm"
                                                                        [(ngModel)]="isActive">
                                                                    <option value=true>Active</option>
                                                                    <option value=false>Inactive</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <br/>
                                                <div class="row">
                                                    <div class="col-2">
                                                        <button class="btn btn-lg btn-yellow font-weight-bold"
                                                                type="button"
                                                                (click)="registeration()" data-dismiss="modal">
                                                            Save
                                                        </button>
                                                    </div>

                                                    <div class="col-2">
                                                        <button class="btn btn-lg btn-yellow font-weight-bold"
                                                                type="button"
                                                                data-dismiss="modal">
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="isUpdate==true">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-2"><span
                                                            class="card-total-row"><b>Username: </b></span></div>
                                                    <div class="col-3"><span class="card-sub-value-total">
                                     <div class="mb-1">
                                          <div class="row">
                                               <div class="col-12">
                                       <input type="text" class="form-control" id="usernameStaff"
                                              placeholder="username"
                                              aria-describedby="remarkHelper"
                                              [(ngModel)]="usernameUpdate" name="usernameUpdate" readonly>
                                        <small id="remarkHelper" class="text-muted form-text" style=" font-size: .7rem">
                                           Enter Your Username
                                        </small>
                                               </div>

                                          </div>
                                    </div>
                                </span>
                                                    </div>

                                                    <div class="col-2"><span
                                                            class="card-total-row"><b>Password: </b></span></div>
                                                    <div class="col-3"><span class="card-sub-value-total">
                                     <div class="mb-1">
                                        <input type="text" class="form-control" id="passwordStaff"
                                               placeholder="password"
                                               aria-describedby="remarkHelper"
                                               [(ngModel)]="passwordUpdate" name="passwordUpadate">
                                        <small id="remarkHelper" class="text-muted form-text" style=" font-size: .7rem">
                                          Enter Your Password
                                        </small>
                                    </div>
                                </span></div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-2"><span
                                                            class="card-total-row"><b>Firstname : </b></span></div>
                                                    <div class="col-3"><span class="card-sub-value-total">
                                     <div class="mb-1">
                                        <input type="text" class="form-control" id="firstname"
                                               placeholder="firstname"
                                               aria-describedby="remarkHelper"
                                               [(ngModel)]="firstnameUpdate" name="firstnameUpdate">
                                    </div>
                                </span></div>
                                                    <div class="col-2"><span
                                                            class="card-total-row"><b>Lastname: </b></span></div>
                                                    <div class="col-3"><span class="card-sub-value-total">
                                     <div class="mb-1">
                                        <input type="text" class="form-control" id="lastname"
                                               placeholder="lastname"
                                               aria-describedby="remarkHelper"
                                               [(ngModel)]="lastnameUpdate" name="lastnameUpdate">
                                    </div>
                                </span></div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-2"><span
                                                            class="card-total-row"><b>Role: </b></span></div>
                                                    <div class="col-3"><span class="card-sub-value-total">
                                      <div class="select-form">
                                            <div class="btn-group">
                                                <select name="roleRegisterUpdate" class="form-control form-control-sm"
                                                        [(ngModel)]="roleRegisterUpdate">
                                                    <option value="STAFF">STAFF</option>
                                                </select>
                                            </div>
                                        </div>
                                </span></div>
                                                    <div class="col-2"><span
                                                            class="card-total-row"><b>Active: </b></span></div>
                                                    <div class="col-3">
                                                        <div class="select-form">
                                                            <div class="btn-group">
                                                                <select name="isActive"
                                                                        class="form-control form-control-sm"
                                                                        [(ngModel)]="isActiveUpdate">
                                                                    <option value=true>Active</option>
                                                                    <option value=false>Inactive</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br/>
                                                <br/>
                                                <div class="row">
                                                    <div class="col-2">
                                                        <button class="btn btn-lg btn-yellow font-weight-bold"
                                                                type="button"
                                                                (click)="update()" data-dismiss="modal">
                                                            Update
                                                        </button>
                                                    </div>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <div class="col-2">
                                                        <button class="btn btn-lg btn-yellow font-weight-bold"
                                                                type="button"
                                                                data-dismiss="modal">
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>

                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
</body>


