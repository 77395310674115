<body class="fixed-sn">
    <div class="wrapper container">
        <app-header></app-header>
        <main>
            <ng-container *ngIf="isActive === false; else elseBlock">
                <app-spinner>
                </app-spinner>
            </ng-container>
            <ng-template #elseBlock>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="'/report-portal'">{{ 'sidebar.payment' | translate }}</a></li>
                    <li class="breadcrumb-item"><a [routerLink]="">{{ 'sidebar.paymentTransaction' | translate }}</a></li>
                </ol>
            </nav>
            <h1>{{ 'sidebar.paymentTransaction' | translate }}</h1>
<!--            <div class="pb-3"></div>-->
            <!--        <div class="btn-toolbar mb-3" role="toolbar">-->

            <div class="btn-toolbar section-group" role="toolbar" style="padding-bottom: 0px !important;">
                <!--            <div class="form-row">-->
                <!--                <div class="form-group col-12">-->

                <div class="form-group col-md-12 row">

                    <label for="email" class="col-form-label font-weight-bold"
                        set-lan="text:Start date_">{{'common.paymentId' | translate }}</label>

                    <div class="input-group table-search col-2">
                        <input type="text" class="form-control customer-border" placeholder="Payment Id"
                            aria-label="Payment Id" aria-describedby="button-addon2" [(ngModel)]="paymentIdSearch"
                            name="paymentIdSearch">
                    </div>

                    <label for="email" class="col-form-label font-weight-bold"
                        set-lan="text:Start date_">{{'common.orderId' | translate }}</label>
                    <div class="input-group table-search col-2">
                        <input type="text" class="form-control customer-border" placeholder="Order Id"
                            aria-label="Order Id" aria-describedby="button-addon2" [(ngModel)]="orderIdSearch"
                            name="orderIdSearch">
                    </div>

                    <label for="email" class="col-form-label font-weight-bold"
                        set-lan="text:Start date_">{{'common.refId' | translate }}</label>

                    <div class="input-group table-search col-2">
                        <input type="text" class="form-control  customer-border" placeholder="refId" aria-label="refId"
                            aria-describedby="button-addon2" [(ngModel)]="refId" name="refId">
                    </div>
                    <ng-container *ngIf="role == 'ADMIN' || role == 'ADMIN_READ_ONLY'">
                        <label for="email" class="col-form-label font-weight-bold"
                            set-lan="text:Start date_">{{'common.merchant' | translate }}</label>
                        <div class="input-group table-search col-2">
                            <input type="text" class="form-control customer-border" placeholder="prefix"
                                aria-label="username" aria-describedby="button-addon2" [(ngModel)]="username"
                                name="username">
                        </div>
                    </ng-container>

                </div>

                <div class="form-group col-md-12 row btn-toolbaredit" style="padding-right: 6rem">
<!--                    <div class="form-group row" style="padding-left: 4.9rem;">-->
                        <label for="email" class="col-form-label font-weight-bold" set-lan="text:Start date_" >{{'common.startDate' | translate }}</label>
                        <div class="input-group table-search col-fix">
                            <input style="width: 150px;" type="date" id="startDate" class="form-control customer-border"
                                [ngModel]="defaultStartDate | date:'yyyy-MM-dd'" placeholder="Payment Id"
                                aria-label="Start Date" name="defaultStartDate"
                                (change)="setStartDate($event.target.value)">
                        </div>
                        <div class="col-fix" style="padding-left: 0.7rem;">
                            <input type="time" data-date="12:00 AM" class="testDate form-control customer-border"
                                [(ngModel)]="defaultStartTime" id="starttime" data-date-format="hh:mm A">
                        </div>
<!--                    </div>-->

<!--                    <div class="form-group row" style="padding-left: 0rem;">-->
                        <label for="email" class="col-form-label font-weight-bold" set-lan="text:To date_">{{'common.endDate' | translate }}</label>
                        <div class="input-group table-search col-fix">
                            <input type="date" id="endDate" style="width: 150px;" class="form-control customer-border"
                                aria-label="End Date" [ngModel]="defaultEndDate | date:'yyyy-MM-dd'"
                                (change)="setEndtDate($event.target.value)">
                        </div>
                        <div class="input-group table-search col-fix">
                            <input type="time" data-date="12:00 AM" class="testDate form-control customer-border"
                                [(ngModel)]="defaultEndTime" id="endtime" data-date-format="hh:mm A">
                        </div>
<!--                    </div>-->

                    <div class="row col-fix">
                        <button class="btn btn-yellow font-weight-bold m-0 px-3 py-2 z-depth-0 waves-effect btnMenu col-fix" style="margin-left: 10px !important;"
                            type="button" set-lan="text:Search" (click)="searchPayment(1)">Search</button>
                    </div>
                    <a (click)="ExportTOExcel()"  id="btnExport" set-lan="title:Export Excel" title="Export Excel"
                        style="display: initial; padding-left: 0.5rem;">
                        <img src="../../../../../assets/img/icons/Excel.png"
                            style="width: 37px; margin-left: .5rem; padding-top: 3px;"></a>
                    <label id="txtExport" set-lan="text:ExportNote"
                        style="font-size: 10pt; width: 185px; margin-left: 0.5rem; line-height: 1rem; display: initial;">Remark:
                        limit to 100 records per download</label>
                </div>
                <div class="form-group col-md-12 row btn-toolbaredit" style="padding-right: 61.2rem">
                    <label for="email" class="col-form-label font-weight-bold"
                        set-lan="text:Start date_">{{'common.service' | translate }}</label>
                    <div class="input-group table-search col-4">
                        <div class="select-form  customer-border" style="border-radius: 3px; margin-left: 0rem;">
                            <div class="btn-group">
                                <select name="status" style="color:#008F6B; padding-left: 0px;"
                                    class="form-control-lg-select btn btn-primary" [(ngModel)]="status">
                                    <option selected="selected" value="">All</option>
                                    <option value="SUCCESS">SUCCESS</option>
                                    <option value="DISPUTE">DISPUTE</option>
                                    <option value="REFUND">REFUND</option>
                                    <option value="WAITING_REFUND">WAITING_REFUND</option>
                                    <option value="WAITING">WAITING</option>
                                    <option value="FAIL">FAIL</option>
                                    <option value="PENDING">PENDING</option>
                                    <option value="PROBLEM">INCOMPLETE</option>
                                    <option value="REJECT">REJECT</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="form-group col-md-12 row">
                <div class="btn-group btn-group-green mr-3" data-toggle="buttons">
                    <label
                        class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                        (click)="btnTimePN('Previous');">
                        <input class="form-check-input" type="radio" name="options" id="option1" autocomplete="off">
                        <span set-lan="text:Previous">Previous</span>
                    </label>

                    <label
                        class=" active btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                        (click)="btnTime('Today');">
                        <input class="form-check-input" type="radio" name="options" id="option2" autocomplete="off">
                        <span set-lan="text:Today">Today</span>
                    </label>
                    <label
                        class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                        (click)="btnTime('Yesterday');">
                        <input class="form-check-input" type="radio" name="options" id="option3" autocomplete="off">
                        <span set-lan="text:Yesterday">Yesterday</span>
                    </label>
                    <label
                        class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                        (click)="btnTime('This week');">
                        <input class="form-check-input" type="radio" name="options" id="option4" autocomplete="off">
                        <span set-lan="text:This week">This week</span>
                    </label>
                    <label
                        class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                        (click)="btnTime('Last week');">
                        <input class="form-check-input" type="radio" name="options" id="option5" autocomplete="off">
                        <span set-lan="text:Last week">Last week</span>
                    </label>
                    <label
                        class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                        (click)="btnTime('This month');">
                        <input class="form-check-input" type="radio" name="options" id="option6" autocomplete="off">
                        <span set-lan="text:This month">This month</span>
                    </label>
                    <label
                        class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                        (click)="btnTime('Last month');">
                        <input class="form-check-input" type="radio" name="options" id="option7" autocomplete="off">
                        <span set-lan="text:Last month">Last month</span>
                    </label>
                    <label
                        class="btn btn-white border border-success z-depth-0 form-check-label waves-effect waves-light"
                        (click)="btnTimePN('Next');">
                        <input class="form-check-input" type="radio" name="options" id="option8" autocomplete="off">
                        <span set-lan="text:Next">Next</span>
                    </label>
                </div>
            </div>

            <!--        <div class="form-row">-->
            <!--            <div class="form-group col-12">-->
            <!--                <div class="form-group row">-->
            <!--                    <div class="input-group table-search col-6">-->
            <!--                        <input type="date" class="form-control" [ngModel]="defaultStartDate"-->
            <!--                               placeholder="Payment Id" aria-label="Start Date"-->
            <!--                               name="defaultStartDate" (change)="setStartDate($event.target.value)">-->
            <!--                    </div>-->
            <!--                    <div class="input-group table-search col-6">-->
            <!--                        <input type="date" class="form-control" [ngModel]="defaultEndDate"-->
            <!--                               placeholder="Order Id" aria-label="End Date"-->
            <!--                               name="defaultEndDate" (change)="setEndtDate($event.target.value)">-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <div class="form-group row">-->
            <!--                    <div class="input-group table-search col-2">-->
            <!--                        <input type="text" class="form-control" placeholder="Payment Id" aria-label="Payment Id"-->
            <!--                               aria-describedby="button-addon2" [(ngModel)]="paymentIdSearch" name="paymentIdSearch">-->
            <!--                    </div>-->

            <!--                            <div class="input-group table-search col-2">-->
            <!--                                <input type="text" class="form-control" placeholder="Order Id" aria-label="Order Id"-->
            <!--                                       aria-describedby="button-addon2" [(ngModel)]="orderIdSearch" name="orderIdSearch">-->
            <!--                            </div>-->
            <!--                            <div class="input-group table-search col-2">-->
            <!--                                <input type="text" class="form-control" placeholder="refId" aria-label="refId"-->
            <!--                                       aria-describedby="button-addon2" [(ngModel)]="refId" name="refId">-->
            <!--                            </div>-->
            <!--                            <div class="input-group table-search col-2">-->
            <!--                                <input type="text" class="form-control" placeholder="refId" aria-label="username"-->
            <!--                                       aria-describedby="button-addon2" [(ngModel)]="username" name="username">-->
            <!--                            </div>-->
            <!--                    <div class="input-group table-search col-3">-->
            <!--                                <div class="select-form">-->
            <!--                                    <div class="btn-group">-->
            <!--                                        <select name="status" class="form-control-lg-select btn btn-primary"-->
            <!--                                                [(ngModel)]="status">-->
            <!--                                            <option selected="selected" value="">All</option>-->
            <!--                                            <option value="SUCCESS">SUCCESS</option>-->
            <!--                                            <option value="DISPUTE">DISPUTE</option>-->
            <!--                                            <option value="REFUND">REFUND</option>-->
            <!--                                            <option value="WAITING_REFUND">WAITING_REFUND</option>-->
            <!--                                            <option value="WAITING">WAITING</option>-->
            <!--                                            <option value="FAIL">FAIL</option>-->
            <!--                                            <option value="PENDING">PENDING</option>-->
            <!--                                            <option value="PROBLEM">PROBLEM</option>-->
            <!--                                            <option value="REJECT">REJECT</option>-->
            <!--                                        </select>-->
            <!--                                    </div>-->
            <!--                                </div>-->
            <!--                    </div>-->

            <!--                    <div class="input-group table-search col-3">-->
            <!--                        <div class="select-form">-->
            <!--                            <div class="btn-group">-->
            <!--                                <select name="service_id" class="form-control-lg-select btn btn-primary"-->
            <!--                                        [(ngModel)]="service_id">-->
            <!--                                    <option value="">-->
            <!--                                        ALL-->
            <!--                                    </option>-->
            <!--                                    <option *ngFor="let service of serviceCategory.result" [value]=service.id>-->
            <!--                                        {{service.name}}-->
            <!--                                    </option>-->
            <!--                                </select>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--                <button class="btn btn-lg btn-yellow font-weight-bold" type="button" (click)="searchPayment(1)">Search-->
            <!--                </button>-->
            <!--                &nbsp; &nbsp; &nbsp;-->
            <!--                <button class="btn btn-lg btn-yellow font-weight-bold" type="button" (click)="ExportTOExcel()">Export-->
            <!--                </button>-->
            <!--            </div>-->
            <!--        </div>-->
            <!--        </div>-->
            <div class="table-wrapper">
                <nav aria-label="Page navigation example">
                    <ul class="pagination" style="float: right;">
                        <li class="page-item">
                            <a class="page-link" (click)="selectFirstPage( )">First</a>
                        </li>
                        <ng-container *ngIf="startPage + maxPageLine < pageNumber">
                            <li class="page-item">
                                <a class="page-link" (click)="setNewPageSet(maxPageLine)"> Next </a>
                            </li>
                        </ng-container>
                        <ng-container *ngIf="pageNumber > maxPageLine; else less_than_20">
                            <li class="page-item" *ngFor="let currentPage of counter(maxPageLine); let i = index">
                                <a class="page-link" (click)="searchPayment(startPage + i)">{{startPage + i}}</a>
                            </li>
                        </ng-container>
                        <ng-template #less_than_20>
                            <li class="page-item" *ngFor="let currentPage of counter(pageNumber); let i = index">
                                <a class="page-link" (click)="searchPayment(startPage + i)">{{startPage + i}}</a>
                            </li>
                        </ng-template>
                        <li class="page-item">
                            <ng-container *ngIf="startPage > 1">
                                <a class="page-link" (click)="setNewPageSet(-maxPageLine)"> Back</a>
                            </ng-container>
                        </li>
                        <li class="page-item">
                            <a class="page-link" (click)="selectLastPage()">Last</a>
                        </li>
                    </ul>
                </nav>
                <div class="table-wrapper" style="margin-top: 10px; margin-bottom: 10px; border-radius: 5px;">
                    <table class="table table-border" datatables="ng" dt-options="dtOptions">
                        <thead class="rgba-green-slight">
                            <tr>
                                <th style=" display:table-cell;width: 10%; text-align: right; padding-right: 5px;"
                                    class="text-nowrap"><span class="">Date & Time</span></th>
                                <th style="width: 11%; " class="">Payment Id</th>
                                <th style="width: 11%; text-align: right; padding-right: 5px;"><span class="">Order
                                        Id</span></th>
                                <th style="width: 11%; text-align: right; padding-right: 5px;"><span class="">Ref
                                        Id</span></th>
                                <ng-container
                                    *ngIf="currentPosition === 'ADMIN'|| this.currentPosition === 'ADMIN_READ_ONLY'">
                                    <th style="width: 7%; text-align: right; padding-right: 5px;"><span
                                            class="">Merchant</span></th>
                                </ng-container>
                                <th style="width: 7%; text-align: right; padding-right: 5px;"><span class="">Fee</span>
                                </th>
                                <th style="width: 7%; text-align: right; padding-right: 5px;"><span
                                        class="">Total</span></th>
                                <th style="width: 7%; text-align: right; padding-right: 5px;"><span
                                        class="">Payment</span></th>
                                <th style="width: 8%; text-align: right; padding-right: 5px;"><span class="">Type</span>
                                </th>
                                <th style="width: 7%; text-align: right; padding-right: 5px;"><span
                                        class="">Status</span></th>

                                <th style="width: 7%; text-align: right; padding-right: 5px;">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr class="hide">
                                <td></td>
                            </tr>
                            <tr *ngFor="let index of responseTransaction?.result.transactions;let i=index">
                                <td class="text-left">
                                    {{index.update_date |  date:'d/M/yy, h:mm a'}}
                                </td>
                                <td class="text-right">{{index.payment_id}}</td>
                                <td class="text-right">{{index.order_id}}</td>
                                <td class="text-right">{{index.ref_id}}</td>
                                <ng-container
                                    *ngIf="currentPosition === 'ADMIN'|| this.currentPosition === 'ADMIN_READ_ONLY'">
                                    <td class="text-right">{{index.username}}</td>
                                </ng-container>
                                <td class="text-right">{{index.merchant_fee | number:'1.2-2'}}</td>
                                <td class="text-right">{{index.customer_actual_amount | number:'1.2-2'}}</td>
                                <td class="text-right">
                                    {{index.detail}}
                                </td>
                                <td class="text-right">{{index.payment_type}}</td>
                                <td class="text-center">
                                    <ng-container *ngIf="index.status === 'PROBLEM'; else other">
                                        INCOMPLETE
                                    </ng-container>
                                    <ng-template #other>
                                        {{index.status}}
                                    </ng-template>
                                </td>

                                <td class="text-center">
                                    <a class="link" data-toggle="modal" data-target="#modal-transaction"
                                        (click)="modal(index.payment_id)">View</a>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot class="rgba-yellw-slight">
                            <tr style="background-color: rgb(255, 244, 149);" class="total">
                                <td colspan="4" class="total-cell text-center">Total</td>
                                <td class="text-right">{{sumFee | number:'1.2-2'}}</td>
                                <td class="text-right">{{sumActualAmount | number:'1.2-2'}}</td>
                                <ng-container
                                    *ngIf="currentPosition === 'ADMIN'|| this.currentPosition === 'ADMIN_READ_ONLY'">
                                    <td class="text-right">
                                    </td>
                                </ng-container>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

            <div class="modal fade" id="modal-transaction">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <!--                        <h4 class="modal-title">Payment information</h4>-->
                            <br />
                        </div>
                        <div class="modal-body">
                            <form class="form-horizontal">
                                <div class="form-group row">
                                    <div class="col-6">
                                        <fieldset>
                                            <legend style="color:blue;font-weight:bold;">Payment Detail</legend>
                                            <table class="table table-bordered sortTable" datatables="ng"
                                                dt-options="dtOptions">
                                                <tbody class="table-striped">
                                                    <tr>
                                                        <td><b>Merchant</b></td>
                                                        <td>{{current_transaction.username}}</td>

                                                    </tr>
                                                    <tr>
                                                        <td><b>Payment Id</b></td>
                                                        <td>{{current_transaction.payment_id}}</td>

                                                    </tr>
                                                    <tr>
                                                        <td><b>Order Id</b></td>
                                                        <td>{{current_transaction.order_id}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Ref Id</b></td>
                                                        <td>{{current_transaction.ref_id}}</td>

                                                    </tr>
                                                    <tr>
                                                        <td><b>Amount</b></td>
                                                        <td>{{current_transaction.amount}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Fee</b></td>
                                                        <td>{{current_transaction.merchant_fee}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Net Amount</b></td>
                                                        <td>{{current_transaction.customer_actual_amount}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Status</b></td>
                                                        <td>
                                                            <ng-container *ngIf="current_transaction.status === 'PROBLEM'; else otherMoodalDetail">
                                                                INCOMPLETE
                                                            </ng-container>
                                                            <ng-template #otherMoodalDetail>
                                                                {{current_transaction.status}}
                                                            </ng-template>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Account No.</b></td>
                                                        <td>{{current_transaction.payer_account_no}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Account Name.</b></td>
                                                        <td>{{current_transaction.payer_account_name}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Paid with Account No.</b></td>
                                                        <td>{{current_transaction.payer_account_no_dest}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Paid with Account Name.</b></td>
                                                        <td>{{current_transaction.payer_account_name_dest}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><b>Payer username.</b></td>
                                                        <td>{{current_transaction.payer_username}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </fieldset>
                                    </div>

                                    <div class="col-6">
                                        <fieldset>
                                            <legend style="color:blue;font-weight:bold;">Payment History</legend>
                                            <table class="table table-bordered sortTable" datatables="ng"
                                                dt-options="dtOptions">
                                                <thead>
                                                    <tr>
                                                        <th style="text-align: left;"><span
                                                                class="col-cell">Status</span></th>
                                                        <th style="text-align: left;"><span class="col-cell">Date &
                                                                Time</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody class="table-striped">
                                                    <tr
                                                        *ngFor="let index of current_transaction.status_history;let i= index">
                                                        <td>
                                                            <b>
                                                                <ng-container *ngIf="index.status === 'PROBLEM'; else otherMoodal">
                                                                    INCOMPLETE
                                                                </ng-container>
                                                                <ng-template #otherMoodal>
                                                                    {{index.status}}
                                                                </ng-template>
                                                            </b>
                                                        </td>
                                                        <td>{{index.date | date:'d/M/yy, h:mm a'}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </fieldset>
                                    </div>
                                </div>

                                <!--                            <br/>-->
                                <div class="form-group row">
                                    <div class="col-12">
                                        <div
                                            *ngIf="currentPosition == 'ADMIN'|| this.currentPosition === 'ADMIN_READ_ONLY'">
                                            <div class="select-form">
                                                <div class="btn-group">
                                                    <div *ngIf="current_transaction.status != 'SUCCESS'">
                                                        <select name="status_update"
                                                            class="form-control-lg-select btn btn-primary"
                                                            [(ngModel)]="status_update">
                                                            <!--                                                    <ng-container *ngIf="status_update != 'SUCCESS' && status_update != 'DISPUTE'" >-->
                                                            <option value="SUCCESS">SUCCESS</option>
                                                            <option value="DISPUTE">DISPUTE</option>
                                                            <!--                                                    </ng-container>-->
                                                            <option value="WAITING_REFUND">WAITING_REFUND</option>
                                                            <option value="WAITING">WAITING</option>
                                                            <option value="FAIL">FAIL</option>
                                                            <option value="PROBLEM">INCOMPLATE</option>
                                                            <option value="REFUND">REFUND</option>
                                                            <option value="PENDING">PENDING</option>
                                                            <option value="REJECT">REJECT</option>

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div *ngIf="current_transaction.status == 'DISPUTE'">
                                        <div class="col-3">
                                            <button type="button" class="btn btn-default" (click)="callback()"
                                                data-dismiss="modal">
                                                Callback
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="currentPosition == 'ADMIN' || this.currentPosition === 'ADMIN_READ_ONLY'">

                                        <div class="col-3">
                                            <button type="button" class="btn btn-default" data-dismiss="modal"
                                                (click)="updatestatus()">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button type="button" class="btn btn-default" data-dismiss="modal">
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </ng-template>
        </main>
    </div>
</body>
