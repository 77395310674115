<div id="slide-out" class="side-nav white fixed wide side-nav-light z-depth-0">
  <ul class="custom-scrollbar_">
    <!-- Side navigation links -->
    <li>
      <ng-container *ngIf="role=='ADMIN'" >
      <ul class="accordion collapsible collapsible-accordion">
        <li>
          <a class="dashboard collapsible-header waves-effect"  [routerLink]="'/dashboard'"><i class="sv-slim-icon fas fa-dashboard"></i>
            <b>{{'sidebar.dashboard' | translate }}</b></a>
        </li>
        <li>
        <a class="payment-portal collapsible-header waves-effect arrow-r" (click)="showPaymentMenu()">
          <i class="sv-slim-icon fas fa-history"></i>
          <b>{{ 'sidebar.payment' | translate }}</b>
          <i class="fas fa-angle-down rotate-icon"></i>
        </a>
        <div class="collapsible-body" id="paymentList" style="display: none;">
          <ul>
            <li><a class="report-payment waves-effect" [routerLink]="'/report-payment'">
                <span class="sv-normal">
                  <b>{{ 'sidebar.paymentTransaction' | translate }}</b></span></a>
            </li>
            <li><a class="report-payout waves-effect" [routerLink]="'/report-payout'">
                <span class="sv-normal">
                  <b>{{ 'sidebar.payoutTransaction' | translate }}</b>
                </span></a>
            </li>
          </ul>
        </div>
      </li>
        <li>
          <a class="payment-portal collapsible-header waves-effect arrow-r" (click)="showReportMenu()">
            <i class="sv-slim-icon fa fa-file"></i>

            <b>{{ 'sidebar.report' | translate }}</b>
            <i class="fas fa-angle-down rotate-icon"></i>
          </a>
          <div class="collapsible-body" id="reportList" style="display: none;">
            <ul>
              <li>
                <a class="report-payment waves-effect" [routerLink]="'/forecast-report-profit'">
                <span class="sv-normal">
                  <b>{{ 'sidebar.forecast' | translate }}</b></span></a>
              </li>
              <li>
                <a class="payment-portal collapsible-header waves-effect arrow-r" [routerLink]="'/report-profit'">
                  <b>{{ 'report.report' | translate }}</b>
                </a>
              </li>
            </ul>
          </div>
        </li>

        <li>
          <a class="account-portal collapsible-header waves-effect arrow-r" (click)="showMemberMenu()">
            <i class="sv-slim-icon fas fa-member"></i>
            <b>{{ 'sidebar.account' | translate }}</b>
            <i class="fas fa-angle-down rotate-icon"></i>
          </a>
          <div class="collapsible-body" id="showMenuList" style="display: none;">
            <ul>
              <li><a class="report-payout waves-effect"  [routerLink]="'/personal'">
                <span class="sv-normal">
                  <b>{{ 'sidebar.profile' | translate }}</b>
                </span></a>
              </li>
              <li><a class="report-payment waves-effect" [routerLink]="'/change-password'">
                <span class="sv-normal">
                  <b> {{ 'sidebar.password' | translate }}</b></span></a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <a class="setting-portal collapsible-header waves-effect arrow-r" id="payment" (click)="showSettingMenu()">
            <i class="sv-slim-icon fas fa-gear"></i>
            <b>{{ 'sidebar.setting' | translate }}</b>
            <i class="fas fa-angle-down rotate-icon"></i>
          </a>
          <div class="collapsible-body" id="settingList" style="display: none;">
            <ul>
              <li><a class="merchant-setting waves-effect" [routerLink]="'/merchant-setting'" style="font-weight: 400">
                <span class="sv-normal">
                  <b>{{ 'sidebar.listMerchantAccountSetting' | translate }}</b>
                </span></a>
              </li>
              <li>
                <a class="dashboard collapsible-header waves-effect"  [routerLink]="'/register'" style="font-weight: 400; font-size: 12.8px ">
                  <b>{{ 'sidebar.merchantAccountSetting' | translate }}</b>
                </a>
              </li>
              <li><a class="payment-transfer-history waves-effect" [routerLink]="'/bank-setting'">
                <span class="sv-normal">
                  <b> {{ 'sidebar.bankSetting' | translate }}</b>
                </span></a>
              </li>
            </ul>
          </div>
        </li>

<!--        <li>-->
<!--          <a class="dashboard collapsible-header waves-effect" (click)="onLogut()"><i class="sv-slim-icon fas fa-sign-out-alt"></i>-->
<!--            <b>   {{'sidebar.logout' | translate }}</b></a>-->
<!--        </li>-->
        <li><a id="toggle" class="waves-effect"><i class="sv-slim-icon fas fa-angle-double-left"></i></a>
        </li>
      </ul>
      </ng-container>

      <ng-container *ngIf="role=='ADMIN_READ_ONLY'" >
        <ul class="accordion collapsible collapsible-accordion">
          <li>
            <a class="dashboard collapsible-header waves-effect"  [routerLink]="'/dashboard'"><i class="sv-slim-icon fas fa-dashboard"></i>
              <b>{{'sidebar.dashboard' | translate }}</b></a>
          </li>
          <li>
            <a class="payment-portal collapsible-header waves-effect arrow-r" (click)="showPaymentMenu()">
              <i class="sv-slim-icon fas fa-history"></i>
              <b>{{ 'sidebar.payment' | translate }}</b>
              <i class="fas fa-angle-down rotate-icon"></i>
            </a>
            <div class="collapsible-body" id="paymentList" style="display: none;">
              <ul>
                <li><a class="report-payment waves-effect" [routerLink]="'/report-payment'">
                <span class="sv-normal">
                  <b>{{ 'sidebar.paymentTransaction' | translate }}</b></span></a>
                </li>
                <li><a class="report-payout waves-effect" [routerLink]="'/report-payout'">
                <span class="sv-normal">
                  <b>{{ 'sidebar.payoutTransaction' | translate }}</b>
                </span></a>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <a class="account-portal collapsible-header waves-effect arrow-r" (click)="showMemberMenu()">
              <i class="sv-slim-icon fas fa-member"></i>
              <b> {{ 'sidebar.account' | translate }}</b>
              <i class="fas fa-angle-down rotate-icon"></i>
            </a>
            <div class="collapsible-body" id="showMenuList" style="display: none;">
              <ul>
                <li><a class="report-payout waves-effect"  [routerLink]="'/personal'">
                <span class="sv-normal">
                  <b>{{ 'sidebar.profile' | translate }}</b>
                </span></a>
                </li>
                <li><a class="report-payment waves-effect" [routerLink]="'/change-password'">
                <span class="sv-normal">
                  <b> {{ 'sidebar.password' | translate }}</b></span></a>
                </li>

              </ul>
            </div>
          </li>
<!--          <li>-->
<!--            <a class="dashboard collapsible-header waves-effect" (click)="onLogut()"><i class="sv-slim-icon fas fa-sign-out-alt"></i>-->
<!--              <b>   {{'sidebar.logout' | translate }}</b></a>-->
<!--          </li>-->
          <li><a id="toggle" class="waves-effect"><i class="sv-slim-icon fas fa-angle-double-left"></i></a>
          </li>
        </ul>
      </ng-container>

      <ng-container *ngIf="role=='MERCHANT'" >
        <ul class="accordion collapsible collapsible-accordion">
          <li>
            <a class="dashboard collapsible-header waves-effect"  [routerLink]="'/dashboard'"><i class="sv-slim-icon fas fa-dashboard"></i>
              <b> {{'sidebar.dashboard' | translate }}</b></a>
          </li>
          <li>
            <a class="payout collapsible-header waves-effect"  [routerLink]="'/payout'"><i class="sv-slim-icon fas fa-money"></i>
              <b> {{'sidebar.payout' | translate }}</b></a>
          </li>
          <li>
            <a class="payment-portal collapsible-header waves-effect arrow-r" (click)="showPaymentMenu()">
              <i class="sv-slim-icon fas fa-history"></i>
              <b>{{ 'sidebar.payment' | translate }}</b>
              <i class="fas fa-angle-down rotate-icon"></i>
            </a>
            <div class="collapsible-body" id="paymentList" style="display: none;">
              <ul>
                <li><a class="report-payment waves-effect" [routerLink]="'/report-payment'">
                <span class="sv-normal">
                  <b>{{ 'sidebar.paymentTransaction' | translate }}</b></span></a>
                </li>
                <li><a class="report-payout waves-effect" [routerLink]="'/report-payout'">
                <span class="sv-normal">
                  <b>{{ 'sidebar.payoutTransaction' | translate }}</b>
                </span></a>
                </li>
              </ul>
            </div>
          </li>

          <li>
            <a class="account-portal collapsible-header waves-effect arrow-r" (click)="showMemberMenu()">
              <i class="sv-slim-icon fas fa-member"></i>
              <b> {{ 'sidebar.account' | translate }}</b>
              <i class="fas fa-angle-down rotate-icon"></i>
            </a>
            <div class="collapsible-body" id="showMenuList" style="display: none;">
              <ul>
                <li><a class="report-payout waves-effect"  [routerLink]="'/personal'">
                <span class="sv-normal">
                  <b>{{ 'sidebar.profile' | translate }}</b>
                </span></a>
                </li>
                <li><a class="report-payment waves-effect" [routerLink]="'/change-password'">
                <span class="sv-normal">
                  <b> {{ 'sidebar.password' | translate }}</b></span></a>
                </li>

              </ul>
            </div>
          </li>

          <li>
            <a class="account-portal collapsible-header waves-effect arrow-r" (click)="showManagementMenu()">
              <i class="far fa-building"></i>
              <b> {{ 'sidebar.management' | translate }}</b>
              <i class="fas fa-angle-down rotate-icon"></i>
            </a>
            <div class="collapsible-body" id="showManagementList" style="display: none;">
              <ul>
                <li>
                  <a class="dashboard collapsible-header waves-effect"  [routerLink]="'/register'">
                    <b>{{ 'sidebar.subAccountSetting' | translate }}</b>
                  </a>
                </li>
                <li><a class="report-payout waves-effect"  [routerLink]="'/merchant-setting'">
                <span class="sv-normal">
                  <b>{{ 'sidebar.listSubAccountSetting' | translate }}</b>
                </span></a>
                </li>

              </ul>
            </div>
          </li>
          <li>
            <a class="setting collapsible-header waves-effect"  [routerLink]="'/callback-and-key-setting'"><i class="sv-slim-icon fas fa-gear"></i>
              <b>{{'sidebar.setting' | translate }}</b></a>
          </li>
<!--          <li>-->
<!--            <a class="dashboard collapsible-header waves-effect" (click)="onLogut()"><i class="sv-slim-icon fas fa-sign-out-alt"></i>-->
<!--              <b>   {{'sidebar.logout' | translate }}</b></a>-->
<!--          </li>-->
          <li><a id="toggle" class="waves-effect"><i class="sv-slim-icon fas fa-angle-double-left"></i></a>
          </li>
        </ul>
      </ng-container>
      <ng-container *ngIf="role=='STAFF'" >
        <ul class="accordion collapsible collapsible-accordion">
          <li>
            <a class="dashboard collapsible-header waves-effect"  [routerLink]="'/dashboard'"><i class="sv-slim-icon fas fa-dashboard"></i>
              <b>   {{'sidebar.dashboard' | translate }}</b></a>
          </li>
          <li>
            <a class="payment-portal collapsible-header waves-effect arrow-r" (click)="showPaymentMenu()">
              <i class="sv-slim-icon fas fa-history"></i>
              <b>{{ 'sidebar.payment' | translate }}</b>
              <i class="fas fa-angle-down rotate-icon"></i>
            </a>
            <div class="collapsible-body" id="paymentList" style="display: none;">
              <ul>
                <li><a class="report-payment waves-effect" [routerLink]="'/report-payment'">
                <span class="sv-normal">
                  <b>{{ 'sidebar.paymentTransaction' | translate }}</b></span></a>
                </li>
                <li><a class="report-payout waves-effect" [routerLink]="'/report-payout'">
                <span class="sv-normal">
                  <b>{{ 'sidebar.payoutTransaction' | translate }}</b>
                </span></a>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <a class="account-portal collapsible-header waves-effect arrow-r" (click)="showMemberMenu()">
              <i class="sv-slim-icon fas fa-member"></i>
              <b> {{ 'sidebar.account' | translate }}</b>
              <i class="fas fa-angle-down rotate-icon"></i>
            </a>
            <div class="collapsible-body" id="showMenuList" style="display: none;">
              <ul>
                <li><a class="report-payout waves-effect"  [routerLink]="'/personal'">
                <span class="sv-normal">
                  <b>{{ 'sidebar.profile' | translate }}</b>
                </span></a>
                </li>
                <li><a class="report-payment waves-effect" [routerLink]="'/change-password'">
                <span class="sv-normal">
                  <b> {{ 'sidebar.password' | translate }}</b></span></a>
                </li>
              </ul>
            </div>
          </li>
<!--          <li>-->
<!--            <a class="dashboard collapsible-header waves-effect" (click)="onLogut()"><i class="sv-slim-icon fas fa-sign-out-alt"></i>-->
<!--              <b>   {{'sidebar.logout' | translate }}</b></a>-->
<!--          </li>-->
          <li><a id="toggle" class="waves-effect"><i class="sv-slim-icon fas fa-angle-double-left"></i></a>
          </li>
        </ul>
      </ng-container>
    </li>
    <!--/. Side navigation links -->
  </ul>
  <div class="sidenav-bg rgba-white-strong"></div>
</div>



