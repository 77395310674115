<body class="fixed-sn">
<div class="wrapper container">
    <app-header></app-header>
    <main>
        <h1>Callback & Key Setting</h1>

        <div class="tab-content section-group">
            <div id="a-md" role="tabpanel" aria-labelledby="a-tab-md">
                <div class="form-row">
                    <div class="form-group col-6">
                        <div class="form-group row">
                            <div class="col-4 account-sub-title">Callback url (check transaction)</div>
                            <div class="col-8 account-sub-value">
                                <input type="text" class="form-control" id="checkUrl"
                                       placeholder="Enter Url"
                                       aria-describedby="remarkHelper"
                                       [(ngModel)]="checkUrl" name="checkUrl">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-4 account-sub-title">Callback url (payment)</div>
                            <div class="col-8 account-sub-value">
                                <input type="text" class="form-control" id="payUrl"
                                       placeholder="Enter Url"
                                       aria-describedby="remarkHelper"
                                       [(ngModel)]="payUrl" name="payUrl">
                            </div>
                        </div>
                        <button class="btn btn-lg btn-yellow font-weight-bold" type="button"
                                (click)="saveCallback()">Save
                        </button>
                    </div>
                    <div class="form-group col-1">
                    </div>
                    <div class="form-group col-5">
                        <div class="form-group row">
                            <div class="col-3 account-sub-title">Secret Key</div>
                            <div class="col-9 account-sub-value">
                                <input type="text" class="form-control" id="secretKey"
                                       placeholder="secret key"
                                       aria-describedby="remarkHelper"
                                       [(ngModel)]="secretKey" name="secretKey" readonly>
                            </div>
                        </div>
                        <div class="form-group row">
                            <button class="btn btn-lg btn-yellow font-weight-bold" type="button"
                                    (click)="generateKey()">Generate Key
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </main>
</div>
<!-- Modal -->
<div class="modal fade" id="modal-main" role="dialog" tabindex="-1" aria-hidden="true"></div>

</body>
