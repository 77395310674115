import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../../service/api.service';
import {ModalDirective} from 'angular-bootstrap-md';
import {ActivatedRoute, Router} from '@angular/router';
import {AppComponent} from '../../../../app.component';
import {TranslateService} from '@ngx-translate/core';

declare let jQuery: any;

@Component({
    selector: 'app-announcement',
    templateUrl: './personal.component.html'
})
export class PersonalComponent extends AppComponent implements OnInit {

    @ViewChild('childModal') childModal: ModalDirective;

    public myPosition = localStorage.getItem('role');
    public username = localStorage.getItem('username');
    public phoneNo = localStorage.getItem('phoneNo');
    public wallet = localStorage.getItem('wallet');
    public secretKey: any;


    public checkUrl: any;
    public payUrl: any;
    public oldPassword: any;
    public newPassword: any;
    public repeatPassword: any;
    public lottoType: any;
    public tab: any;
    public userProfile: any;
    public type: number = 0;
    public h: number = 0;
    public m: number = 0;
    public id: any = localStorage.getItem('userId');


    constructor(
        private apiService: ApiService,
        private _formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        public translate: TranslateService
    ) {
        super(translate);
    }

    async ngOnInit(): Promise<any> {
        jQuery('.limit-setting').addClass('active');

        jQuery('#settingList').slideDown(300, function() {
            jQuery('#settingList').show();
        });

        this.route.queryParams.subscribe(params => {
            this.tab = params['tab'];
            if (params['id']) {
                this.id = params['id'];
            }
            if (this.tab === undefined) {
                this.tab = 'basicInfo';
            }
        });
        if (this.tab === 'basicInfo') {
            jQuery('.basicInfo').addClass('active');
            jQuery('.basicInfoTab').addClass('show');
            jQuery('.basicInfoTab').addClass('active');
        }
        if (this.tab === 'balance') {
            jQuery('.balance').addClass('active');
            jQuery('.balanceTab').addClass('show');
            jQuery('.balanceTab').addClass('active');
        }
        if (this.tab === 'callback') {
            jQuery('.callback').addClass('active');
            jQuery('.callbackTab').addClass('show');
            jQuery('.callbackTab').addClass('active');
        }
        if (this.tab === 'security') {
            jQuery('.security').addClass('active');
            jQuery('.securityTab').addClass('show');
            jQuery('.securityTab').addClass('active');
        }
        if (this.tab === 'creditSetting') {
            jQuery('.creditSetting').addClass('active');
            jQuery('.creditSettingTab').addClass('show');
            jQuery('.creditSettingTab').addClass('active');
        }
        if (this.tab === 'productSetting') {
            jQuery('.productSetting').addClass('active');
            jQuery('.productSettingTab').addClass('show');
            jQuery('.productSettingTab').addClass('active');
        }
        this.userProfile = await this.apiService.getUserProfile();
        this.secretKey = this.userProfile.result.secretKey;
        this.checkUrl = this.userProfile.result.callbackUrlCheck;
        this.payUrl = this.userProfile.result.callbackUrlPay;
    }

    async changePassword() {
        if (this.newPassword !== this.repeatPassword) {
            alert('New Password and Repeat Password must be the same !!!');
        }
        if (confirm('Do you want to change your password?')) {
            let request = {
                password: this.oldPassword,
                new_password: this.newPassword,
            };
            let response;
            if (this.role == 'ADMIN' || this.role == 'ADMIN_READ_ONLY') {
                response = await this.apiService.adminChangePassword(request);
            } else {
                response = await this.apiService.changePassword(request);
            }
            if (response.code === 0) {
                this.router.navigate(['/login']);
            }

        }
    }

    async saveCallback() {
        if (confirm('ต้องการบันทึกข้อมูล ใช่ หรือ ไม่?')) {
            let request = {
                callback_check_url: this.checkUrl,
                callback_pay_url: this.payUrl
            };
            let response = await this.apiService.saveCallback(request);
            if (response.code == 0) {
                alert('ทำรายการสำเร็จ');
            }
        }
    }

    async saveBankAccount() {
        if (this.userProfile.result.bankName == null || this.userProfile.result.accountFirstname == null || this.userProfile.result.accountLastname == null || this.userProfile.result.accountNumber == null) {
            alert('กรุณากรอบแบบฟอร์มให้ครบถ้วน');
        } else {
            if (confirm('ต้องการบันทึกข้อมูล ใช่ หรือ ไม่? / Do you want to update?')) {
                let request = {
                    bank_name: this.userProfile.result.bankName,
                    firstname: this.userProfile.result.accountFirstname,
                    lastname: this.userProfile.result.accountLastname,
                    account_number: this.userProfile.result.accountNumber
                };
                let response = await this.apiService.saveCallback(request);
                if (response.code == 0) {
                    alert('ทำรายการสำเร็จ / Success');
                }
            }
        }
    }

    async generateKey() {
        if (confirm('Do you want to change your secret key?')) {
            let response = await this.apiService.generateSecretKey();
            if (response.code === 0) {
                this.secretKey = response.result.uu_id;
            }
        }
    }

    async payOut(type) {
        let request = {
            remark: 'ลูกค้าถอน',
            type: type
        };
        alert(type);
        if (confirm('Do you want to payout?')) {
            let response = await this.apiService.payout(request);
        }
        this.userProfile = await this.apiService.getUserProfile();
    }

}

