<div class="login-page">
  <div class="wrapper container h-100 d-flex justify-content-center align-items-center">
    <div class="login-section">
      <div class="card">
        <div class="login-footer pt-4"
          style="padding-top: 1rem !important; border-bottom: 1px #fff solid; padding-bottom: .5rem !important;">
           <br>
          <ul class="nav nav-pills languages" role="tablist">
            <li class="nav-item">
              <a  href="" class="nav-link tagA" data-toggle="tab">ไทย</a>
            </li>
            <li class="nav-item">
              <a href="" class="nav-link tagA active" data-toggle="tab">Eng</a>
            </li>
          </ul>
<!--          <a href="#" class="nav-link" set-lan="html:Mobile Version" style="color: #fff;"><i-->
<!--              class="fas fa-mobile"></i>&nbsp;Mobile Version</a>-->
        </div>

        <div class="card-header text-center">
          <a href="" class="logo mx-auto pb-4 mb-2">
            <img src="../../../assets/img/logo.svg" alt="logo">
          </a>
          <div class="card-header-text">Agent Management Website</div>
        </div>
        <form name="loginForm" [formGroup]="loginForm" (ngSubmit)="onLogin(loginForm.value)">

          <div class="card-body">
            <div class="card-body-holder">
              <div class="form-group row mb-4">
                <div class="col-12">
                  <div class="md-form md-outline">
                    <input id="username" formControlName="username" name="username" type="text"
                      class="form-control form-control-lg mb-0" autocomplete="off" placeholder="Username" required autofocus>
<!--                    <label for="username" class="label">Username</label>-->
                  </div>
                </div>
              </div>
              <div class="form-group row mb-4">
                <div class="col-12">
                  <div class="md-form md-outline">
                    <input formControlName="password" name="password" id="password" type="password"
                      class="form-control form-control-lg mb-0" autocomplete="off" placeholder="Password" required/>
<!--                    <label for="password" class="label">Password</label>-->
                    <span toggle="#password" class="toggle-password field-icon">
                      <i class="fa "
                         [class]="{'fa-low-vision': !fieldTextType, 'fa-eye': fieldTextType}"
                         (click)="toggleFieldTextType()">
                      </i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-group row mb-4">
                <div class="col-12">
                  <button mat-raised-button class="btn btn-lg btn-yellow font-weight-bold btn-block submit-button">Sign
                    in</button>
                </div>
              </div>
              <div class="note-text white-text text-center">
                <span>Contact your associate in case you forgot the password or unable to sign in</span>
              </div>
            </div>
          </div>
        </form>
      </div>


    </div>
  </div>
</div>

<!-- JQuery -->
<script type="text/javascript" src="assets/js/jquery-3.4.1.min.js"></script>
<!-- Bootstrap tooltips -->
<script type="text/javascript" src="assets/js/popper.min.js"></script>

<!-- Bootstrap core JavaScript -->
<!--  <script type="text/javascript" src="js/bootstrap.min.js"></script>-->
<!-- MDB core JavaScript -->
<script type="text/javascript" src="assets/js/mdb.min.js"></script>
<!--  <script type="text/javascript" src="../js/dist/compiled-4.7.4.min.js"></script>-->
