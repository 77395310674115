<body class="fixed-sn">
<div class="wrapper container">
    <app-header></app-header>
    <main>
        <h1>My Account</h1>

        <!--        <div class="input-group table-search col-4">-->
        <!--            <div class="select-form" style="border-radius: 3px; margin-left: 1.6rem;">-->
        <!--                <div class="btn-group">-->
        <!--                    <select name="status" class="form-control-lg-select btn btn-primary customer-border"-->
        <!--                            [(ngModel)]="userProfile.result.bankName">-->
        <!--                        <option value="NO">&#45;&#45;&#45;&#45; เลือกธนาคารของท่าน &#45;&#45;&#45;&#45;</option>-->
        <!--                        <option value="SCB">ธนาคารไทยพาณิชย์ จำกัด (มหาชน)</option>-->
        <!--                        <option value="BBL">ธนาคารกรุงเทพ จำกัด (มหาชน)</option>-->
        <!--                        <option value="KBANK">ธนาคารกสิกรไทย จำกัด (มหาชน)</option>-->
        <!--                        <option value="KTB">ธนาคารกรุงไทย จำกัด (มหาชน)</option>-->
        <!--                        <option value="BAAC">ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร</option>-->
        <!--                        <option value="TMB">ธนาคารทหารไทย จำกัด (มหาชน)</option>-->
        <!--                        <option value="CIMB">ธนาคาร ซีไอเอ็มบี ไทย จำกัด (มหาชน)</option>-->
        <!--                        <option value="UOB">ธนาคารยูโอบี จำกัด (มหาชน)</option>-->
        <!--                        <option value="BAY">ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)</option>-->
        <!--                        <option value="GSB">ธนาคารออมสิน</option>-->
        <!--                        <option value="LHBANK">ธนาคารแลนด์ แอนด์ เฮ้าส์ จำกัด (มหาชน)-->
        <!--                        </option>-->
        <!--                        <option value="TBANK">ธนาคารธนชาต จำกัด (มหาชน)</option>-->
        <!--                        <option value="TISCO">ธนาคารทิสโก้ จำกัด (มหาชน)</option>-->
        <!--                        <option value="KKP">ธนาคารเกียรตินาคิน จำกัด (มหาชน)</option>-->
        <!--                    </select>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->

        <!--        <div class="input-group table-search col-4">-->
        <!--            <div class="select-form  customer-border" style="border-radius: 3px; margin-left: 1.6rem;">-->
        <!--                <div class="btn-group">-->
        <!--                    <select name="status" style="color:#008F6B; padding-left: 0px;"-->
        <!--                            class="form-control-lg-select btn btn-primary" [(ngModel)]="status">-->
        <!--                        <option selected="selected" value="">All</option>-->
        <!--                        <option value="SUCCESS">SUCCESS</option>-->
        <!--                        <option value="DISPUTE">DISPUTE</option>-->
        <!--                        <option value="REFUND">REFUND</option>-->
        <!--                        <option value="WAITING_REFUND">WAITING_REFUND</option>-->
        <!--                        <option value="WAITING">WAITING</option>-->
        <!--                        <option value="FAIL">FAIL</option>-->
        <!--                        <option value="PENDING">PENDING</option>-->
        <!--                        <option value="PROBLEM">PROBLEM</option>-->
        <!--                        <option value="REJECT">REJECT</option>-->
        <!--                    </select>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
        <div class="tab-section mb-4">
            <div class="tab-control-panel card-header card-header-unit">
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                        <a href="#bi-md" id="bi-tab-md" data-toggle="tab" role="tab" class="nav-link basicInfo"
                           aria-controls="bi-tab-md" aria-selected="true">Basic Info</a>
                    </li>
                    <!--                    <li class="nav-item">-->
                    <!--                        <a href="#s-md" id="s-tab-md" data-toggle="tab" role="tab" class="nav-link security"-->
                    <!--                           aria-controls="s-tab-md" aria-selected="true">Security</a>-->
                    <!--                    </li>-->
                    <ng-container *ngIf="role != 'STAFF' && role != 'ADMIN_READ_ONLY'">
                        <li class="nav-item">
                            <a href="#ps-md" id="ps-tab-md" data-toggle="tab" role="tab" class="nav-link productSetting"
                               aria-controls="ps-tab-md" aria-selected="true">Product Info.</a>
                        </li>
                    </ng-container>
                </ul>
            </div>
            <div class="tab-content section-group">
                <div class="tab-pane fade basicInfoTab" id="bi-md" role="tabpanel" aria-labelledby="bi-tab-md">
                    <div class="form-row">
                        <div class="form-group col-6">
                            <div class="form-group row">
                                <div class="col-4 account-sub-title">Username</div>
                                <div class="col-8 account-sub-value">
                                    <div>{{userProfile.result.username}}</div>
                                    <small class="text-muted"><i>To change username, contact your associate</i></small>
                                </div>
                            </div>
                            <ng-container *ngIf="role != 'STAFF' && role != 'ADMIN_READ_ONLY'">
                                <div class="form-group row">
                                    <div class="col-4 account-sub-title">Account Firstname</div>
                                    <div class="col-8 account-sub-value">
                                        <div class="mb-1">
                                            <input type="text" class="form-control" id="remark"
                                                   placeholder="Enter firstname"
                                                   aria-describedby="remarkHelper"
                                                   [(ngModel)]="userProfile.result.accountFirstname" name="firstname">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-4 account-sub-title">Account Lastname</div>
                                    <div class="col-8 account-sub-value">
                                        <div class="mb-1">
                                            <input type="text" class="form-control" id="remark"
                                                   placeholder="Enter lastname"
                                                   aria-describedby="remarkHelper"
                                                   [(ngModel)]="userProfile.result.accountLastname" name="lastname">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-4 account-sub-title">Bank Account</div>
                                    <div class="col-8 account-sub-value">
                                        <div class="mb-1">
                                            <input type="text" class="form-control" id="remark"
                                                   placeholder="Enter username"
                                                   aria-describedby="remarkHelper"
                                                   [(ngModel)]="userProfile.result.accountNumber" name="accountNumber">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-12 row">
                                    <div class="col-4 account-sub-title">Bank Name</div>
                                    <div class="col-8 account-sub-value  customer-border">
                                        <div class="input-group table-search col-4" style="text-align: left; padding-left: 4px">
                                            <div class="select-form  customer-border"
                                                 style="border-radius: 3px; text-align: left">
                                                <div class="btn-group">
                                                    <select name="status"
                                                            class="form-control-lg-select btn btn-primary customer-border"
                                                            style="border-color: #b8b8bc; border-style: solid; border-width: thin; position:relative;"
                                                            [(ngModel)]="userProfile.result.bankName">
                                                        <option value="NO">---- เลือกธนาคารของท่าน ----</option>
                                                        <option value="SCB">ธนาคารไทยพาณิชย์ จำกัด (มหาชน)</option>
                                                        <option value="BBL">ธนาคารกรุงเทพ จำกัด (มหาชน)</option>
                                                        <option value="KBANK">ธนาคารกสิกรไทย จำกัด (มหาชน)</option>
                                                        <option value="KTB">ธนาคารกรุงไทย จำกัด (มหาชน)</option>
                                                        <option value="BAAC">ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร
                                                        </option>
                                                        <option value="TMB">ธนาคารทหารไทย จำกัด (มหาชน)</option>
                                                        <option value="CIMB">ธนาคาร ซีไอเอ็มบี ไทย จำกัด (มหาชน)
                                                        </option>
                                                        <option value="UOB">ธนาคารยูโอบี จำกัด (มหาชน)</option>
                                                        <option value="BAY">ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)</option>
                                                        <option value="GSB">ธนาคารออมสิน</option>
                                                        <option value="LHBANK">ธนาคารแลนด์ แอนด์ เฮ้าส์ จำกัด (มหาชน)
                                                        </option>
                                                        <option value="TBANK">ธนาคารธนชาต จำกัด (มหาชน)</option>
                                                        <option value="TISCO">ธนาคารทิสโก้ จำกัด (มหาชน)</option>
                                                        <option value="KKP">ธนาคารเกียรตินาคิน จำกัด (มหาชน)</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-lg btn-yellow font-weight-bold" type="button"
                                        (click)="saveBankAccount()">Save
                                </button>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade productSettingTab" id="ps-md" role="tabpanel" aria-labelledby="ps-tab-md">
                    <div class="title-holder">
                        <h4>Product Info.</h4>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12 mb-4">
                            <div class="tab-section card">
                                <div class="tab-control-panel card-header">
                                    <ul class="nav nav-tabs" role="tablist">
                                        <li class="nav-item">
                                            <a href="#tl-md" id="tl-tab-md" data-toggle="tab" role="tab"
                                               class="nav-link active" aria-controls="tl-tab-md" aria-selected="true">Services</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="card-body">
                                    <div class="tab-content">
                                        <div class="tab-pane fade show active" id="tl-md" role="tabpanel"
                                             aria-labelledby="tl-tab-md">
                                            <div class="row mb-0">
                                                <div class="col-12 pr-0">
                                                    <div class="row-header">
                                                        <div class="row">
                                                            <div class="col">Logo</div>
                                                            <div class="col">Service</div>
                                                            <div class="col">Fee</div>
                                                            <div class="col">Fee Type</div>
                                                            <div class="col">Min Fee</div>
                                                            <div class="col">Min Fee Type</div>
                                                            <div class="col">Payout Fee</div>
                                                        </div>
                                                    </div>
                                                    <div class="table-body">
                                                        <ng-container *ngFor="let index of userProfile.result.services">
                                                            <div class="row">
                                                                <div class="col"><img style="width: 100px; height: 100px" src="{{index.imageUrl}}"></div>
                                                                <div class="col">{{index.serviceName}}</div>
                                                                <div class="col">{{index.merchantFee}}</div>
                                                                <div class="col">{{index.merchantFeeType}}</div>
                                                                <div class="col">{{index.merchantMinimumFee}}</div>
                                                                <div class="col">{{index.merchantMinimumFeeType}}</div>
                                                                <div class="col">{{index.payoutFee}}</div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
<!-- Modal -->
<div class="modal fade" id="modal-main" role="dialog" tabindex="-1" aria-hidden="true"></div>

</body>
