import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../../service/api.service';
import {ModalDirective} from 'angular-bootstrap-md';
import {HeaderComponent} from '../../header/header.component';
import {AppComponent} from '../../../app.component';
import {TranslateService} from '@ngx-translate/core';
import {DefaultVariable} from '../../common/default.variable';
import {ActivatedRoute, Router} from '@angular/router';

declare let jQuery: any;

@Component({
    selector: 'app-dashboard',
    templateUrl: './changepassword.component.html',
    styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent extends AppComponent implements OnInit {

    @ViewChild('childModal') childModal: ModalDirective;

    public oldPassword: any;
    public newPassword: any;
    public repeatPassword: any;
    public username: any;


    public currentPage: 1;
    public pageNumber: any;
    loginForm: FormGroup;
    public password: any;


    constructor(
        private apiService: ApiService,
        private _formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        public translate: TranslateService
    ) {
        super(translate);
    }

    async ngOnInit(): Promise<any> {
        this.loginForm = this._formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        this.username = localStorage.getItem("username");


        // jQuery('.dashboard').addClass('active');



    }


    async changePassword() {
        if (this.newPassword !== this.repeatPassword) {
            alert('New Password and Repeat Password must be the same !!!');
        }
        if (confirm('Do you want to change your password?')) {
            let request = {
                password: this.oldPassword,
                new_password: this.newPassword,
            };
            let response;
            if (this.role == 'ADMIN' || this.role == 'ADMIN_READ_ONLY') {
                response = await this.apiService.adminChangePassword(request);
            } else {
                response = await this.apiService.changePassword(request);
            }
            if (response.code === 0) {
                this.router.navigate(['/login']);
            }

        }
    }
}

