import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from './service/api.service';
import {Router} from '@angular/router';
import {DefaultVariable} from './components/common/default.variable';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends DefaultVariable {
    title = 'angular8-seed';
    isActive: boolean = false;

    constructor(public translate: TranslateService) {
        super();
        translate.addLangs(['en', 'th']);
        translate.setDefaultLang('en');
        const browserLang = translate.getBrowserCultureLang();
        translate.use(browserLang.match(/en|th/) ? browserLang : 'en');
    }

    changeLang(lang: string) {

        if (lang === 'th') {
            this.translate.use('th');
        }

        if (lang === 'en') {
            this.translate.use('en');
        }
    }

}


