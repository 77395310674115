<script src="dashboard.component.ts"></script>

<body class="fixed-sn">
<div class="wrapper container">
    <app-header></app-header>
    <main>
        <ng-container *ngIf="isActive === false; else elseBlock">
            <app-spinner>
            </app-spinner>
        </ng-container>
        <ng-template #elseBlock>
            <ng-container *ngIf="this.role != 'STAFF'">
            <div class="btn-toolbar section-group" role="toolbar" style="padding-bottom: 0px !important;">
                <!--            <div class="form-row">-->
                <!--                <div class="form-group col-12">-->

                    <div class="form-group col-md-12 row" style="padding-left: 0.6rem;">
                        <div>
<!--                        <div class="col-1">-->
                            <label for="email"
                                   class="col-form-label font-weight-bold">{{'common.type' | translate }}</label>
                        </div>
                        <div style="padding-left: 1rem;">
<!--                        <div class="col-2">-->
                            <!--                    <div class="input-group table-search col-2" style="padding-left: 2.1rem;">-->
                            <div class="select-form  customer-border" style="border-radius: 2px; margin-left: 0rem;">
                                <div class="btn-group">
                                    <select name="status" style="color:#008F6B; padding-left: 0px;"
                                            class="form-control-lg-select btn btn-primary" [(ngModel)]="type">
                                        <option value="T">แสดงผลรายชั่วโมง</option>
                                        <option value="D">แสดงผลรายวัน</option>
                                    </select>
                                </div>
                            </div>
                        </div>
<!--                        <div>-->
<!--&lt;!&ndash;                        <div class="col-1">&ndash;&gt;-->
<!--                            <label for="email" class="col-form-label font-weight-bold" set-lan="text:Start date_" style="padding-left: 1rem;">-->
<!--                                {{'common.merhcant' | translate }}-->
<!--                            </label>-->
<!--                        </div>-->
                        <ng-container *ngIf="role =='ADMIN' || role =='ADMIN_READ_ONLY'">
                            <div>
                                <!--                        <div class="col-1">-->
                                <label for="email" class="col-form-label font-weight-bold" set-lan="text:Start date_" style="padding-left: 1rem;">
                                    {{'common.merhcant' | translate }}
                                </label>
                            </div>
                            <div style="padding-left: 1rem;">
<!--                            <div class="col-2">-->
                                <div class="input-group table-search " style="padding-left:  0rem;">

                                    <div class="select-form  customer-border"
                                         style="border-radius: 2px; margin-left: 0rem;">
                                        <div class="btn-group">
                                            <select name="status" style="color:#008F6B; padding-left: 0px;"
                                                    class="form-control-lg-select btn btn-primary"
                                                    [(ngModel)]="username">
                                                <option value="" selected>All</option>
                                                <ng-container *ngFor="let i of listMerchant.result">
                                                    <option value="{{i.username}}" selected>{{i.username}}</option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </ng-container>
                    </div>

                <div class="form-group col-md-12 row">
                    <div>
<!--                    <div class="col-1">-->
                        <label for="email" class="col-form-label font-weight-bold"
                               set-lan="text:Start date_">{{'dashboard.month' | translate }}</label>
                    </div>
                    <div style="padding-left: 1.8rem;">
<!--                    <div class="col-2">-->
                        <div class="input-group table-search">
                            <div class="select-form  customer-border" style="border-radius: 3px; margin-left: 0rem!important;">
                                <div class="btn-group">
                                    <select name="mounth" style="color:#008F6B; padding-left: 0px;"
                                            class="form-control-lg-select btn btn-primary" [(ngModel)]="mounth"
                                            (change)="getDateFromMounth()">
                                        <option value="1">มกราคม</option>
                                        <option value="2">กุมภาพันธ์</option>
                                        <option value="3">มีนาคม</option>
                                        <option value="4">เมษายน</option>
                                        <option value="5">พฤษภาคม</option>
                                        <option value="6">มิถุนายน</option>
                                        <option value="7">กรกฎาคม</option>
                                        <option value="8">สิงหาคม</option>
                                        <option value="9">กันยายน</option>
                                        <option value="10">ตุลาคม</option>
                                        <option value="11">พฤศจิกายน</option>
                                        <option value="12">ธันวาคม</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="type == 'T'">
                            <div>
<!--                        <div class="col-">-->
                            <label for="email" class="col-form-label font-weight-bold"
                                   set-lan="text:Start date_" style="padding-left: 3.3rem;">{{'dashboard.day' | translate }}</label>
                        </div>
                        <div style="padding-left: 2.25rem;">
<!--                        <div class="col-2">-->
                            <div class="input-group table-search table-dashboard">
<!--                            <div class="input-group table-search col-2 table-dashboard">-->
                                <div class="select-form  customer-border"
                                     style="border-radius: 3px; margin-left: 0rem;">
                                    <div class="btn-group">
                                        <select name="status" style="color:#008F6B; padding-left: 0px;"
                                                class="form-control-lg-select btn btn-primary" [(ngModel)]="day">

                                            <ng-container *ngFor="let index of counter(allDate);let i= index ">
                                                <option value="{{i+1}}">{{i + 1}}</option>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div style="padding-left: 1rem;">
<!--                    <div class="col-2">-->
                        <div class="form-group " style="padding-left: 1rem;">
                            <button class="btn btn-yellow font-weight-bold m-0 px-3 py-2 z-depth-0 waves-effect btnMenu"
                                    type="button" set-lan="text:Search" (click)="searchChart()">Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            </ng-container>
            <br>
            <br>

            <ng-container *ngIf="this.role == 'STAFF'">
                <div class="row">
                    <div class="col">
                        <div class="card mb-4">
                            <div class="card-header">{{'dashboard.yourWithdraw'|translate}}</div>
                            <div class="card-body">
                                <div class="row" *ngFor="let index of summaryWithdraw.result">
                                    <div class="col-3"><span class="card-total-row">
                                        <ng-container *ngIf="index.status === 'PROBLEM'; else other">
                                                                INCOMPLETE
                                                            </ng-container>
                                                            <ng-template #other>
                                                                  {{index.status}}
                                                            </ng-template>
                                        </span></div>
                                    <div class="col-3"><span class="card-sub-value-total">
                                                Amount : {{index.total_value + index.fee | number:'.2' }}
                                        {{'common.thb'|translate}}</span>
                                    </div>
                                    <!--&lt;!&ndash;                          -->
                                    <div class="col-3"><span class="card-sub-value-total">
                                                Fee: {{index.fee | number:'1.2-2' }} {{'common.thb'|translate}}</span>
                                    </div>

                                    <div class="col-3"><span class="card-sub-value-total">
                                                Net : {{index.total_value | number:'1.2-2' }}
                                        {{'common.thb'|translate}}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card mb-4">
                            <div class="card-header">{{'dashboard.outstanding'|translate}}</div>
                            <div class="card-body">

                                <div class="row" *ngFor="let index of summaryBudget.result">
                                    <div class="col-4"><span class="card-total-row">
                                        <ng-container *ngIf="index.status === 'PROBLEM'; else other">
                                                                INCOMPLETE
                                                            </ng-container>
                                                            <ng-template #other>
                                                                  {{index.status}}
                                                            </ng-template>
                                    </span></div>
                                    <div class="col-4"><span class="card-sub-value-total">No. Trans :
                                        {{index.count | number:'1.2-2'}} </span>
                                    </div>
                                    <div class="col-4"><span class="card-sub-value-total">ToTal :
                                        {{index.total | number:'1.2-2' }} {{'common.thb'|translate}}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="this.role === 'MERCHANT'">
                <div class="row">
                    <div class="col">
                        <div class="mb-4">
                            <div class="chart-wrapper">
                                <canvas baseChart
                                        [data]="pieChartData"
                                        [labels]="pieChartLabels"
                                        [chartType]="pieChartType"
                                        [options]="pieChartOptions"
                                        [colors]="pieChartColors"
                                        [plugins]="pieChartPlugins"
                                        [legend]="pieChartLegend">
                                </canvas>
                            </div>
                        </div>
                        <div class="card mb-4">
                            <div class="card-header">Bank Detail</div>
                            <div class="card-body">

                                <ng-container *ngFor="let index of pieChartLabels;let i = index">
                                    <div class="row">
                                        <div class="col-6"><span class="card-total-row">
                                            <ng-container *ngIf="pieChartLabels[i] == ''; else loggedOut">
                                                ไม่ทราบข้อมูล/Undefined Bank
                                                </ng-container>
                                                <ng-template #loggedOut>
                                                 {{pieChartLabels[i]|number:'.2'}}
                                            </ng-template>

                                        </span></div>
                                        <div class="col-6"><span class="card-total-row">{{pieChartData[i]|number:'.2'}}</span></div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="card mb-4">
                            <div class="card-header">{{'dashboard.yourCredit'|translate}}</div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-6"><span
                                            class="card-total-row">{{'dashboard.totalCredit'|translate}}</span>
                                    </div>
                                    <div class="col-6"><span class="card-sub-value-total">
                                                {{wallet + walletDw + walletCredit | number:'.2' }}
                                        {{'common.thb'|translate}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-4">
                            <div class="card-header">{{'dashboard.yourWithdraw'|translate}}</div>
                            <div class="card-body">
                                <div class="row" *ngFor="let index of summaryWithdraw.result">
                                    <div class="col-3"><span class="card-total-row">
                                             <ng-container *ngIf="index.status === 'PROBLEM'; else other">
                                                                INCOMPLETE
                                                            </ng-container>
                                                            <ng-template #other>
                                                                  {{index.status}}
                                                            </ng-template>
                                        </span></div>
                                    <div class="col-3"><span class="card-sub-value-total">
                                                Amount : {{index.total_value + index.fee | number:'.2' }}
                                        {{'common.thb'|translate}}</span>
                                    </div>
                                    <!--&lt;!&ndash;                          -->
                                    <div class="col-3"><span class="card-sub-value-total">
                                                Fee: {{index.fee | number:'1.2-2' }} {{'common.thb'|translate}}</span>
                                    </div>

                                    <div class="col-3"><span class="card-sub-value-total">
                                                Net : {{index.total_value | number:'.2' }}
                                        {{'common.thb'|translate}}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-4">
                            <div class="card-header">{{'dashboard.outstanding'|translate}}</div>
                            <div class="card-body">
                                <div class="row" *ngFor="let index of summaryBudget.result">
                                    <div class="col-4">
                                        <span class="card-total-row">
                                        <ng-container *ngIf="index.status === 'PROBLEM'; else other1">
                                                                INCOMPLETE
                                                            </ng-container>
                                                            <ng-template #other1>
                                                                  {{index.status}}
                                                            </ng-template>
                                    </span>
                                    </div>
                                    <div class="col-4"><span class="card-sub-value-total">No. Trans :
                                        {{index.count | number:'1.2-2'}} </span>
                                    </div>
                                    <div class="col-4"><span class="card-sub-value-total">ToTal :
                                        {{index.total | number:'1.2-2' }} {{'common.thb'|translate}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="col">
                            <div class="mb-4">
                                <div class="chart-wrapper">
                                    <canvas baseChart
                                            [datasets]="barChartData"
                                            [labels]="barChartLabels"
                                            [options]="barChartOptions"
                                            [plugins]="barChartPlugins"
                                            [legend]="barChartLegend"
                                            [colors]="chartColors"
                                            [chartType]="barChartType">
                                    </canvas>
                                </div>
                            </div>
                            <div class="mb-4">
                                <div class="chart-wrapper">
                                    <canvas baseChart
                                            [datasets]="barChartFrequencyData"
                                            [labels]="barChartFrequencyLabels"
                                            [options]="barChartFrequencyOptions"
                                            [plugins]="barChartPlugins"
                                            [legend]="barChartFrequencyLegend"
                                            [colors]="chartColors"
                                            [chartType]="barChartFrequencyType">
                                    </canvas>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="this.role === 'ADMIN' || this.role == 'ADMIN_READ_ONLY'">
                <div class="row">
                    <div class="col">
                        <div class="mb-4">
                            <div class="chart-wrapper">
                                <canvas baseChart
                                        [data]="pieChartData"
                                        [labels]="pieChartLabels"
                                        [chartType]="pieChartType"
                                        [options]="pieChartOptions"
                                        [colors]="pieChartColors"
                                        [plugins]="pieChartPlugins"
                                        [legend]="pieChartLegend">
                                </canvas>
                            </div>
                        </div>
                        <div class="card mb-4">
                            <div class="card-header">Payment</div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-6"><span class="card-total-row">Total Deposit Amount</span></div>
                                    <div class="col-6"><span class="card-sub-value-total">{{ttl_amount  | number:'1.2-2'}} THB</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6"><span class="card-total-row">Total Deposit Count</span></div>
                                    <div class="col-6"><span class="card-sub-value-total">{{ttl_count  | number:'1.2-2'}} </span></div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-4">
                            <div class="card-header">Bank Detail</div>
                            <div class="card-body">

                                <ng-container *ngFor="let index of pieChartLabels;let i = index">
                                    <div class="row">
                                        <div class="col-6"><span class="card-total-row">
                                            <ng-container *ngIf="pieChartLabels[i] == ''; else loggedOut">
                                                ไม่ทราบข้อมูล/Undefined Bank
                                                </ng-container>
                                                <ng-template #loggedOut>
                                                 {{pieChartLabels[i]}}
                                            </ng-template>

                                        </span></div>
                                        <div class="col-6"><span class="card-total-row">{{pieChartData[i]}}</span></div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="mb-4">
                            <div class="chart-wrapper">
                                <canvas baseChart
                                        [datasets]="barChartData"
                                        [labels]="barChartLabels"
                                        [options]="barChartOptions"
                                        [plugins]="barChartPlugins"
                                        [legend]="barChartLegend"
                                        [colors]="chartColors"
                                        [chartType]="barChartType">
                                </canvas>
                            </div>
                        </div>
                        <div class="mb-4">
                            <div class="chart-wrapper">
                                <canvas baseChart
                                        [datasets]="barChartFrequencyData"
                                        [labels]="barChartFrequencyLabels"
                                        [options]="barChartFrequencyOptions"
                                        [plugins]="barChartPlugins"
                                        [legend]="barChartFrequencyLegend"
                                        [colors]="chartColors"
                                        [chartType]="barChartFrequencyType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </main>
</div>
</body>
